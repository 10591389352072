import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { CustomInputField, CustomDropDownField, CustomTextArea } from '../CustomComponents/ReusableComponents';

const EditAccountTypes = ({ id, isOpen, onClose, onUpdate }) => {
    const { auth } = useAuth();

    const [accountTypes, setAccountTypes] = useState([]);

    const [formData, setFormData] = useState({
        parent_id: '',
        name: '',
        description: ''
    });

    useEffect(() => {
                const fetchDropdownData = async () => {
                    try {
                        const response = await axios.post(
                            `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                            {
                                models: [{ model_name: "AccountType" }]
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${auth}`
                                }
                            }
                        );
                        setAccountTypes(response.data.content.AccountType || []);
                    } catch (err) {
                        console.error('Failed to load dropdown data', err);
                    }
                };
        
                fetchDropdownData();
            }, [auth]);
    
    useEffect(() => {
        const fetchAccountType = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/account_types/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                        }
                    }
                );
                setFormData({
                    name: response.data.name,
                    description: response.data.description,
                    parent_id: response.data.parent_id || '',
                });
            } catch (err) {
                console.error("Failed to fetch Transaction Type", err);
            }
        };

        fetchAccountType();
    }, [id, auth]);

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_V1_BASE_URL}/account_types/${id}`,
                { ...formData },
                {
                    headers: {
                        Authorization: `Bearer ${auth}`,
                        "Content-Type": "application/json"
                    }
                }
            );
            console.log("Account Type updated successfully", response.data);
            onClose();
            onUpdate();
        } catch (err) {
            console.error("Failed to update Transaction Type", err);
        }
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const modalOverlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        backdropFilter: 'blur(1px)'
    };

    const modalContentStyle = {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        width: '400px',
        textAlign: 'center'
    };

    return (
        <>
            {isOpen && (
                <div style={modalOverlayStyle} onClick={handleOverlayClick}>
                    <div style={modalContentStyle}>
                    <h5>Edit Account Types</h5>
                    <CustomInputField
                inputType="text"
                labelText="Name"
                inputWrapper="col-lg-12"
                name="name"
                value={formData.name}
                onChange={handleChange}
            />
            <CustomTextArea
                labelText="Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
            />
            <CustomDropDownField
                options={accountTypes.map(c => ({
                    value: c.id,
                    label: c.name
                }))}
                labelText="Parent account"
                name="parent_id"
                value={formData.parent_id}
                onChange={handleChange}
                dropDownSize="col-lg-12"
            />
                        <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={handleSubmit}>Save changes</button>

                    </div>
                </div>
            )}
        </>
    );
};

EditAccountTypes.propTypes = {
    id: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired, 
};

export default EditAccountTypes;

// import React, { useState, useEffect } from 'react';
// import { useAuth } from '../contexts/AuthContext';
// import axios from 'axios';
// import {
//     CustomDropDownField, CustomInputField
// } from "../CustomComponents/ReusableComponents";
// import CustomPopup from '../CustomComponents/CustomPopup';
// import PropTypes from 'prop-types';

// const EditAccountTypes = ({ id }) => {
//     const { auth } = useAuth();
//     const [accountTypes, setAccountTypes] = useState([]);

//     const [formData, setFormData] = useState({
//         parent_id: '',
//         name: '',
//         description: ''
//     });

//     useEffect(() => {
//         const fetchAccountType = async () => {
//             try {
//                 const response = await axios.get(
//                     `${process.env.REACT_APP_API_V1_BASE_URL}/account_types/${id}`,
//                     {
//                         headers: {
//                             Authorization: `Bearer ${auth}`
//                         }
//                     }
//                 );
//                 const accountTypeData = response.data;
//                 setFormData({
//                     parent_id: accountTypeData.parent_id || '',
//                     name: accountTypeData.name || '',
//                     description: accountTypeData.description || ''
//                 });

//                 // Console log the fetched data
//                 console.log("Fetched Account Type Data:", accountTypeData);
//             } catch (err) {
//                 console.error('Failed to fetch account type', err);
//             }
//         };

//         fetchAccountType();
//     }, [id, auth]);

//     // Fetch dropdown data
//     useEffect(() => {
//         const fetchDropdownData = async () => {
//             try {
//                 const response = await axios.post(
//                     `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
//                     {
//                         models: [{ model_name: "AccountType" }]
//                     },
//                     {
//                         headers: {
//                             Authorization: `Bearer ${auth}`
//                         }
//                     }
//                 );
//                 setAccountTypes(response.data.content.AccountType || []);
//             } catch (err) {
//                 console.error('Failed to load dropdown data', err);
//             }
//         };

//         fetchDropdownData();
//     }, [auth]);

//     const handleChange = e => {
//         const { name, value } = e.target;
//         setFormData(prevFormData => ({
//             ...prevFormData,
//             [name]: value
//         }));
//     };

//     const handleSubmit = async e => {
//         e.preventDefault();
//         try {
//             // Console log the data being submitted
//             console.log("Submitting Account Type Data:", formData);

//             const response = await axios.put( // Use PUT for updating
//                 `${process.env.REACT_APP_API_V1_BASE_URL}/account_types/${id}`, // Endpoint for updating
//                 { ...formData },
//                 {
//                     headers: {
//                         Authorization: `Bearer ${auth}`,
//                         "Content-Type": "application/json"
//                     }
//                 }
//             );
//             console.log("Account Type updated successfully", response.data);
//         } catch (err) {
//             console.error("Failed to update Account Type", err);
//         }
//     };

//     return (
//         <CustomPopup label="Edit Account Type" id="edit_account" handleSubmit={handleSubmit}>
//             <CustomInputField
//                 inputType="text"
//                 labelText="Name"
//                 inputWrapper="col-lg-12"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//             />
//             <CustomInputField
//                 inputType="text"
//                 labelText="Description"
//                 inputWrapper="col-lg-12"
//                 name="description"
//                 value={formData.description}
//                 onChange={handleChange}
//             />
//             <CustomDropDownField
//                 options={accountTypes.map(c => ({
//                     value: c.id,
//                     label: c.name
//                 }))}
//                 labelText="Account Type"
//                 name="parent_id"
//                 value={formData.parent_id}
//                 onChange={handleChange}
//                 dropDownSize="col-lg-12"
//             />
//         </CustomPopup>
//     );
// };

// EditAccountTypes.propTypes = {
//     id: PropTypes.string.isRequired,
// };

// export default EditAccountTypes;