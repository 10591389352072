// src/components/ItemFormFields.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../contexts/AuthContext';
import { useParams, useHistory } from 'react-router-dom';
import { CustomDropDownField, CustomInputField, CustomTextArea } from '../CustomComponents/ReusableComponents';
import Header from '../layouts/Header';
import Sidebar from '../layouts/Sidebar';

const AddItems = () => {
    const { auth } = useAuth();
    const { id } = useParams();
    
    const history = useHistory();
    const [menu, setMenu] = useState(false);
    const [accounts, setAccounts] = useState([]);
    // const [loading, setLoading] = useState(true);
    // const [error, setError] = useState(null);

    const itemTypes = [
        { id: 1, name: "Goods" },
        { id: 2, name: "Services" }
    ];

    const [formData, setFormData] = useState({
        type: 'Goods',
        name: '',
        unit: '',
        selling_price: '',
        account_id: '',
        description: ''
    });

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };
    
    const handleCancel = () => {
        history.push('/items');
    };

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                    {
                        models: [
                            { model_name: "Account" },
                        ]
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`
                        }
                    }
                );
                console.log("response", response);
                setAccounts(response.data.content.Account || []);
            } catch (err) {
                // setError("Failed to load dropdown data");
            }
        };

        const fetchAccountData = async () => {
            if (id) {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_V1_BASE_URL}/items/${id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${auth}`
                            }
                        }
                    );
                    setFormData(response.data);
                } catch (err) {
                    // Handle error
                }
            }
        };

        fetchDropdownData();
        fetchAccountData();
    }, [auth, id]);

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const response = id
                ? await axios.put(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/items/${id}`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                            "Content-Type": "application/json"
                        }
                    }
                )
                : await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/items`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                            "Content-Type": "application/json"
                        }
                    }
                );

            console.log("Item saved successfully", response.data);
            history.push('/items');
        } catch (err) {
            console.error("Failed to save Items", err);
            // Handle error
        }
    };

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <Header onMenuClick={toggleMobileMenu} />
                <Sidebar />
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="card mb-0">
                            <div className="card-body">
                                <div className="page-header">
                                    <div className="content-page-header">
                                    <h5>{id ? 'Edit Items' : 'Add Items'}</h5>
                                    </div>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <CustomInputField
                                            inputType="text"
                                            labelText="Item Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />

                                        <CustomDropDownField
                                            options={itemTypes.map(c => ({
                                                value: c.name,
                                                label: c.name
                                            }))}
                                            labelText="Account Type"
                                            name="type"
                                            value={formData.type}
                                            onChange={handleChange}
                                        /> 
                                    </div>
                                    <div className="row">
                                        <CustomInputField
                                            inputType="number"
                                            labelText="Unit"
                                            name="unit"
                                            value={formData.unit}
                                            onChange={handleChange}
                                        />

                                        <CustomInputField
                                            inputType="number"
                                            labelText="Selling Price"
                                            name="selling_price"
                                            value={formData.selling_price}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="row">
                                        <CustomDropDownField
                                            options={accounts.map(c => ({
                                                value: c.id,
                                                label: c.name
                                            }))}
                                            labelText="Account"
                                            name="account_id"
                                            value={formData.account_id}
                                            onChange={handleChange}
                                        />
                                    </div>
                                                
                                    <div className="col-lg-6 my-3">
                                        <CustomTextArea
                                            rows={5}
                                            name="description"
                                            value={formData.description}
                                            onChange={handleChange}
                                            placeholder="Description"
                                        />
                                    </div>
                                    
                                    <div className="text-end">
                                        <button
                                            type="reset"
                                            className="btn btn-primary cancel me-2"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Save
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

AddItems.propTypes = {
    id: PropTypes.number.isRequired,
};

export default AddItems;
