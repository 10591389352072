import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { CustomInputField } from '../CustomComponents/ReusableComponents';

const EditTransactionTypes = ({ id, isOpen, onClose, onUpdate }) => {
    const { auth } = useAuth();

    const [formData, setFormData] = useState({
        name: '',
    });
    
    useEffect(() => {
        // Fetch existing data for the transaction type
        const fetchTransactionType = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/transaction_types/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                        }
                    }
                );
                setFormData({
                    name: response.data.name,
                });
            } catch (err) {
                console.error("Failed to fetch Transaction Type", err);
            }
        };

        fetchTransactionType();
    }, [id, auth]);

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_V1_BASE_URL}/transaction_types/${id}`,
                { ...formData },
                {
                    headers: {
                        Authorization: `Bearer ${auth}`,
                        "Content-Type": "application/json"
                    }
                }
            );
            console.log("Transaction Type updated successfully", response.data);
            onClose();
            onUpdate();
        } catch (err) {
            console.error("Failed to update Transaction Type", err);
        }
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const modalOverlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        backdropFilter: 'blur(1px)'
    };

    const modalContentStyle = {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        width: '400px', // Adjust as needed
        textAlign: 'center'
    };

    return (
        <>
            {isOpen && (
                <div style={modalOverlayStyle} onClick={handleOverlayClick}>
                    <div style={modalContentStyle}>
                        <h5>Edit Transaction Types</h5>

                        <CustomInputField
                            inputType="text"
                            labelText="Name"
                            inputWrapper="col-lg-12"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                        <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={handleSubmit}>Save changes</button>

                        {/* <button
                            onClick={onClose}
                            style={closeButtonStyle}
                            onMouseOver={(e) => (e.target.style.backgroundColor = closeButtonHoverStyle.backgroundColor)}
                            onMouseOut={(e) => (e.target.style.backgroundColor = closeButtonStyle.backgroundColor)}
                        >
                            Close
                        </button> */}
                    </div>
                </div>
            )}
        </>
    );
};

EditTransactionTypes.propTypes = {
    id: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired, 
};

export default EditTransactionTypes;