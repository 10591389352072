// import React, { useState, useEffect } from 'react';
// import { PlaidLink } from 'react-plaid-link';
// import { useAuth } from '../../contexts/AuthContext';


// const PlaidLinkComponent = () => {
//     const { auth: authToken } = useAuth();
//   const [linkToken, setLinkToken] = useState(null);
//   //const [accessToken, setAccessToken] = useState(null);

//   const fetchLinkToken = async () => {
//     const response = await fetch(`${process.env.REACT_APP_API_V1_BASE_URL}/plaid/create-link-token`, {
//       method: 'POST',
//       headers: {
//         Authorization: `Bearer ${authToken}`,
//         "Content-Type": "application/json",
//       },
//     });
//     // const response = await axios.get(`${process.env.REACT_APP_API_V1_BASE_URL}/getprofitandlossstatement`, {
//     //     headers: {
//     //       Authorization: `Bearer ${authToken}`,
//     //       "Content-Type": "application/json",
//     //     },
//     //     params: {
//     //       start_date: selectedstartDate, // Use selectedDate here
//     //       end_date: selectedendDate, // Use selectedDate here
//     //     },
//     //   });
//     const data = await response.json();
//     setLinkToken(data.link_token);
//   };

//   useEffect(() => {
//     fetchLinkToken();
//   }, []);

//   const handleOnSuccess = async (publicToken) => {
//     const response = await fetch(`${process.env.REACT_APP_API_V1_BASE_URL}/plaid/exchange-token`, {
//       method: 'POST',
//       headers: {
//         Authorization: `Bearer ${authToken}`,
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ public_token: publicToken }),
//     });
//     const data = await response.json();
//     console.log(data);
//    // setAccessToken(data.access_token);
//   };

//   return (
//     <div>
//       {linkToken ? (
//         <PlaidLink
//           token={linkToken}
//           onSuccess={handleOnSuccess}
//         >
//           <button>Connect a bank account</button>
//         </PlaidLink>
//       ) : (
//         <p>Loading...</p>
//       )}
//     </div>
//   );
// };

// export default PlaidLinkComponent;
import React, { useState, useEffect } from 'react';
import { PlaidLink } from 'react-plaid-link';
import { useAuth } from '../../contexts/AuthContext';

const PlaidLinkComponent = () => {
  const { auth: authToken } = useAuth();
  const [linkToken, setLinkToken] = useState(null);
  const [plaidItems, setPlaidItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch the link token for connecting a new bank account
  const fetchLinkToken = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_V1_BASE_URL}/plaid/create-link-token`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setLinkToken(data.link_token);
    } catch (error) {
      console.error('Error fetching link token:', error);
    }
  };

  // Fetch the list of Plaid items (linked bank accounts)
  const fetchPlaidItems = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_V1_BASE_URL}/plaid/items`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setPlaidItems(data || []);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching Plaid items:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLinkToken();
    fetchPlaidItems();
  }, []);

  // Handle successful linking of a new bank account
  const handleOnSuccess = async (publicToken) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_V1_BASE_URL}/plaid/exchange-token`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ public_token: publicToken }),
      });
      const data = await response.json();
      console.log('Plaid item linked:', data);

      // Update the list of linked Plaid items after successful linking
      fetchPlaidItems();
    } catch (error) {
      console.error('Error exchanging public token:', error);
    }
  };

  return (
    <div>
      <h2>Linked Bank Accounts</h2>
      
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <ul>
          {plaidItems.length > 0 ? (
            plaidItems.map((item) => (
              <li key={item.id}>
                <strong>Institution:</strong> {item.institution_name} <br />
                <strong>Item ID:</strong> {item.item_id} <br />
                <strong>access_token : </strong> {item.access_token} <br />
              </li>
            ))
          ) : (
            <p>No bank accounts linked yet.</p>
          )}
        </ul>
      )}

      <h2>Link a New Bank Account</h2>
      {linkToken ? (
        <PlaidLink
          token={linkToken}
          onSuccess={handleOnSuccess}
        >
          <button>Connect a bank account</button>
        </PlaidLink>
      ) : (
        <p>Generating link token...</p>
      )}
    </div>
  );
};

export default PlaidLinkComponent;

