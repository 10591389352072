
import React from 'react';
import PageComponent from '../../CustomComponents/PageComponent';
// import PageComponent from '../CustomComponents/PageComponent';
const Customers = () => {
  return(
    <PageComponent route="invoices" listAPI='sales_invoices'  label='Invoices' addType='nextPage' addLabel='Invoice'/>
  )
}

export default Customers;




// import React, { useState, useEffect } from "react";
// import Header from "../../layouts/Header";
// import Sidebar from "../../layouts/Sidebar";
// import "../../_components/antd.css";
// import { Table, Tooltip } from "antd";
// import {
//   onShowSizeChange,
//   itemRender,
// } from "../../_components/paginationfunction";
// import { Link, useHistory } from "react-router-dom";
// import FeatherIcon from "feather-icons-react";
// // import Addinvoice from "./addinvoice";
// import { filterIcon } from "../../_components/Imagepath";
// import axios from "axios";
// import { useAuth } from "../../contexts/AuthContext";

// const InvoiceList = () => {
//   const { auth } = useAuth();
//   const history = useHistory();
//   const [menu, setMenu] = useState(false);
//   const [show, setShow] = useState(false);
//   const [invoiceData, setinvoiceData] = useState([]);
//   const [columns, setColumns] = useState([]);

//   const toggleMobileMenu = () => {
//     setMenu(!menu);
//   };

//   const fetchSaleInvoiceDetails = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_V1_BASE_URL}/sales_invoices`, {
//         headers: {
//           Authorization: `Bearer ${auth}`,
//         },
//       });
//       console.log(response.data);

//       const records = response.data.records;
//       const headers = response.data.headers;

//       const dynamicColumns = headers.map(header => ({
//         title: header.display_name,
//         dataIndex: header.field_name,
//         sorter: header.sortable ? (a, b) => a[header.field_name]?.localeCompare(b[header.field_name]) : null,
//         render: (text) => text,
//       }));

//       // api/v1/sales_invoices/{sales_invoice}
//       dynamicColumns.push({
//         title: 'Actions',
//         dataIndex: 'actions',
//         render: (_, record) => (
//           <div className="d-flex align-items-center">
//             <button 
//               className="btn btn-action-icon me-2" 
//               onClick={() => handleLedger(record.id)}
//             >
//               <i className="far fa-edit me-2" />
//               {/* Ledger */}
//             </button>
//             <button 
//               className="btn btn-action-icon me-2" 
//               onClick={() => handleEdit(record.id)}
//             >
//               <i className="far fa-edit me-2" />
//               {/* Edit */}
//             </button>
//             <button 
//               className="btn btn-action-icon" 
//               onClick={() => handleDelete(record.id)}
//             >
//               <i className="far fa-trash-alt me-2" />
//               {/* Delete */}
//             </button>
//           </div>
//         ),
//       });

//       setColumns(dynamicColumns);
//       setinvoiceData(records);
//     } catch (error) {
//       console.error("Error fetching customer details:", error);
//     }
//   };


//   const handleEdit = (id) => {
//     history.push(`/edit-invoice/${id}`);
//   };

//   const handleLedger = (id) => {
//     console.log("id", id);
//     history.push(`/ledger/${id}`);
//   }

//   const handleDelete = async (id) => {
//     try {
//       const response = await axios.delete(`${process.env.REACT_APP_API_V1_BASE_URL}/sales_invoices/${id}`, {
//         headers: {
//           Authorization: `Bearer ${auth}`,
//         },
//       });

//       if (response.status === 200) {
//         await fetchSaleInvoiceDetails();
//         console.log("Customer deleted successfully:", id);
//       }
//     } catch (error) {
//       console.error("Error deleting customer:", error);
//     }
//   };
//   useEffect(() => {
//     fetchSaleInvoiceDetails();
//   }, [auth]);

//   return (
//     <>
//       <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
//         <Header onMenuClick={() => toggleMobileMenu()} />
//         <Sidebar />

//         <div className="page-wrapper">
//           <div className="content container-fluid">
//             {/* Page Header */}
//             <div className="page-header">
//               <div className="content-page-header ">
//                 <h5>Sales Invoice</h5>
//                 <div className="list-btn">
//                   <ul className="filter-list">
//                     <li>
//                       <Link
//                         to="#"
//                         className="btn btn-filters w-auto popup-toggle me-2"
//                         onClick={() => setShow(!show)}
//                       >
//                         <span className="me-2">
//                            <Tooltip title="Filter">
//                             <img src={filterIcon} alt="filter" />
//                           </Tooltip>
//                         </span>
//                         Filter
//                       </Link>
//                     </li>
//                     <li className="">
//                       <div className="dropdown dropdown-action me-2">
//                         <Link
//                           to="#"
//                           className="btn-filters me-2"
//                           data-bs-toggle="dropdown"
//                           aria-expanded="false"
//                         >
//                           <span>
//                           <Tooltip title="Download" placement="bottom">
//                           <i className="fe fe-download" />
//                         </Tooltip>
//                           </span>
//                         </Link>
//                         <div className="dropdown-menu dropdown-menu-end">
//                           <ul className="d-block">
//                             <li>
//                               <Link
//                                 className="d-flex align-items-center download-item"
//                                 to="#"
//                                 download=""
//                               >
//                                 <i className="far fa-file-pdf me-2" />
//                                 PDF
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 className="d-flex align-items-center download-item"
//                                 to="#"
//                                 download=""
//                               >
//                                 <i className="far fa-file-text me-2" />
//                                 CVS
//                               </Link>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                     </li>
//                     <li>
//                       <Link className="btn-filters" to="#">
//                         <span>
//                         <Tooltip title="Print" placement="bottom">
//                         <i className="fe fe-printer" />
//                       </Tooltip>
//                         </span>{" "}
//                       </Link>
//                     </li>
//                     <li>
//                       <Link className="btn btn-import" to="#">
//                         <span>
//                           <FeatherIcon icon="check-square" className="me-2" />{" "}
//                           Import
//                         </span>
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         className="btn btn-primary"
//                         to="/sales_invoice/add-sales-invoices"
                        
//                         data-bs-target="#add_invoice"
//                       >
//                         <i
//                           className="fa fa-plus-circle me-2"
//                           aria-hidden="true"
//                         />
//                         Add invoice
//                       </Link>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//             </div>
//             {/* /Page Header */}
//             {/* Search Filter */}
//             <div id="filter_inputs" className="card filter-card">
//               <div className="card-body pb-0">
//                 <div className="row">
//                   <div className="col-sm-6 col-md-3">
//                     <div className="input-block mb-3">
//                       <label>Name</label>
//                       <input type="text" className="form-control" />
//                     </div>
//                   </div>
//                   <div className="col-sm-6 col-md-3">
//                     <div className="input-block mb-3">
//                       <label>Email</label>
//                       <input type="text" className="form-control" />
//                     </div>
//                   </div>
//                   <div className="col-sm-6 col-md-3">
//                     <div className="input-block mb-3">
//                       <label>Phone</label>
//                       <input type="text" className="form-control" />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             {/* /Search Filter */}
//             <div className="row">
//               <div className="col-sm-12">
//                 <div className=" card-table">
//                   <div className="card-body invoices">
//                     <div className="table-responsive table-hover table-striped">
//                     <Table
//                         className="table"
//                         pagination={{
//                           total: invoiceData.length,
//                           showTotal: (total, range) =>
//                             `Showing ${range[0]} to ${range[1]} of ${total} entries`,
//                           onShowSizeChange: onShowSizeChange,
//                           itemRender: itemRender,
//                         }}
//                         columns={columns}
//                         dataSource={invoiceData}
//                         rowKey={(record) => record.id}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

// {/* /        <Addinvoice setShow={setShow} show={show} /> */}

//         <div className="modal custom-modal fade" id="add_invoice" role="dialog">
//           <div className="modal-dialog modal-dialog-centered modal-md">
//             <div className="modal-content">
//               <div className="modal-header border-0 pb-0">
//                 <div className="form-header modal-header-title text-start mb-0">
//                   <h4 className="mb-0">Add invoice</h4>
//                 </div>
//                 <button
//                   type="button"
//                   className="btn-close"
//                   data-bs-dismiss="modal"
//                   aria-label="Close"
//                 ></button>
//               </div>
//               <div className="modal-body">
//                 <div className="row">
//                   <div className="col-lg-12 col-sm-12">
//                     <div className="input-block mb-3">
//                       <label>Name</label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         placeholder="Enter Name"
//                       />
//                     </div>
//                   </div>
//                   <div className="col-lg-12 col-sm-12">
//                     <div className="input-block mb-3">
//                       <label>Email</label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         placeholder="Enter Email Address"
//                       />
//                     </div>
//                   </div>
//                   <div className="col-lg-12 col-sm-12">
//                     <div className="input-block mb-3">
//                       <label>Phone Number</label>
//                       <input
//                         type="number"
//                         className="form-control"
//                         placeholder="Enter Phone Number"
//                       />
//                     </div>
//                   </div>
//                   <div className="col-lg-12 col-sm-12">
//                     <div className="input-block mb-3 mb-0">
//                       <label>Balance</label>
//                       <input
//                         type="number"
//                         className="form-control"
//                         placeholder="Enter Balance Amount"
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="modal-footer">
//                 <Link
//                   to="#"
//                   data-bs-dismiss="modal"
//                   className="btn btn-primary paid-cancel-btn me-2"
//                 >
//                   Cancel
//                 </Link>
//                 <Link
//                   to="#"
//                   data-bs-dismiss="modal"
//                   className="btn btn-primary paid-continue-btn"
//                 >
//                   Add invoice
//                 </Link>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="modal custom-modal fade" id="edit_invoice" role="dialog">
//           <div className="modal-dialog modal-dialog-centered modal-md">
//             <div className="modal-content">
//               <div className="modal-header border-0 pb-0">
//                 <div className="form-header modal-header-title text-start mb-0">
//                   <h4 className="mb-0">Edit invoice</h4>
//                 </div>
//                 <button
//                   type="button"
//                   className="btn-close"
//                   data-bs-dismiss="modal"
//                   aria-label="Close"
//                 ></button>
//               </div>
//               <div className="modal-body">
//                 <div className="row">
//                   <div className="col-lg-12 col-md-12">
//                     <div className="input-block mb-3">
//                       <label>Name</label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         defaultValue="John Smith"
//                         placeholder="Enter Name"
//                       />
//                     </div>
//                   </div>
//                   <div className="col-lg-12 col-md-12">
//                     <div className="input-block mb-3">
//                       <label>Email</label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         defaultValue="john@example.com"
//                         placeholder="Select Date"
//                       />
//                     </div>
//                   </div>
//                   <div className="col-lg-12 col-md-12">
//                     <div className="input-block mb-3">
//                       <label>Phone Number</label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         defaultValue="+1 989-438-3131"
//                         placeholder="Enter Reference Number"
//                       />
//                     </div>
//                   </div>
//                   <div className="col-lg-12 col-md-12">
//                     <div className="input-block mb-3 mb-0">
//                       <label>Balance</label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         defaultValue="$4200"
//                         placeholder="Enter Reference Number"
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="modal-footer">
//                 <Link
//                   to="#"
//                   data-bs-dismiss="modal"
//                   className="btn btn-primary paid-cancel-btn me-2"
//                 >
//                   Cancel
//                 </Link>
//                 <Link
//                   to="#"
//                   data-bs-dismiss="modal"
//                   className="btn btn-primary paid-continue-btn"
//                 >
//                   Update
//                 </Link>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div
//           className="modal custom-modal fade"
//           id="delete_modal"
//           role="dialog"
//         >
//           <div className="modal-dialog modal-dialog-centered modal-md">
//             <div className="modal-content">
//               <div className="modal-body">
//                 <div className="form-header">
//                   <h3>Delete invoice</h3>
//                   <p>Are you sure want to delete?</p>
//                 </div>
//                 <div className="modal-btn delete-action">
//                   <div className="row">
//                     <div className="col-6">
//                       <button
//                         type="reset"
//                         data-bs-dismiss="modal"
//                         className="w-100 btn btn-primary paid-continue-btn"
//                       >
//                         Delete
//                       </button>
//                     </div>
//                     <div className="col-6">
//                       <button
//                         type="submit"
//                         data-bs-dismiss="modal"
//                         className="w-100 btn btn-primary paid-cancel-btn"
//                       >
//                         Cancel
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default InvoiceList;
