import React, { useState, useEffect, useRef } from "react";
import "../_components/antd.css";
import { Table, Tooltip, message } from "antd";
// import {
// //   onShowSizeChange,
//   itemRender,
// } from "../_components/paginationfunction";
import { Link, useHistory } from "react-router-dom";
// import FeatherIcon from 'feather-icons-react';
import { filterIcon } from "../_components/Imagepath";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import Sidebar from "../layouts/Sidebar";
import Header from "../layouts/Header";
import ImportPopup from "../CustomComponents/ImportPopup";
import PropTypes from "prop-types";
import NewFilter from "./NewFilterComponent";
import AddAccountTypes from "../accountsfinance/AddAccountTypes";
import AddJournalTypes from "../journal/addJournalTypes";
import AddTransactionTypes from "../accountsfinance/AddTransactionTypes";
import EditTransactionTypes from "../accountsfinance/EditTransactionType";
import EditAccountTypes from "../accountsfinance/EditAccountTypes";
import EditJournalTypes from "../accountsfinance/EditJournalTypes";
import { PlaidLink } from 'react-plaid-link';


const PageComponent = ({ listAPI, label, addType, route, addLabel }) => {
    const { auth } = useAuth();
    const history = useHistory();
    const dynamicTarget = `#add_${listAPI}`;

    const [menu, setMenu] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [filterableFields, setFilterableFields] = useState([]);
    const [filters, setFilters] = useState({});

    const filtersRef = useRef({});

    const [linkToken, setLinkToken] = useState(null);
    
    //const [plaidItems, setPlaidItems] = useState([]);
   // const [isLoading, setIsLoading] = useState(true);

    console.log("filterableFields", filterableFields, filters);

    const toggleFilter = () => {
        setShowFilter(prevShow => !prevShow);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [totalRecords, setTotalRecords] = useState(0);

    const [popupEdit, setPopupEdit] = useState(null);

    const [showModal, setShowModal] = useState(false);

    const invoiceTypes = ['purchase_invoices', 'sales_invoices'];
    // State for messages
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handlePopupEdit = id => {
        setPopupEdit(id);
        setShowModal(true);
    };
    console.log(handlePopupEdit);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const [sortField, setSortField] = useState('id');
    const [sortDirection, setSortDirection] = useState('asc');

    const handleSort = field => {
        const isAscending = sortField === field && sortDirection === "asc";
        setSortField(field);
        setSortDirection(isAscending ? "desc" : "asc");
        fetchTableDetails();
    };

      // Fetch the link token for connecting a new bank account
    const fetchLinkToken = async () => {
        try {
        const response = await fetch(`${process.env.REACT_APP_API_V1_BASE_URL}/plaid/create-link-token`, {
            method: 'POST',
            headers: {
            Authorization: `Bearer ${auth}`,
            "Content-Type": "application/json",
            },
        });
        const data = await response.json();
        setLinkToken(data.link_token);
        fetchTableDetails();
        } catch (error) {
        console.error('Error fetching link token:', error);
        }
    };
      // Handle successful linking of a new bank account
  const handleOnSuccess = async (publicToken) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_V1_BASE_URL}/plaid/exchange-token`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ public_token: publicToken }),
      });
      const data = await response.json();
      console.log('Plaid item linked:', data);

      // Update the list of linked Plaid items after successful linking
     // fetchPlaidItems();
    } catch (error) {
      console.error('Error exchanging public token:', error);
    }
  };

    const fetchTableDetails = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_V1_BASE_URL}/${listAPI}`,
                {
                    headers: {
                        Authorization: `Bearer ${auth}`
                    },
                    params: {
                        page: currentPage,
                        per_page: rowsPerPage,
                        sort_by: sortField,
                        sort_direction: sortDirection,
                        ...filtersRef.current
                    }
                }
            );

            console.log(response.data);

            const { records, headers, total_items } = response.data;

            console.log("headers", headers);
            const dynamicColumns = headers
            .filter(header => header.enable === 1)
            .map(header => ({
                title: header.display_name,
                dataIndex: header.field_name,
                sorter: header.sortable,
                onHeaderCell: column => ({
                    onClick: () => handleSort(column.dataIndex),
                }),
            }));

            const actionsColumnIndex = dynamicColumns.findIndex(
                column => column.title === "Actions"
            );
            if (actionsColumnIndex !== -1) {
                dynamicColumns[actionsColumnIndex].render = (_, record) => (
                    <div className="d-flex align-items-center">
                        {listAPI === "bank_accounts" && record.source === 'Plaid'  && (
                            <button
                                className="btn btn-action-icon me-2"
                                onClick={() => handletransactionssync(record.id)}
                            >
                                <i className="far fe fe-refresh-ccw me-2" />
                                Transaction sync
                            </button>
                        )}
                        {listAPI === "accounts" && (
                            <button
                                className="btn btn-action-icon me-2"
                                onClick={() => handleLedger(record.id)}
                            >
                                <i className="fe fe-file-text me-2" />
                                Ledger
                            </button>
                        )}
                        {listAPI === "journals" && (
                            <button
                                className="btn btn-action-icon me-2"
                                onClick={() => handleJournal(record.reference)}
                            >
                                <i className="far fa-edit me-2" />
                                Journal
                            </button>
                        )}
                        {listAPI === "roles" && (
                            <button
                                onClick={() => handlePermissions(record.id)}
                                className="btn btn-greys me-2"
                            >
                                <i className="fa fa-shield me-1" /> Permissions
                            </button>
                        )}
                        {route === "invoices" && (
                            <button
                                onClick={() => handleView(record.id)}
                                className="btn btn-greys me-2"
                            >
                                <i className="far fa-eye me-2" /> View
                            </button>
                        )}

                        {addType === "nextPage" && (
                            <button
                                className="btn btn-action-icon me-2"
                                onClick={() => handleEdit(record.id)}
                            >
                                <i className="far fa-edit me-2" />
                                Edit
                            </button>
                        )}

                        {addType === "popUp" && (
                            <>
                                <button
                                    className="btn btn-action-icon me-2"
                                    // data-bs-toggle="modal"
                                    // data-bs-target={editDynmicTarget}
                                    onClick={() => handlePopupEdit(record.id)}
                                >
                                    <i className="far fa-edit me-2" />
                                    Edit
                                </button>
                            </>
                        )}

                        <button
                            className="btn btn-action-icon"
                            onClick={() => handleDelete(record.id)}
                        >
                            <i className="far fa-trash-alt me-2" />
                            Delete
                        </button>
                    </div>
                );
            }

            setColumns(dynamicColumns);
            setTableData(records);
            setTotalRecords(total_items);

            const filterableFields = headers.filter(
                header => header.filterable === 1
            );
            setFilterableFields(filterableFields);

            const initialFilters = {};
            filterableFields.forEach(field => {
                initialFilters[field.field_name] = "";
            });
            setFilters(initialFilters);
        } catch (error) {
            console.error("Error fetching customer details:", error);
        }
    };

    const onShowSizeChange = (current, size) => {
        setCurrentPage(current);
        setRowsPerPage(size);
        fetchTableDetails();
    };

    const handlePermissions = id => {
        history.push(`/permission/${id}`);
        console.log("Clicked", id);
    };

    const handleLedger = id => {
        history.push(`/ledger/${id}`);
    };

    const handleJournal = reference => {
        history.push(`/journal/${reference}`);
        //journal/TRX-1
    };
    const handleEdit = id => {
      if(invoiceTypes.includes(listAPI)){
        history.push(`/edit-${route}/${id}`);
      } 
      else{
        history.push(`/edit-${listAPI}/${id}`);
      }
    };

    const handleView = id => {
        history.push(`/view-${route || listAPI}/${id}`);
    };
    const handleDelete = async id => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_API_V1_BASE_URL}/${listAPI}/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${auth}`
                    }
                }
            );

            if (response.status === 200 || response.status === 204) {
                console.log(addType);
                console.log(`${listAPI} deleted successfully`);
                fetchTableDetails();
            }
        } catch (error) {
            console.error(
                "There was an error deleting the account type:",
                error.response ? error.response.data : error.message
            );
        }
    };


    const handleAddData = () => {
        if(invoiceTypes.includes(listAPI)){
          history.push(`/add-${route}`);
        }
        else{
          history.push(`/add-${listAPI}`);
        }
    };

    const handleAdd = () => {
      fetchTableDetails();
    }

    const handleDownload = async (filetype) => {
        try {
            console.log("listapi-", listAPI);
            const response = await axios.get(`${process.env.REACT_APP_API_V1_BASE_URL}/${listAPI}/export`, { // Ensure the correct API endpoint
                headers: {
                    Authorization: `Bearer ${auth}`,
                },
                params: { filetype },
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${listAPI}.${filetype === 'xlsx' ? 'xlsx' : 'csv'}`);
            document.body.appendChild(link);
            link.click();
            link.remove();

            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error(`Error downloading ${filetype.toUpperCase()}:`, error);
        }
    };

    const handleFilterChange = (fieldName, value) => {
        setFilters(prev => ({ ...prev, [fieldName]: value }));
        filtersRef.current = { ...filtersRef.current, [fieldName]: value };
        fetchTableDetails();
    };

    // const [isAddAccountTypesVisible, setAddAccountTypesVisible] = useState(
    //     false
    // );

    // console.log("isAddAccountTypesVisible", isAddAccountTypesVisible);
    // const handleOpenModal = () => {
    //     setAddAccountTypesVisible(prev => !prev);
    // };
    // console.log(handleOpenModal);
    const handletransactionssync = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_V1_BASE_URL}/plaid/accounts/transactions`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${auth}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ id: id }),
            });
            const data = await response.json();

            // Check if the response has an error
            if (response.ok) {
                // Success response
                setSuccessMessage("Transactions imported successfully!"); // Set success message
                console.log('Transactions data:', data.transactions); // Log transactions data
            } else {
                // Error response
                setErrorMessage(data.error || "Error transactions record."); // Set error message from response or default
                console.error('Error fetching transactions:', data.error);
            }
        } catch (error) {
            setErrorMessage("Error transactions record."); // Set error message for catch block
            console.error('Error fetching transactions:', error);
        }
    };
    const handleUpdate = () => {
        setShowModal(false);
        fetchTableDetails();
    };
    // Show success and error messages
    useEffect(() => {
        fetchTableDetails();
        if(listAPI === 'bank_accounts'){
            fetchLinkToken();
        }
        if (successMessage) {
            message.success(successMessage); // Show success message
            setSuccessMessage(""); // Clear message after showing
        }
        if (errorMessage) {
            message.error(errorMessage); // Show error message
            setErrorMessage(""); // Clear message after showing
        }
    }, [auth, currentPage, rowsPerPage, successMessage, errorMessage]);

    // useEffect(() => {
    //     ///plaid
    //     if(listAPI === 'bank_accounts'){
    //         fetchLinkToken();
    //     }
    //    // fetchPlaidItems();
    // }, [auth, currentPage, rowsPerPage]);
    // useEffect(() => {
        
    //   }, []);

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <Header onMenuClick={() => toggleMobileMenu()} />
                <Sidebar />

                <div className="page-wrapper">
                    <div className="content container-fluid">
                        {popupEdit && listAPI === "transaction_types" && (
                            // <EditTransactionTypes id={popupEdit} />
                            <EditTransactionTypes
                                id={popupEdit}
                                isOpen={showModal}
                                onClose={() => setShowModal(false)}
                                onUpdate={handleUpdate}
                            />
                        )}

                        {popupEdit && listAPI === "account_types" && (
                            <EditAccountTypes
                                id={popupEdit}
                                isOpen={showModal}
                                onClose={() => setShowModal(false)}
                                onUpdate={handleUpdate}
                            />
                        )}

                        {popupEdit && listAPI === "journal_types" && (
                            <EditJournalTypes
                                id={popupEdit}
                                isOpen={showModal}
                                onClose={() => setShowModal(false)}
                                onUpdate={handleUpdate}
                            />
                        )}

                        {/* Page Header */}
                        <div className="page-header">
                            <div className="content-page-header ">
                                <h5>{label}</h5>
                                <div className="list-btn">
                                    <ul className="filter-list">
                                        <li>
                                            <Link
                                                to="#"
                                                className="btn btn-filters w-auto popup-toggle me-2"
                                                // onClick={() => setShow(!show)}
                                                onClick={toggleFilter}
                                            >
                                                <span className="me-2">
                                                    <Tooltip title="Filter">
                                                        <img
                                                            src={filterIcon}
                                                            alt="filter"
                                                        />
                                                    </Tooltip>
                                                </span>
                                                Filter
                                            </Link>
                                        </li>
                                        <li className="">
                                            <div className="dropdown dropdown-action me-2">
                                                <Link
                                                    to="#"
                                                    className="btn-filters me-2"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <span>
                                                        <Tooltip
                                                            title="Download"
                                                            placement="bottom"
                                                        >
                                                            <i className="fe fe-download" />
                                                        </Tooltip>
                                                    </span>
                                                </Link>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                    <ul className="d-block">
                                                        <li>
                                                            <Link
                                                                className="d-flex align-items-center download-item"
                                                                to="#"
                                                                onClick={() =>
                                                                    handleDownload(
                                                                        "xlsx"
                                                                    )
                                                                }
                                                            >
                                                                <i className="far fa-file-pdf me-2" />
                                                                Excel
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link
                                                                className="d-flex align-items-center download-item"
                                                                to="#"
                                                                onClick={() =>
                                                                    handleDownload(
                                                                        "csv"
                                                                    )
                                                                }
                                                            >
                                                                <i className="far fa-file-text me-2" />
                                                                CSV
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <Link
                                                className="btn-filters"
                                                to="#"
                                            >
                                                <span>
                                                    <Tooltip
                                                        title="Print"
                                                        placement="bottom"
                                                    >
                                                        <i className="fe fe-printer" />
                                                    </Tooltip>
                                                </span>{" "}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="btn btn-primary"
                                                to="#"
                                                data-bs-toggle="modal"
                                                data-bs-target={`#import_${listAPI}`}
                                            >
                                                <i
                                                    className="fa fa-plus-circle me-2"
                                                    aria-hidden="true"
                                                />
                                                Import
                                            </Link>
                                        </li>
                                        <ImportPopup
                                            id={`import_${listAPI}`}
                                            listAPI={listAPI}
                                        />
                                        <li>
                                            {addType === "nextPage" && (
                                                <>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={handleAddData}
                                                >
                                                    <i
                                                        className="fa fa-plus-circle me-2"
                                                        aria-hidden="true"
                                                    />
                                                    Add {addLabel}
                                                </button>
                                                </>
                                            )}

                                            {listAPI === 'bank_accounts' && (
                                                <>
                                                 {linkToken ? (
                                                    <PlaidLink
                                                    token={linkToken}
                                                    onSuccess={handleOnSuccess}
                                                    >
                                                    <button className="btn btn-primary">
                                                        <i
                                                        className="fa fa-plus-circle me-2"
                                                        aria-hidden="true"
                                                    />Connect a bank account</button>
                                                    </PlaidLink>
                                                ) : (
                                                    <p>Generating link token...</p>
                                                )}
                                                </>
                                            )}

                                            {listAPI === "popUp" && (
                                                <>
                                                    <Link
                                                        className="btn btn-primary"
                                                        to="#"
                                                        data-bs-toggle="modal"
                                                        data-bs-target={
                                                            dynamicTarget
                                                        }
                                                    >
                                                        <i
                                                            className="fa fa-plus-circle me-2"
                                                            aria-hidden="true"
                                                        />
                                                        Add {addLabel}
                                                    </Link>

                                                    {listAPI ===
                                                        "account_types" && (
                                                            <AddAccountTypes onAdd={handleAdd}/>
                                                        )}
                                                    {listAPI ===
                                                        "journal_types" && (
                                                            <AddJournalTypes />
                                                        )}
                                                    {listAPI ===
                                                        "transaction_types" && (
                                                            <AddTransactionTypes />
                                                        )}
                                                </>
                                            )}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <AddAccountTypes /> */}
                        {/* Filter Made */}
                        <NewFilter
                            show={showFilter}
                            setShow={setShowFilter}
                            filterableFields={filterableFields}
                            handleFilterChange={handleFilterChange}
                        />

                        <div className="row">
                            <div className="col-sm-12">
                                <div className=" card-table">
                                    <div className="card-body vendors">
                                        <div className="table-responsive table-hover table-striped">
                                            <Table
                                                className="table"
                                                pagination={{
                                                    current: currentPage,
                                                    pageSize: rowsPerPage,
                                                    total: totalRecords,
                                                    showTotal: (total, range) =>
                                                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                                    onChange: onShowSizeChange,
                                                    itemRender: (
                                                        current,
                                                        type,
                                                        originalElement
                                                    ) => {
                                                        if (type === "prev") {
                                                            return (
                                                                <a>Previous</a>
                                                            );
                                                        }
                                                        if (type === "next") {
                                                            return <a>Next</a>;
                                                        }
                                                        if (type === "page") {
                                                            return (
                                                                <a>{current}</a>
                                                            );
                                                        }
                                                        return originalElement;
                                                    }
                                                }}
                                                columns={columns.map(column => ({
                                                    ...column,
                                                    sorter: false,
                                                    onHeaderCell: () => ({
                                                        onClick: () => handleSort(column.dataIndex)
                                                    })
                                                }))}
                                                dataSource={tableData}
                                                rowKey={record => record.id}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

PageComponent.propTypes = {
    label: PropTypes.string.isRequired,
    listAPI: PropTypes.string.isRequired,
    addType: PropTypes.string.isRequired,
    route: PropTypes.string,
    addLabel: PropTypes.string
};

export default PageComponent;
