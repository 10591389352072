import React from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import SettingsSidebar from "../layouts/SettingsSidebar";
import Profile from "../pages/profile/Index";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const AccountSettings = () => {

    return (
        <>
            <Header />
            <Sidebar />
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="row">
                        <div className="col-xl-3 col-md-4">
                            <SettingsSidebar />
                        </div>
                        <div className="col-xl-9 col-md-8">
                            <div className="card">
                            <div className="content-page-header card-header mb-0">
                    <h5>Profile Page</h5>
                    <Link className="btn btn-primary">
                        <i className="fe fe-edit"></i>
                        <span>Edit</span>
                    </Link>
                </div>
                                <div className="card-body w-100 pt-0">
                                    {/* <div className="content-page-header">
                                        <h5 className="setting-menu">
                                            {('User Profile')}
                                        </h5>
                                    </div> */}
                                        <div className="row">
                                            
                                            <div className="btn-path ">
                                                <Profile/>
                                                                                         </div>
                                        </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccountSettings;
