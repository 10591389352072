export default {
    Data: [
        {
          Id: 1,
          key:1,
          Type: "Wallet Topup",
          Amount: "+$650",
          date: "19 Dec 2022, 06:12 PM",
          Payment: "Paypal",
          Status: "Completed"
        },
        {
          Id: 2,
          key:2,
          Type: "Purchase",
          Amount: "+$350",
          date: "19 Dec 2022, 06:12 PM",
          Payment: "Cash",
          Status: "Cancel"
        },
        {
          Id: 3,
          key:3,
          Type: "Refund",
          Amount: "+100",
          date: "20 Jan 2022, 06:12 PM",
          Payment: "Paypal",
          Status: "Completed"
        },
        {
          Id: 4,
          key:4,
          Type: "Wallet Topup",
          Amount: "-$650",
          date: "20 Jun 2022, 06:12 PM",
          Payment: "Cash",
          Status: "Completed"
        },
        {
          Id: 5,
          key:5,
          Type: "Purchase",
          Amount: "+$350",
          date: "19 Dec 2022, 06:12 PM",
          Payment: "Paypal",
          Status: "Cancel"
        },
        {
          Id: 6,
          key:6,
          Type: "Refund",
          Amount: "+$100",
          date: "15 Jan 2022, 06:12 PM",
          Payment: "Cash",
          Status: "Cancel"
        },
        {
          Id: 7,
          key:7,
          Type: "Wallet Topup",
          Amount: "+$650",
          date: "19 Dec 2022, 06:12 PM",
          Payment: "Paypal",
          Status: "Completed"
        },
        {
          Id: 8,
          key:8,
          Type: "Purchase",
          Amount: "+$350",
          date: "19 Dec 2022, 06:12 PM",
          Payment: "Cash",
          Status: "Cancel"
        },
        {
          Id: 9,
          key:9,
          Type: "Refund",
          Amount: "+100",
          date: "20 Jan 2022, 06:12 PM",
          Payment: "Paypal",
          Status: "Completed"
        },
        {
          Id: 10,
          key:10,
          Type: "Purchase",
          Amount: "+$350",
          date: "19 Dec 2022, 06:12 PM",
          Payment: "Paypal",
          Status: "Cancel"
        }
       ]
}