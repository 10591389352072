import PropTypes from 'prop-types';
import Header from '../layouts/Header'
import Sidebar from '../layouts/Sidebar'
// import SectionHeader from './SectionHeader'

const MainContainer = ({children}) => {
  return (
    <div>
        <Header />
            <Sidebar />
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="card mb-0">
                        <div className="card-body">
                            {/* <SectionHeader sectionHeading="Company Info" /> */}
                            {children}
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )

}
MainContainer.propTypes = {
    children: PropTypes.node
};

export default MainContainer