import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import MainContainer from "./MainContainer";
// import SectionHeader from "./SectionHeader";
import {
    CustomDropDownField,
    CustomInputField,
    CustomTextArea
} from "./ReusableComponents";
import axios from "axios";
import PropTypes from "prop-types";
import {
    useParams,
    useHistory
} from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../contexts/AuthContext";

const AddCustomerForm = () => {
    // const { auth: authToken, branchId } = useAuth();
    const { auth, branch_id } = useAuth();
    const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;

    const { id } = useParams();
    console.log("id", id);
    const navigate = useHistory();
    console.log("branch id", branch_id);
    console.log("auth", auth);

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        name_sufix: "",
        cus_display_name: "",
        company_name: "",
        customer_type: "",
        registration_number: "",
        phone: "",
        whatsapp: "",
        email: "",
        currency_code: "",
        payment_terms: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        state: "",
        state_abbr: "",
        zip_code: "",
        country_id: "",
        shiping_address: "",
        shiping_city: "",
        shiping_state: "",
        shiping_state_abbr: "",
        shiping_zip_code: "",
        shiping_country_id: "",
        internal_notes: ""
    });

    const [countries, setCountries] = useState([]);
    const [currencies, setCurrencies] = useState([]); // State for currencies
    const [sameAddress, setSameAddress] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [menu, setMenu] = useState(false);

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const dropdownRequestData = {
                    models: [
                        { model_name: "Currency" },
                        { model_name: "Country" }
                    ]
                }; // Define your dropdown request data as needed
                const apiReqHeaders = {
                    headers: {
                        Authorization: `Bearer ${auth}`,
                        "Content-Type": "application/json"
                    }
                };

                const response = await axios.post(
                    `${API_BASE_URL}/get_dropdown_data`,
                    dropdownRequestData,
                    apiReqHeaders
                );
                const { Currency } = response.data.content;
                // setCountries(Country || []);
                setCurrencies(Currency || []);
                const { Country } = response.data.content;
                setCountries(Country || []);

                // setCountries(Currency || [1,2,3]);
            } catch (error) {
                setError("Error fetching dropdown data");
                // console.error("Error fetching dropdown data:", error);
            }
        };

        const fetchCustomerData = async () => {
            if (id) {
                try {
                    const apiReqHeaders = {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                            "Content-Type": "application/json"
                        }
                    };

                    const response = await axios.get(
                        `${API_BASE_URL}/customers/${id}`,
                        apiReqHeaders
                    );
                    setFormData({
                        ...formData,
                        ...response.data
                    });
                    setSameAddress(
                        response.data.address_line_1 ===
                            response.data.shiping_address &&
                            response.data.city === response.data.shiping_city &&
                            response.data.state ===
                                response.data.shiping_state &&
                            response.data.state_abbr ===
                                response.data.shiping_state_abbr &&
                            response.data.zip_code ===
                                response.data.shiping_zip_code &&
                            response.data.country_id ===
                                response.data.shiping_country_id
                    );
                } catch (error) {
                    setError("Error fetching customer data");
                    console.error("Error fetching customer data:", error);
                }
            }
        };

        fetchDropdownData();
        fetchCustomerData();
        setLoading(false);
    }, [API_BASE_URL, auth, id]);

    const handleChange = e => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value
        });
    };
    const handleRadioChange = e => {
        setFormData({
            ...formData,
            customer_type: e.target.value
        });
    };

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const handleSameAddressChange = e => {
        const { checked } = e.target;
        setSameAddress(checked);
        if (checked) {
            setFormData(prevData => ({
                ...prevData,
                shiping_address: prevData.address_line_1,
                shiping_city: prevData.city,
                shiping_state: prevData.state,
                shiping_state_abbr: prevData.state_abbr,
                shiping_zip_code: prevData.zip_code,
                shiping_country_id: prevData.country_id
            }));
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const customerData = { ...formData };
            const apiReqHeaders = {
                headers: {
                    Authorization: `Bearer ${auth}`,
                    "Content-Type": "application/json"
                }
            };

            if (id) {
                await axios.put(
                    `${API_BASE_URL}/customers/${id}`,
                    customerData,
                    apiReqHeaders
                );
                alert("Customer updated successfully!");
            } else {
                await axios.post(
                    `${API_BASE_URL}/customers`,
                    customerData,
                    apiReqHeaders
                );
                alert("Customer created successfully!");
            }

            navigate.push("/customers");
        } catch (error) {
            console.error("Error saving customer:", error);
            setError("Failed to save customer.");
        }
    };

    return (
        <>
            <Header onMenuClick={toggleMobileMenu} />
            <Sidebar />
            <MainContainer>
                {/* <SectionHeader sectionHeading="Add Customer" /> */}
                <div className="content-page-header">
                    <h5>{id ? "Edit Customer" : "Add Customer"}</h5>
                    {/* <h5>Add Invoice</h5> */}
                </div>
                <div>
                    <span className="custom-radio">
                        <input
                            type="radio"
                            name="customer_type"
                            value="Business"
                            checked={formData.customer_type === "Business"}
                            onChange={handleRadioChange}
                            className="radio-input"
                        />
                        <span className="radio-custom"></span>
                        <label className="p-1 radioHeading">Business</label>
                    </span>
                    <span className="custom-radio">
                        <input
                            type="radio"
                            name="customer_type"
                            value="Individual"
                            checked={formData.customer_type === "Individual"}
                            onChange={handleRadioChange}
                            className="radio-input"
                        />
                        <span className="radio-custom"></span>
                        <label className="p-1 radioHeading">Individual</label>
                    </span>
                </div>

                {loading && <p>Loading...</p>}
                {error && <p className="text-danger">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="form-title col-lg-12">
                            <h5>Basic Details</h5>
                        </div>
                        <CustomInputField
                            labelText="Name Suffix"
                            name="nameSuffix"
                            value={`${formData.first_name} ${formData.last_name}`}
                            onChange={handleChange}
                        />
                        <CustomInputField
                            labelText="First Name"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                        />
                        <CustomInputField
                            labelText="Last Name"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                        />
                        <CustomInputField
                            labelText="Customer Display Name"
                            name="cus_display_name"
                            value={formData.cus_display_name  = `${formData.first_name} ${formData.last_name}`}
                            onChange={handleChange}
                        />

                        <CustomInputField
                            labelText="Company Name"
                            name="company_name"
                            value={formData.company_name}
                            onChange={handleChange}
                        />
                        <CustomInputField
                            labelText="Registration Number"
                            name="registration_number"
                            inputType="number"
                            value={formData.registration_number}
                            onChange={handleChange}
                        />

                        <CustomInputField
                            labelText="Zip Code"
                            name="zip_code"
                            value={formData.zip_code}
                            onChange={handleChange}
                        />

                        <CustomInputField
                            inputType="text"
                            labelText="Phone Number"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                        />
                        <CustomInputField
                            inputType="text"
                            labelText="Whatsapp Number"
                            name="whatsapp"
                            value={formData.whatsapp}
                            onChange={handleChange}
                        />
                        <CustomInputField
                            inputType="email"
                            labelText="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />

                        <CustomDropDownField
                            options={currencies.map(c => ({
                                value: c.id,
                                label: c.name
                            }))}
                            labelText="Currency Code"
                            name="currencyCode" // make sure it matches the formData key
                            value={formData.currencyCode} // Use the correct value for currency
                            onChange={handleChange}
                        />

                        <CustomDropDownField
                            options={countries.map(b => ({
                                value: b.id,
                                label: b.name
                            }))}
                            labelText="Country"
                            name="country_id" // ensure this matches the formData key
                            value={formData.country_id} // Use the correct value for country
                            onChange={handleChange}
                        />

                        {/* <CustomInputField
                            labelText="Zip Code"
                            name="zip_code"
                            value={formData.zip_code}
                            onChange={handleChange}
                        /> */}

                        <CustomInputField
                            labelText="Payment Terms"
                            name="payment_terms"
                            value={formData.payment_terms}
                            onChange={handleChange}
                        />
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 my-4">
                                <h5 className="mb-2">Billing Address</h5>
                                <>
                                    <CustomInputField
                                        labelText="Address Line 1"
                                        name="address_line_1"
                                        value={formData.address_line_1}
                                        onChange={handleChange}
                                        inputWrapper="col-lg-11"
                                    />
                                    <CustomInputField
                                        labelText="Address Line 2"
                                        name="address_line_2"
                                        value={formData.address_line_2}
                                        onChange={handleChange}
                                        inputWrapper="col-lg-11"
                                    />
                                    <CustomInputField
                                        labelText="City"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                        inputWrapper="col-lg-11"
                                    />
                                    <CustomInputField
                                        labelText="State"
                                        name="state"
                                        value={formData.state}
                                        onChange={handleChange}
                                        inputWrapper="col-lg-11"
                                    />
                                    <CustomInputField
                                        labelText="State Abbr"
                                        name="state_abbr"
                                        value={formData.state_abbr}
                                        onChange={handleChange}
                                        inputWrapper="col-lg-11"
                                    />
                                </>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12 my-4">
                                <div className="d-flex align-items-baseline mb-2">
                                    <h5>Shipping Address</h5>
                                    <span>
                                        <span className="d-inline-block ms-2 me-1">
                                            <input
                                                type="checkbox"
                                                name="sameAsBilling"
                                                checked={sameAddress}
                                                onChange={
                                                    handleSameAddressChange
                                                }
                                            />
                                        </span>
                                        <span>Same as billing address</span>
                                    </span>
                                </div>
                                {!sameAddress && (
                                    <>
                                        <CustomInputField
                                            labelText="Shipping Address"
                                            name="shiping_address"
                                            value={formData.shiping_address}
                                            onChange={handleChange}
                                            inputWrapper="col-lg-10"
                                        />

                                        <CustomInputField
                                            labelText="City"
                                            name="shiping_city"
                                            value={formData.shiping_city}
                                            onChange={handleChange}
                                            inputWrapper="col-lg-10"
                                        />
                                        <CustomInputField
                                            labelText="State"
                                            name="shiping_state"
                                            value={formData.shiping_state}
                                            onChange={handleChange}
                                            inputWrapper="col-lg-10"
                                        />
                                        <CustomInputField
                                            labelText="State abbr"
                                            name="shiping_state_abbr"
                                            value={formData.shiping_state_abbr}
                                            onChange={handleChange}
                                            inputWrapper="col-lg-10"
                                        />
                                        <CustomInputField
                                            labelText="Zip Code"
                                            name="shiping_zip_code"
                                            value={formData.shiping_zip_code}
                                            onChange={handleChange}
                                            inputWrapper="col-lg-10"
                                        />
                                        {/* <CustomInputField
                                            labelText="country"
                                            name="shiping_country_id"
                                            value={formData.shiping_country_id}
                                            onChange={handleChange}
                                            inputWrapper="col-lg-10"
                                        /> */}

                                        <CustomDropDownField
                                            options={countries.map(b => ({
                                                value: b.id,
                                                label: b.name
                                            }))}
                                            labelText="Country"
                                            name="shiping_country_id" // ensure this matches the formData key
                                            value={formData.shiping_country_id} // Use the correct value for country
                                            onChange={handleChange}
                                            inputWrapper="col-lg-10"
                                        />
                                    </>
                                )}
                            </div>

                            <div className="col-lg-12">
                                <CustomTextArea
                                    labelText="Note"
                                    name="internal_notes"
                                    value={formData.internal_notes}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="btn btn-primary mt-4"
                        disabled={loading}
                    >
                        {loading ? "Saving..." : "Save"}
                    </button>
                </form>
            </MainContainer>
        </>
    );
};

AddCustomerForm.propTypes = {
    auth: PropTypes.string.isRequired
};

export default AddCustomerForm;
