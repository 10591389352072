import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
// import { TextField, MenuItem, Button, Container, Grid, Alert, Typography } from '@mui/material';
import Header from '../layouts/Header';
import Sidebar from '../layouts/Sidebar';
import { CustomDropDownField, CustomInputField, CustomTextArea } from '../CustomComponents/ReusableComponents';
import { useAuth } from '../contexts/AuthContext';


const AddPurchaseInvoice = () => {
    const { auth } = useAuth();
    const { id } = useParams();
    const [menu, setMenu] = useState(false);
    const toggleMobileMenu = () => {
        setMenu(!menu);
    };
    const history = useHistory();

    const branchId = sessionStorage.getItem('branch_id');

    const getCurrentDateTime = () => {
        const currentDate = new Date();
        return currentDate.toISOString().slice(0, 16);
    };

    const [formData, setFormData] = useState({
        branch_id: branchId || '',
        vendor_id: '',
        account_id: '',
        paid_through_account_id: '',
        invoice_date: getCurrentDateTime(), // || "2024-09-26T07:37",
        invoice_due_date: getCurrentDateTime(), //|| "2024-09-26T07:37",
        invoice_number: '',
        description: '',
        amount: '',
        order_number: '',
        subject: '',
        vendor_notes: '',
        terms_conditions: '',
        currency_id: '',
        status: 'pending',
    });

    const [accounts, setAccounts] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [vendors, setVendors] = useState([]);
    // const [error, setError] = useState(null);
    // const [validationError, setValidationError] = useState(null);
    const [paidthroughAcc, setPaidthroughAcc] = useState([]);

    const status = [
        { id: 1, name: "paid" },
        { id: 2, name: "pending" },
        { id: 3, name: "cancelled" }
    ];
    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                    {
                        models: [
                            { model_name: 'Vendor' },
                            { model_name: 'Account' },
                            { model_name: 'Currency' }
                        ],
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                        },
                    }
                );
                setAccounts(response.data.content.Account || []);
                setPaidthroughAcc(response.data.content.Account || []);
                setCurrencies(response.data.content.Currency || []);
                setVendors(response.data.content.Vendor || []);
            } catch (err) {
                // setError('Failed to load dropdown data');
            }
        };

        const fetchInvoiceData = async () => {
            if (id) {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_V1_BASE_URL}/purchase_invoices/${id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${auth}`,
                            },
                        }
                    );

                    setFormData(response.data);
                } catch (err) {
                    // setError('Failed to load invoice data');
                }
            }
        };

        fetchDropdownData();
        fetchInvoiceData();
    }, [id, auth]);

    // Handle vendor change
    const handleVendorChange = (e) => {
        const selectedVendorId = e.target.value;
        const selectedVendor = vendors.find(vendor => vendor.id === parseInt(selectedVendorId));

        setFormData({
            ...formData,
            vendor_id: selectedVendorId,
            account_id: selectedVendor ? selectedVendor.account_id : '', // Update account_id based on vendor
        });
        // setValidationError(null);
        alert(`Vendor ID: ${selectedVendorId}, Account ID: ${selectedVendor ? selectedVendor.account_id : 'N/A'}`);
    };

    const handleChange = (e) => {

        const { name, value } = e.target;
    
        setFormData(prevFormData => {
            // Initialize the object to update with the new form data
            const updatedFormData = {
                ...prevFormData,
                [name]: value
            };
    
            // If the field being changed is the invoice_date, calculate the due date
            if (name === 'invoice_date') {
                const invoiceDate = new Date(value);
                const dueDate = new Date(invoiceDate);
                dueDate.setDate(invoiceDate.getDate() + 30); // Add 30 days to the selected date

                // Add the calculated due date to the form data
                updatedFormData.invoice_due_date = dueDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
            }
    
            return updatedFormData;
        });

        // setFormData({ ...formData, [e.target.name]: e.target.value });
        // setValidationError(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // const invoiceDate = new Date(formData.invoice_date);
        // const dueDate = new Date(formData.invoice_due_date);

        // if (dueDate < invoiceDate) {
        //     // setValidationError("Invoice Due Date cannot be earlier than Invoice Date.");
        //     return;
        // }

        try {
            const response = id
                ? await axios.put(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/purchase_invoices/${id}`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                            "Content-Type": "application/json"
                        }
                    }
                )
                : await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/purchase_invoices`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                            "Content-Type": "application/json"
                        }
                    }
                );

            console.log("Item saved successfully", response.data);
            history.push('/bills');
        } catch (err) {
            console.error("Failed to save Items", err);
            // Handle error
        }
    };

    const handleCancel = () => {
        history.push('/bills');
    };

    return (
        <>
        <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <Header onMenuClick={toggleMobileMenu} />
                <Sidebar />
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="card mb-0">
                            <div className="card-body">
                                <div className="page-header">
                                    <div className="content-page-header">
                                    <h5>{id ? 'Edit Bills' : 'Add Bills'}</h5>
                                    </div>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <CustomDropDownField
                                            options={vendors.map(c => ({
                                                value: c.id,
                                                label: c.name
                                            }))}
                                            labelText="Vendor"
                                            name="vendor_id"
                                            value={formData.vendor_id}
                                            onChange={handleVendorChange}
                                        />

                                        <CustomDropDownField
                                            options={accounts.map(c => ({
                                                value: c.id,
                                                label: c.name
                                            }))}
                                            labelText="Account"
                                            name="account_id"
                                            value={formData.account_id}
                                            onChange={handleChange}
                                        />

                                        <CustomDropDownField
                                            options={paidthroughAcc.map(c => ({
                                                value: c.id,
                                                label: c.name
                                            }))}
                                            labelText="Paid Through Account"
                                            name="paid_through_account_id"
                                            value={formData.paid_through_account_id}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="row">
                                        <CustomInputField
                                            labelText="Invoice Date"
                                            inputType="datetime-local"
                                            name="invoice_date"
                                            value={formData.invoice_date}
                                            onChange={handleChange}
                                        />
                                        <CustomInputField
                                            labelText="Invoice Due Date"
                                            inputType="datetime-local"
                                            name="invoice_due_date"
                                            value={formData.invoice_due_date}
                                            onChange={handleChange}
                                        />

                                    </div>
                                    {/* Date Picker */}
                                    {/* <div className="row">
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="input-block mb-3">
                                              <label>Purchases Date</label>
                                              <DatePicker
                                                className="datetimepicker form-control"
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                              ></DatePicker>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="input-block mb-3">
                                              <label>Purchases Date</label>
                                              <DatePicker
                                                className="datetimepicker form-control"
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                              ></DatePicker>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="row">
                                        <CustomInputField
                                            inputType="text"
                                            labelText="Invoice Number"
                                            name="invoice_number"
                                            value={formData.invoice_number}
                                            onChange={handleChange}
                                        />
                                    </div>
                                                
                                    <div className="col-lg-6 my-3">
                                        <CustomTextArea
                                            rows={5}
                                            name="description"
                                            value={formData.description}
                                            onChange={handleChange}
                                            placeholder="Description"
                                        />
                                    </div>

                                    <div className="row">
                                        <CustomInputField
                                            inputType="number"
                                            labelText="Amount"
                                            name="amount"
                                            value={formData.amount}
                                            onChange={handleChange}
                                        />
                                        <CustomInputField
                                            inputType="text"
                                            labelText="Order Number"
                                            name="order_number"
                                            value={formData.order_number}
                                            onChange={handleChange}
                                        />
                                        <CustomInputField
                                            inputType="text"
                                            labelText="Subject"
                                            name="subject"
                                            value={formData.subject}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-lg-6 my-3">
                                        <CustomTextArea
                                            rows={5}
                                            name="vendor_notes"
                                            value={formData.vendor_notes}
                                            onChange={handleChange}
                                            placeholder="Vendor Notes"
                                        />
                                    </div>

                                    <div className="row">
                                        <CustomDropDownField
                                            options={currencies.map(c => ({
                                                value: c.id,
                                                label: c.name
                                            }))}
                                            labelText="Currency"
                                            name="currency_id"
                                            value={formData.currency_id}
                                            onChange={handleChange}
                                        />

                                        <CustomDropDownField
                                            options={status.map(c => ({
                                                value: c.name,
                                                label: c.name
                                            }))}
                                            labelText="Status"
                                            name="status"
                                            value={formData.status}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-lg-6 my-3">
                                        <CustomTextArea
                                            rows={5}
                                            name="terms_conditions"
                                            value={formData.terms_conditions}
                                            onChange={handleChange}
                                            placeholder="Terms & Conditions"
                                        />
                                    </div>
                                    
                                    <div className="text-end">
                                        <button
                                            type="reset"
                                            className="btn btn-primary cancel me-2"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Save
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        </>
    );
};

export default AddPurchaseInvoice;

// {
//     "branch_id": "1",
//     "vendor_id": 1,
//     "account_id": 9,
//     "paid_through_account_id": 16,
//     "invoice_date": "2024-09-26T07:37",
//     "invoice_due_date": "2024-09-26T07:37",
//     "invoice_number": "12345678",
//     "description": "qwertyui",
//     "amount": "100",
//     "order_number": "1",
//     "subject": "none",
//     "vendor_notes": "asdfghjk",
//     "terms_conditions": " t c",
//     "currency_id": 4,
//     "status": "paid"
// }

// {
//     "models": [
//         {
//             "model_name": "Vendor"
//         },
//         {
//             "model_name": "Account"
//         },
//         {
//             "model_name": "Currency"
//         }
//     ]
// }