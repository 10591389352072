import React, { useState, useEffect } from "react";
// import Header from "../../layouts/Header";
import { CustomInputField } from "../../CustomComponents/ReusableComponents";
// import Sidebar from "../../layouts/Sidebar";
import axios from "axios";
import { useAuth } from "../../contexts/AuthContext";
// import { Link } from "react-router-dom/cjs/react-router-dom.min";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import { useParams } from "react-router-dom";

const Profile = () => {
    // const [menu, setMenu] = useState(false);
    const { auth } = useAuth(); // Retrieve auth token from AuthContext
    const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;
    const id = sessionStorage.getItem('user_id');
    // const navigate = useHistory();  
    
    // Initial state for formData to match the API data structure
    const [formData, setFormData] = useState({
        address_line1: "",
        address_line2: "",
        city: "",
        contact_type: "",
        country_code: "",
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        secondary_phone: "",
        social_security_number: "",
        state: "",
        tax_id: "",
        updated_at: "",
    });

    // const toggleMobileMenu = () => {
    //     setMenu(!menu);
    // };
    // const previousPage = () => {
    //     navigate.goBack();
    // };

    useEffect(() => {
        const apiReqHeaders = {
            headers: {
                Authorization: `Bearer ${auth}`, // Use the auth token for Authorization
                "Content-Type": "application/json",
            },
        };

        // Fetch profile data when the component mounts
        axios
            .get(`${API_BASE_URL}/contacts/${id}`, apiReqHeaders) // Assuming the endpoint for a single contact
            .then((response) => {
                const data = response.data; // Assuming data is an object
                setFormData({
                    address_line1: data.address_line1 || "",
                    address_line2: data.address_line2 || "",
                    city: data.city || "",
                    contact_type: data.contact_type || "",
                    country_code: data.country_code || "",
                    email: data.email || "",
                    first_name: data.first_name || "",
                    last_name: data.last_name || "",
                    phone: data.phone || "",
                    secondary_phone: data.secondary_phone || "",
                    social_security_number: data.social_security_number || "",
                    state: data.state || "",
                    tax_id: data.tax_id || "",
                    updated_at: data.updated_at || "",
                });
            })
            .catch((error) => {
                console.error("There was an error fetching the profile data!", error);
            });
    }, [API_BASE_URL, auth]);

    return (
        <>
        <div className={`main-wrapper`}>
            {/* <Header onMenuClick={() => toggleMobileMenu()} /> */}
            {/* <Sidebar /> */}
            <div className="page-header">
            </div>
            <div className="useDetails">
                <div className="row">
                    <CustomInputField
                        value={formData.first_name || "N/A"}
                        labelText="First Name"
                    />
                    <CustomInputField
                        value={formData.last_name || "N/A"}
                        labelText="Last Name"
                    />
                    <CustomInputField
                        value={formData.email || "N/A"}
                        labelText="Email"
                    />
                    <CustomInputField
                        value={formData.social_security_number || "N/A"}
                        labelText="Social Security Number"
                    />
                    <CustomInputField
                        value={formData.phone || "N/A"}
                        labelText="Phone Number"
                    />
                    <CustomInputField
                        value={formData.secondary_phone || "N/A"}
                        labelText="Secondary Phone"
                    />
                    <CustomInputField
                        value={formData.state || "N/A"}
                        labelText="State"
                    />
                    <CustomInputField
                        value={formData.city || "N/A"}
                        labelText="City"
                    />
                    <CustomInputField
                        value={formData.address_line1 || "N/A"}
                        labelText="Address Line 1"
                    />
                    <CustomInputField
                        value={formData.address_line2 || "N/A"}
                        labelText="Address Line 2"
                    />
                    <CustomInputField
                        value={formData.contact_type || "N/A"}
                        labelText="Contact Type"
                    />

                    <CustomInputField
                        value={formData.tax_id || "N/A"}
                        labelText="Tax ID"
                    />
                </div>
            </div>
        </div>
        </>
    );
};

export default Profile;
