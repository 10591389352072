import React, { useState, useEffect } from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import PropTypes from "prop-types";
import {
    CustomDropDownField,
    CustomInputField,
    CustomTextArea
} from "../../CustomComponents/ReusableComponents";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
// import e from "cors";
const AddInvoice = () => {
    //const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;
    const { auth } = useAuth();
    const DISCOUNT_TYPES = {
        PERCENT: "percentage",
        AMOUNT: "amount" // Correcting the key name to match convention
    };
    const { id } = useParams();
    const navigate = useHistory();

    const getCurrentDateTime = () => {
        const currentDate = new Date();
        return currentDate.toISOString().slice(0, 16);
    };

    const [formData, setFormData] = useState({
        customer_id: null,
        account_id: null,
        invoice_date: getCurrentDateTime(),
        invoice_due_date: getCurrentDateTime(),
        invoice_number: null,
        description: "",
        amount: null,
        order_number: "",
        subject: "",
        customer_notes: "",
        terms_conditions: "",
        currency_id: 1,
        status: "",
        items: [
            {
                item_id: null,
                quantity: null,
                price: 0,
                discount_type: "percentage",
                discount_val: 0
            }
        ],
        shipping_charges: 0,
        additional_charges: 0
    });
    const [currency, setCurrency] = useState([]);
    const [accountTypes, setAccountTypes] = useState([]);
    const [sales_invoices, setsales_invoices] = useState([]);
    const [menu, setMenu] = useState(false);
    const [error, setError] = useState("");
    const [items, setItems] = useState([]);
    // const [isSubmitting, setIsSubmitting] = useState(false);
    // const [error, setError] = useState(null);
    // const [startDate, setStartDate] = useState(new Date());

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    // const calculateTotal = () => {
    //     const amount = formData.items.reduce((acc, item) => {
    //         const selling_price = parseFloat(item.selling_price) || 0; // Use selling_price from the table
    //         const unit = parseFloat(item.unit) || 0;
    //         let discount = 0;
    //         const discountVal = parseFloat(item.discount_val) || 0;

    //         // Calculate discount based on discount type
    //         if (item.discount_type === DISCOUNT_TYPES.PERCENT) {
    //             discount = (selling_price * discountVal) / 100; // Percentage discount
    //         } else if (item.discount_type === DISCOUNT_TYPES.AMOUNT) {
    //             discount = discountVal / unit; // Fixed amount discount per unit
    //         }

    //         // Calculate total price for the item after discount
    //         const totalItemPrice = (selling_price - discount) * unit;
    //         return acc + totalItemPrice; // Accumulate total
    //     }, 0);

    //     const shippingCharges = parseFloat(formData.shipping_charges) || 0;
    //     const additionalCharges = parseFloat(formData.additional_charges) || 0;

    //     // Calculate total amount
    //     const totalAmount = amount + shippingCharges + additionalCharges;

    //     formData.amount = totalAmount; // Store total amount in formData
    //     return totalAmount;
    // };

    const calculateTotal = () => {
        const itemsTotal = formData.items.reduce((acc, item) => {
            const price = parseFloat(item.price) || 0;
            const discount =
                item.discount_type === "percentage"
                    ? (price * parseFloat(item.discount_val || 0)) / 100
                    : parseFloat(item.discount_val || 0);

            // Validate and parse quantity
            const quantity = parseFloat(item.quantity) || 0;

            return acc + (price - discount) * quantity;
        }, 0);

        // Parse and validate shipping and additional charges
        const shippingCharges = parseFloat(formData.shipping_charges) || 0;
        const additionalCharges = parseFloat(formData.additional_charges) || 0;

        return itemsTotal + shippingCharges + additionalCharges;
    };

    const calculateItemTotal = item => {
        const price = parseFloat(item.price) || 0;
        const discount =
            item.discount_type === "percentage"
                ? (price * parseFloat(item.discount_val || 0)) / 100
                : parseFloat(item.discount_val || 0);
        const quantity = parseFloat(item.quantity) || 0;

        return (price - discount) * quantity;
    };

    // const handleCustomerChange = e => {
    //     const selectedCustomerId = e.target.value;
    //     const selectedCustomer = sales_invoices.find(
    //         customer => customer.id === parseInt(selectedCustomerId)
    //     );

    //     // Find the account that matches the customer's account_id
    //     // const selectedAccount = selectedCustomer
    //     //     ? accountTypes.find(account => account.id === selectedCustomer.account_id)
    //     //     : null;

    //     setFormData({
    //         ...formData,
    //         customer_id: selectedCustomerId,
    //         account_id: selectedCustomer ? selectedCustomer.account_id : '' // Update account_id based on selected customer
    //     });
    // };

    const handleCustomerChange = e => {
        const selectedCustomerId = e.target.value;
        const selectedCustomer = sales_invoices.find(
            customer => customer.id === parseInt(selectedCustomerId)
        );
        // alert(`Customer ID: ${selectedCustomerId}, Account ID: ${selectedCustomer ? selectedCustomer.account_id : "N/A"}`);

        setFormData({
            ...formData,
            customer_id: selectedCustomerId,
            account_id: selectedCustomer ? selectedCustomer.account_id : "" // Update account_id based on customer
        });

        // Log the selected customer and account ID

        // Optionally, you can use alert instead of console.log
    };

    // const handleItemChange = (index, e) => {
    //     const { name, value } = e.target;
    //     const updatedItems = [...formData.items];

    //     updatedItems[index][name] = value;

    //     if (name === "item_id") {
    //         const selectedItem = items.find(item => item.id == value);
    //         if (selectedItem) {
    //             updatedItems[index].unit = selectedItem.unit;
    //             updatedItems[index].selling_price = selectedItem.selling_price;
    //         }
    //     }

    //     setFormData({
    //         ...formData,
    //         items: updatedItems
    //     });
    // };

    // const handleItemChange = (index, e) => {
    //     const { name, value } = e.target;
    //     const updatedItems = [...formData.items];
    //     updatedItems[index][name] = value;

    //     // If the item_id is changed, set the price
    //     if (name === "item_id") {
    //         const selectedItem = items.find(item => item.id === value);
    //         if (selectedItem) {
    //             updatedItems[index].price = selectedItem.selling_price;
    //         }
    //     }

    //     setFormData({
    //         ...formData,
    //         amount: calculateTotal(),
    //         items: updatedItems
    //     });
    // };
    const handleItemChange = (index, e) => {
        const { name, value } = e.target;
        const updatedItems = [...formData.items];

        updatedItems[index][name] = value;

        if (name === "item_id") {
            const selectedItem = items.find(item => item.id === Number(value));
            if (selectedItem) {
                updatedItems[index].price = selectedItem.selling_price;
                updatedItems[index].quantity = selectedItem.quantity || 1;
            }
        }

        // Log the updated formData.items
        console.log("Updated Items:", updatedItems);

        setFormData({
            ...formData,
            amount: calculateTotal(),
            items: updatedItems
        });
    };

    const addItem = () => {
        setFormData({
            ...formData,
            items: [
                ...formData.items,
                {
                    item_id: "",
                    quantity: 1,
                    price: 0,
                    discount_type: DISCOUNT_TYPES.PERCENT,
                    discount_val: 0,
                    invoice_date: "", // Initialize the invoice date
                    due_date: "" // Initialize the due date, will be calculated once invoice_date is selected
                }
            ]
        });
    };

    const removeItem = index => {
        const updatedItems = formData.items.filter((_, i) => i !== index);
        setFormData({ ...formData, items: updatedItems });
    };

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const customerData = { ...formData };

            //try {
            const response = id
                ? await axios.put(
                      `${process.env.REACT_APP_API_V1_BASE_URL}/sales_invoices/${id}`,
                      customerData,
                      {
                          headers: {
                              Authorization: `Bearer ${auth}`,
                              "Content-Type": "application/json"
                          }
                      }
                  )
                : await axios.post(
                      `${process.env.REACT_APP_API_V1_BASE_URL}/sales_invoices`,
                      customerData,
                      {
                          headers: {
                              Authorization: `Bearer ${auth}`,
                              "Content-Type": "application/json"
                          }
                      }
                  );

            console.log("Item saved successfully", response.data);
            navigate.goBack();
        } catch (error) {
            console.error("Error saving customer:", error);
            setError("Failed to save customer.");
        }
    };

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                    {
                        models: [
                            { model_name: "Customer" },
                            { model_name: "Account" },
                            { model_name: "Currency" },
                            { model_name: "Item" }
                        ]
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`
                        }
                    }
                );
                setAccountTypes(response.data.content.Account || []);
                setsales_invoices(response.data.content.Customer || []);
                setCurrency(response.data.content.Currency || []);
                // setItems(response.data.content.Item || []);
                setItems(response.data.content.Item || []);
                // setCurrency(response.data).content.currency || []);
            } catch (err) {
                console.log("Failed to load dropdown data");
                setError(" ");
            }
        };
        const fetchInvoiceData = async () => {
            if (id) {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_V1_BASE_URL}/sales_invoices/${id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${auth}`
                            }
                        }
                    );
                    setFormData(response.data);
                } catch (err) {
                    // Handle error
                }
            }
        };
        fetchInvoiceData();
        fetchDropdownData();
    }, [id, auth]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData(prevFormData => {
            // Create a copy of the previous form data
            const updatedFormData = { ...prevFormData, [name]: value };

            // If the name is 'invoice_date', calculate and set the due date
            if (name === "invoice_date") {
                const invoiceDate = new Date(value);
                const dueDate = new Date(invoiceDate);
                dueDate.setDate(invoiceDate.getDate() + 30);

                // Update the invoice due date immediately
                updatedFormData.invoice_due_date = dueDate
                    .toISOString()
                    .slice(0, 16);
            }

            return updatedFormData;
        });
    };
    console.log(formData.items);
    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <Header onMenuClick={toggleMobileMenu} />
                <Sidebar />
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="card mb-0">
                            <div className="card-body">
                                <div className="page-header">
                                    <div className="content-page-header">
                                        <h5>
                                            {id
                                                ? "Edit Invoice"
                                                : "Add Invoice"}
                                        </h5>
                                        {/* <h5>Add Invoice</h5> */}
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <CustomDropDownField
                                            options={[
                                                {
                                                    value: "",
                                                    label: "Select a customer"
                                                }, // Add a blank option
                                                ...sales_invoices.map(c => ({
                                                    value: c.id,
                                                    label: c.name
                                                }))
                                            ]}
                                            labelText="Customer"
                                            name="customer_id"
                                            onChange={handleCustomerChange}
                                            value={formData.customer_id}
                                        />
                                        <CustomDropDownField
                                            options={accountTypes.map(a => ({
                                                value: a.id,
                                                label: a.name
                                            }))}
                                            labelText="Account"
                                            name="account_id"
                                            onChange={handleChange}
                                            value={formData.account_id}
                                        />
                                        <CustomInputField
                                            inputType="datetime-local"
                                            labelText="Invoice Date"
                                            name="invoice_date"
                                            value={formData.invoice_date}
                                            onChange={handleChange}
                                        />
                                        <CustomInputField
                                            inputType="datetime-local"
                                            labelText="Invoice Due Date"
                                            name="invoice_due_date"
                                            value={formData.invoice_due_date}
                                            onChange={handleChange}
                                        />
                                        <CustomInputField
                                            inputType="text"
                                            labelText="Invoice Number"
                                            name="invoice_number"
                                            value={formData.invoice_number}
                                            onChange={handleChange}
                                        />
                                        <div className="col-lg-6 my-3">
                                            <CustomTextArea
                                                rows={5}
                                                name="customer_notes"
                                                value={formData.customer_notes}
                                                onChange={handleChange}
                                                placeholder="Customer Notes"
                                            />
                                        </div>
                                        <div className="col-lg-6 my-3">
                                            <CustomTextArea
                                                rows={5}
                                                name="description"
                                                value={formData.description}
                                                onChange={handleChange}
                                                placeholder="Description"
                                            />
                                        </div>

                                        {error && (
                                            <p className="text-danger">
                                                {error}
                                            </p>
                                        )}
                                        <div className="d-flex justify-content-end py-2 px-3 mt-2">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={addItem}
                                            >
                                                <i
                                                    className="fa fa-plus-circle me-2"
                                                    aria-hidden="true"
                                                ></i>
                                                Add Item
                                            </button>
                                        </div>
                                        <div className="form-group-item">
                                            <div className="card-table">
                                                <div className="card-body">
                                                    <div className="table-responsive no-pagination px-3">
                                                        <table className="table table-center table-hover datatable">
                                                            <thead className="thead-light">
                                                                <tr className="text-center">
                                                                    <th>
                                                                        Item
                                                                    </th>
                                                                    <th>
                                                                        Unit
                                                                    </th>
                                                                    <th>
                                                                        Rate
                                                                    </th>
                                                                    <th>
                                                                        Discount
                                                                        Type
                                                                    </th>
                                                                    <th>
                                                                        Discount
                                                                    </th>
                                                                    <th>
                                                                        Amount
                                                                    </th>
                                                                    <th className="no-sort">
                                                                        Action
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {formData.items.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <tr
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {/* Dropdown for Item selection */}
                                                                            <td className="w-20">
                                                                                <select
                                                                                    placeholder="select Item"
                                                                                    className="form-control"
                                                                                    name="item_id"
                                                                                    value={
                                                                                        item.item_id
                                                                                    }
                                                                                    onChange={e =>
                                                                                        handleItemChange(
                                                                                            index,
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <option value="">
                                                                                        {" "}
                                                                                        Select
                                                                                        Items
                                                                                    </option>
                                                                                    {items.map(
                                                                                        itemOption => (
                                                                                            <option
                                                                                                key={
                                                                                                    itemOption.id
                                                                                                }
                                                                                                value={
                                                                                                    itemOption.id
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    itemOption.name
                                                                                                }
                                                                                            </option>
                                                                                        )
                                                                                    )}
                                                                                </select>
                                                                            </td>

                                                                            {/* Input for Quantity */}
                                                                            <td>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    name="quantity"
                                                                                    step="0.01"
                                                                                    value={
                                                                                        item.quantity ||
                                                                                        0
                                                                                    }
                                                                                    onChange={e =>
                                                                                        handleItemChange(
                                                                                            index,
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </td>

                                                                            {/* Input for Price */}
                                                                            <td className="no-controls">
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    // placeholder="price"
                                                                                    name="price"
                                                                                    step="0.01"
                                                                                    value={
                                                                                        item.price
                                                                                    }
                                                                                    onChange={e =>
                                                                                        handleItemChange(
                                                                                            index,
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </td>

                                                                            {/* Dropdown for Discount Type */}
                                                                            <td>
                                                                                <select
                                                                                    className="form-control"
                                                                                    name="discount_type"
                                                                                    value={
                                                                                        item.discount_type
                                                                                    }
                                                                                    onChange={e =>
                                                                                        handleItemChange(
                                                                                            index,
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <option value="percentage">
                                                                                        %
                                                                                    </option>
                                                                                    <option value="amount">
                                                                                        $
                                                                                    </option>
                                                                                </select>
                                                                            </td>

                                                                            {/* Input for Discount Value */}
                                                                            <td>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    name="discount_val"
                                                                                    value={
                                                                                        item.discount_val
                                                                                    }
                                                                                    onChange={e =>
                                                                                        handleItemChange(
                                                                                            index,
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <p>
                                                                                    {calculateItemTotal(
                                                                                        item
                                                                                    ).toFixed(
                                                                                        2
                                                                                    )}
                                                                                </p>
                                                                            </td>

                                                                            {/* Remove Item Button */}
                                                                            <td>
                                                                                <div className="d-flex align-items-center justify-content-evenly">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="table_action_button"
                                                                                        onClick={() =>
                                                                                            removeItem(
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <i className="fe fe-trash-2" />
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                )}

                                                                {/* Total Calculation Row */}
                                                                <tr>
                                                                    <td
                                                                        colSpan="5"
                                                                        className="text-end fw-bold"
                                                                    >
                                                                        Total
                                                                    </td>
                                                                    <td
                                                                        colSpan="2"
                                                                        className="fw-bold"
                                                                    >
                                                                        $
                                                                        {calculateTotal().toFixed(
                                                                            2
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <CustomInputField
                                                labelText="Order No :"
                                                inputType="number"
                                                name="order_no"
                                                value={formData.order_no}
                                                onChange={handleChange}
                                                placeholder="Add Order Number here"
                                                inputWrapper="col-lg-3"
                                            />
                                            <CustomInputField
                                                labelText="Subject :"
                                                inputType="text"
                                                name="subject"
                                                value={formData.subject}
                                                onChange={handleChange}
                                                placeholder="Mention Subject here"
                                                inputWrapper="col-lg-3"
                                            />
                                            <CustomDropDownField
                                                labelText="Currency :"
                                                options={currency.map(cur => ({
                                                    value: cur.id,
                                                    label: cur.name
                                                }))}
                                                name="currency_id"
                                                onChange={handleChange}
                                            />

                                            <div className="col-lg-4">
                                                <CustomDropDownField
                                                    labelText="Status :"
                                                    options={[
                                                        {
                                                            value: "pending",
                                                            label: "Pending"
                                                        },
                                                        {
                                                            value: "paid",
                                                            label: "paid"
                                                        },
                                                        {
                                                            value: "cancelled",
                                                            label: "cancelled"
                                                        }
                                                    ]}
                                                    name="status"
                                                    value={formData.status}
                                                    onChange={handleChange}
                                                    dropDownSize="col-lg-12"
                                                />
                                                <div className="mt-5 d-flex align-items-center">
                                                    <h5 className="">
                                                        Total Amount :
                                                    </h5>
                                                    <p className="px-3">
                                                        $
                                                        {calculateTotal().toFixed(
                                                            2
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-8 input-block mb-3">
                                                <label > Terms & Condtions : </label>
                                                <CustomTextArea
                                                    rows={5}
                                                    name="terms_conditions"
                                                    value={
                                                        formData.terms_conditions
                                                    }
                                                    onChange={handleChange}
                                                    placeholder="Terms & Conditions"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-end pt-3">
                                        <button
                                            type="reset"
                                            className="btn btn-primary cancel me-2"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

AddInvoice.propTypes = {
    auth: PropTypes.string.isRequired
};

export default AddInvoice;
