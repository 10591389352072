import React, { useState } from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import ReportModal from "../../common/modal/reportModal";
import Breadcrumbs from "../../common/breadcrumb/breadCrumbs";
import { DatePicker } from "antd";
import Select from "react-select";
import axios from "axios";
import { useAuth } from '../../contexts/AuthContext';


const ProfitLossList = () => {
  const { auth: authToken } = useAuth();
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedstartDate, setSelecteStartDate] = useState(null);
  const [selectedendDate, setSelectEdendDate] = useState(null);
  const [selectedOptionThree, setSelectedOptionThree] = useState(null);
  const [profitLossData, setProfitLossData] = useState({
    income: { total: 0, accounts: [] },
    expenses: { total: 0, accounts: [] },
    gross_profit: 0,
    operating_income: 0,
    net_income: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [selectedperiodOption, setSelectedperiodOption] = useState(null);

  const periodoptions = [
    { value: 'current_fin_year', label: 'Current Financial Year' },
    { value: 'last_fin_year', label: 'Last Financial Year' },
    { value: 'last_3_months', label: 'Last 3 Months' },
    { value: 'last_6_months', label: 'Last 6 Months' },
    { value: 'custom', label: 'Custom' }, // Custom option for selecting a custom date range
  ];

  // Sample API base URL and token
  
  // API Call
  const getProfitAndLossStatement = async () => {
    setIsLoading(true); // Start loading
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_V1_BASE_URL}/getprofitandlossstatement`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        params: {
          start_date: selectedstartDate, // Use selectedDate here
          end_date: selectedendDate, // Use selectedDate here
        },
      });
      setProfitLossData(response.data.profit_and_loss); // Set the API response in state
    } catch (error) {
      console.error("Error fetching profit and loss data:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const handleStartDateChange = (date, dateString) => {
    setSelecteStartDate(dateString);
  };
  const handleEndDateChange = (date, dateString) => {
    setSelectEdendDate(dateString);
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  //const optionsTwo = [{ value: "1", label: "Month" }];
  const optionsThree = [{ value: "1", label: "Accrual" }];

  const handlePeriodChange = (selectedperiodOption) => {
    setSelectedperiodOption(selectedperiodOption);
    if (selectedperiodOption === 'custom') {
      // Show custom date picker or any custom logic
    }
  };

  const handleSelectChangeThree = (selectedOptionThree) => {
    setSelectedOptionThree(selectedOptionThree);
  };

  const handleRunButtonClick = () => {
    getProfitAndLossStatement(); // Fetch data when "Run" button is clicked
  };

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <Breadcrumbs title="Profit & Loss" show={show} setShow={setShow} />
            <ReportModal setShow={setShow} show={show} />
            <div className="profit-menu">
              <div className="row">
                {/* Date and filter options */}
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <label>Period</label>
                    <ul className="form-group">
                      <li>
                      <Select
                          value={selectedperiodOption}
                          options={periodoptions}
                          onChange={handlePeriodChange}
                          placeholder="Current Fin Year"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
                {selectedperiodOption && selectedperiodOption.value === 'custom' && (
                  <>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <label>From</label>
                    <div>
                      <DatePicker onChange={handleStartDateChange} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <label>To</label>
                    <div>
                      <DatePicker onChange={handleEndDateChange} />
                    </div>
                  </div>
                </div>
                </>
              )}
                {/* <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <label>Display Columns by</label>
                    <ul className="form-group">
                      <li>
                        <Select
                          value={selectedOptionTwo}
                          options={optionsTwo}
                          onChange={handleSelectChangeTwo}
                          placeholder="Month"
                        />
                      </li>
                    </ul>
                  </div>
                </div> */}
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <label>Accounting Method</label>
                    <ul className="form-group">
                      <li>
                        <Select
                          value={selectedOptionThree}
                          options={optionsThree}
                          onChange={handleSelectChangeThree}
                          placeholder="Accrual"
                          className="select"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <button
                    className="btn btn-primary loss"
                    onClick={handleRunButtonClick}
                  >
                    {isLoading ? "Running..." : "Run"} {/* Button text change */}
                  </button>
                </div>
              </div>
            </div>

            {/* Table to display profit and loss data */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card-table">
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="table-profit-loss">
                        <table className="table table-center ">
                          <thead className="thead-light loss">
                            <tr>
                              <th>Info</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* Income Section */}
                            <tr>
                              <td className="profit space" colSpan={2}>
                                <table className="table table-center profit">
                                  <thead className="profitloss-heading">
                                    <tr>
                                      <th
                                        className="table-profit-head"
                                        colSpan={2}
                                      >
                                        Income
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {profitLossData.income.accounts.length > 0 ? (
                                      profitLossData.income.accounts.map((account) => (
                                        <tr key={account.code}>
                                          <td>{account.name}</td>
                                          <td>{account.total}</td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan={2}>No income data available</td>
                                      </tr>
                                    )}
                                  </tbody>
                                  <tbody>
                                    <tr className="profitloss-bg">
                                      <td><h6>Total Income</h6></td>
                                      <td><h6>{profitLossData.income.total}</h6></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>

                            {/* Expenses Section */}
                            <tr>
                              <td className="loss-space" colSpan={2}>
                                <table className="table table-center profit">
                                  <thead className="profitloss-heading">
                                    <tr>
                                      <th
                                        className="table-profit-head"
                                        colSpan={2}
                                      >
                                        Expenses
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {profitLossData.expenses.accounts.length > 0 ? (
                                      profitLossData.expenses.accounts.map((account) => (
                                        <tr key={account.code}>
                                          <td>{account.name}</td>
                                          <td>{account.total}</td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan={2}>No expense data available</td>
                                      </tr>
                                    )}
                                  </tbody>
                                  <tbody>
                                    <tr className="profitloss-bg">
                                      <td><h6>Total Expenses</h6></td>
                                      <td><h6>{profitLossData.expenses.total}</h6></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>

                            {/* Net Income Section */}
                            <tr className="profitloss-bg">
                              <td><h6>Net Income</h6></td>
                              <td><h6>{profitLossData.net_income}</h6></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfitLossList;