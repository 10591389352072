import React, { useState, useEffect } from 'react';
import { PlaidLink } from 'react-plaid-link';
import { useAuth } from '../../contexts/AuthContext';

const PlaidDashboard = () => {
  const { auth: authToken } = useAuth();
  const [linkToken, setLinkToken] = useState(null);
  const [plaidItems, setPlaidItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch the link token for connecting a new bank account
  const fetchLinkToken = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_V1_BASE_URL}/plaid/create-link-token`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setLinkToken(data.link_token);
    } catch (error) {
      console.error('Error fetching link token:', error);
    }
  };

  // Fetch the list of Plaid items (linked bank accounts)
  const fetchPlaidItems = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_V1_BASE_URL}/plaid/items`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setPlaidItems(data || []);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching Plaid items:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLinkToken();
    fetchPlaidItems();
  }, []);

  // Handle successful linking of a new bank account
  const handleOnSuccess = async (publicToken) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_V1_BASE_URL}/plaid/exchange-token`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ public_token: publicToken }),
      });
      const data = await response.json();
      console.log('Plaid item linked:', data);

      // Update the list of linked Plaid items after successful linking
      fetchPlaidItems();
    } catch (error) {
      console.error('Error exchanging public token:', error);
    }
  };

  // Handle fetching auth details for a specific account
  const handleFetchAuth = async (accessToken) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_V1_BASE_URL}/plaid/accounts`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ access_token: accessToken }),
      });
      const data = await response.json();
      console.log('Auth data:', data);
    } catch (error) {
      console.error('Error fetching auth data:', error);
    }
  };

  // Handle fetching transactions for a specific account
  const handleFetchTransactions = async (accessToken) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_V1_BASE_URL}/plaid/accounts/transactions`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ access_token: accessToken }),
      });
      const data = await response.json();
      console.log('Transactions data:', data);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  return (
    <div>
      <h2>Linked Bank Accounts</h2>
      
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {plaidItems.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Account #</th>
                  <th>Routing #</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {plaidItems.map((item) => (
                  <tr key={item.id}>
                    <td>{item.institution_name}</td>
                    <td>{item.account_number}</td>
                    <td>{item.routing_number}</td>
                    <td>
                      <button onClick={() => handleFetchAuth(item.access_token)}>Accounts</button>
                      <button onClick={() => handleFetchTransactions(item.access_token)}>Transactions</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No bank accounts linked yet.</p>
          )}
        </div>
      )}

      <h2>Link a New Bank Account</h2>
      {linkToken ? (
        <PlaidLink
          token={linkToken}
          onSuccess={handleOnSuccess}
        >
          <button>Connect a bank account</button>
        </PlaidLink>
      ) : (
        <p>Generating link token...</p>
      )}
    </div>
  );
};

export default PlaidDashboard;
