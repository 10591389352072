import React from 'react';
import { Link } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import PropTypes from 'prop-types';

const CustomDropdown = ({ label, selectedValue, options, onOptionSelect, disabled }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <InputLabel sx={{ color: 'white' }}>{label}</InputLabel>
            <div className={`nav-item dropdown ${disabled ? 'disabled' : ''}`}>
                <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ color: 'black', backgroundColor: 'white', borderColor: 'white' }}
                    disabled={disabled}
                >
                    {selectedValue}
                </button>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                    {options.map((option) => (
                        <li key={option.id}>
                            <Link
                                to="#"
                                className="dropdown-item"
                                onClick={() => onOptionSelect(option)}
                                style={{ color: 'black' }}
                            >
                                {option.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

CustomDropdown.propTypes = {
    label: PropTypes.string.isRequired,                 
    selectedValue: PropTypes.string,         
    options: PropTypes.arrayOf(                        
        PropTypes.shape({
            id: PropTypes.oneOfType([                
                PropTypes.number, 
                PropTypes.string
            ]).isRequired,
            name: PropTypes.string.isRequired,         
        })
    ).isRequired,
    onOptionSelect: PropTypes.func.isRequired,
    disabled: PropTypes.bool,                   
};

CustomDropdown.defaultProps = {
    disabled: false,
};

export default CustomDropdown;