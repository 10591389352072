import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { AiaLogoWithName, WhiteLogo } from '../../_components/Imagepath';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { useAuth } from "../../contexts/AuthContext";

import axios from 'axios';

const InvoiceViewOne = () => {
    const { auth } = useAuth();  // Getting authentication token
    const { id } = useParams();  // Getting invoice id from the URL
    // Dummy invoice object to use as default before data is fetched
    const defaultInvoice = {
        company: {
            name: "Default Company",
            address: {
                address_line1: "123 Default Address",
                address_line2: "Suite 200",
                city: "Some City",
                state: "Some State",
                country: "Some Country",
                postal_code: "12345",
                get full_address() {
                    return `${this.address_line1}, ${this.address_line2}, ${this.city}, ${this.state}, ${this.postal_code}, ${this.country}`;
                },
            },
        },
        invoice_number: "INV-000000",
        invoice_date: "2024-01-01",
        due_date: "2024-01-15",
        customer: {
            name: "Default Customer",
            registration_number: "000000000000",
            billing_address: {
                address_line1: "299 Star Trek Drive",
                address_line2: "Suite 100",
                city: "Panama City",
                state: "Florida",
                country: "USA",
                postal_code: "32405",
                get full_address() {
                    return `${this.address_line1}, ${this.address_line2}, ${this.city}, ${this.state}, ${this.postal_code}, ${this.country}`;
                },
            },
            shipping_address: {
                address_line1: "299 Star Trek Drive",
                address_line2: "Suite 100",
                city: "Panama City",
                state: "Florida",
                country: "USA",
                postal_code: "32405",
                get full_address() {
                    return `${this.address_line1}, ${this.address_line2}, ${this.city}, ${this.state}, ${this.postal_code}, ${this.country}`;
                },
            },
        },
        payment_status: "Pending",
        items: [
            {
                name: "Sample Item",
                quantity: 1,
                unit_price: 0,
                discount: 0,
                total_amount: 0,
            },
        ],
        total_amount: 0,
    };


    const [invoice, setInvoice] = useState(defaultInvoice);  // State to store invoice data
    const [loading, setLoading] = useState(true);  // Loading state
    const [error, setError] = useState(null);      // Error state
    const fetchInvoiceData = async (flag) => {
        try {
            // Adding the 'show' flag to the API request
            const response = await axios.get(`${process.env.REACT_APP_API_V1_BASE_URL}/sales_invoices/${id}?flag=${flag}`, {
                headers: {
                    Authorization: `Bearer ${auth}`,
                    "Content-Type": "application/json"
                }
            });

            console.info(response.data);
            // Assuming the response structure contains 'data'
            setInvoice(JSON.parse(JSON.stringify(response.data.data)));  // Setting the fetched data to state
        } catch (err) {
            setError(err);  // Handling error
        } finally {
            setLoading(false);  // Stopping loading state
        }
    };
    const handlePrint = async () => {
        window.print();
    };
    const handleDownload = async (flag) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_V1_BASE_URL}/sales_invoices/${id}?flag=${flag}`, { // Ensure the correct API endpoint
                headers: {
                    Authorization: `Bearer ${auth}`,
                },
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `invoice.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();

            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error(`Error downloading :`, error);
        }
    };
    useEffect(() => {
        fetchInvoiceData('show');  // Call the fetch function on component mount
    }, [id, auth]);


    // If data is still loading
    if (loading) return <p>Loading...</p>;

    // If there's an error in fetching data
    if (error) return <p>Error loading invoice: {error.message}</p>;

    return (
        <>
            <div className="main-wrapper invoice-one" style={{ marginTop: '0px' }}>
                <div className="container">
                    <div className="invoice-wrapper download_section" style={{ marginTop: '0px' }} id="printableContent">
                        <div className="inv-content">
                            <div className="invoice-header">
                                <div className="inv-header-left">
                                    <h4>Invoice</h4>
                                    <div className="company-details">
                                        <div className="gst-details">
                                            <h6>{invoice.company.name}</h6>
                                            <span>
                                                Address: {invoice.company.address.address_line1}
                                                <br /> {invoice.company.address.address_line2} {invoice.company.address.city} {invoice.company.address.country}
                                            </span>
                                        </div>
                                        <div className="address-bg" />
                                    </div>
                                </div>
                                <div className="inv-header-right">
                                    <a href="#">
                                        <img
                                            className="logo-lightmode"
                                            src={AiaLogoWithName}
                                            alt="Logo"
                                        />
                                        <img
                                            className="logo-darkmode"
                                            src={WhiteLogo}
                                            alt="Logo"
                                        />
                                    </a>
                                    <h6>
                                        Invoice No : <span>{invoice.invoice_number}</span>
                                    </h6>
                                    <h6>
                                        Invoice Date :<span> {invoice.invoice_date}</span>
                                    </h6>
                                    <p>
                                        {" "}
                                        <span>Due Date : {invoice.due_date}</span>
                                    </p>
                                </div>
                            </div>
                            <span className="line" />
                            <h5>Customer Information</h5>
                            <div className="patient-infos">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className=" patient-detailed">
                                            <div className="bill-add">Customer Details :</div>
                                            <div className="customer-name">
                                                {invoice.customer.name}
                                                <p>
                                                    <span>Rg : {invoice.customer.registration_number}</span>
                                                </p>
                                            </div>
                                            <div className="payment-status">
                                                Payment Status{" "}
                                                <p>
                                                    <span>{invoice.payment_status}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className=" patient-detailed">
                                            <div className="bill-add">Billing Address :</div>
                                            <div className="add-details">
                                                {invoice.customer.billing_address.address_line1}
                                                <br /> {invoice.customer.billing_address.address_line2} {invoice.customer.billing_address.city}
                                                <br /> {invoice.customer.billing_address.state} {invoice.customer.billing_address.postal_code}
                                                <br /> {invoice.customer.billing_address.country}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className=" patient-detailed">
                                            <div className="bill-add">Shipping Address :</div>
                                            <div className="add-details">
                                                {invoice.customer.shipping_address.address_line1}
                                                <br /> {invoice.customer.shipping_address.address_line2} {invoice.customer.shipping_address.city}
                                                <br /> {invoice.customer.shipping_address.state} {invoice.customer.shipping_address.postal_code}
                                                <br /> {invoice.customer.shipping_address.country}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="invoice-table">
                                <div className="table-responsive">
                                    <table>
                                        <thead>
                                            <tr className="ecommercetable">
                                                <th className="table_width_1">#</th>
                                                <th className="table_width_2">Item</th>
                                                <th className="text-start">Quantity</th>
                                                <th className="text-start">Unit Price</th>
                                                <th className="text-start">Discount</th>
                                                <th className="text-end">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invoice.items.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td className="text-start">{item.name}</td>
                                                    <td className="text-start">{item.quantity}</td>
                                                    <td className="text-start unit-price-data">${item.unit_price}</td>
                                                    <td className="text-start">{item.discount}</td>
                                                    <td className="text-end">{item.total_amount}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="invoice-table-footer">
                                <div className="table-footer-left notes">
                                    <div className="logo3">
                                        {/* <img src={Paid} /> */}
                                    </div>
                                </div>
                                <div className="text-end table-footer-right">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span>Taxable Amount</span>
                                                </td>
                                                <td>{invoice.total_amount}</td>
                                            </tr>
                                            {/* <tr>
                                                <td>
                                                    <span>IGST 18.0%</span>
                                                </td>
                                                <td>$846.90</td>
                                            </tr> */}
                                            <tr>
                                                <td>
                                                    <span>Discount</span>
                                                </td>
                                                <td>$0.00</td>
                                            </tr>
                                            {/* <tr>
                                                <td>
                                                    <span>Round Off</span>
                                                </td>
                                                <td>-{invoice.total_amount}</td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="invoice-table-footer totalamount-footer">
                                <div className="table-footer-left"></div>
                                <div className="table-footer-right">
                                    <table className="totalamt-table">
                                        <tbody>
                                            <tr>
                                                <td>Total Amount</td>
                                                <td>{invoice.total_amount}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* <div className="total-amountdetails">
                                <p>
                                    Total amount ( in words):{" "}
                                    <span>{invoice.total_amount} dollars only.</span>
                                </p>
                            </div> */}

                            <div className="bank-details">
                                <div className="row">
                                    <div className="col md-6">
                                        <div className="payment-info">
                                            <div className="qr">
                                                {/* <img src={qr} alt="qr" /> */}
                                                {/* <h6 className="scan-details">Scan to View Receipt</h6> */}
                                            </div>
                                            <div className="pay-details">
                                                {/* <span className="payment-title">Payment Info:</span> */}
                                                <div>
                                                    {/* <span>Debit Card :</span> 465 *************645 */}
                                                </div>
                                                <div className="mb-0">
                                                    {/* <span>Amount :</span> $1,815 */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="terms-condition">
                                            <span>Terms &amp; Conditions:</span>
                                            <ol>
                                                <li> Goods Once sold cannot be taken back or exchanged</li>
                                                <li>
                                                    {" "}
                                                    We are not the manufactures, company will stand for warrenty
                                                    as per their terms and conditions.
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="thanks-msg text-center">Thanks for your Business</div>

                        </div>
                    </div>
                    <div className="file-link">
                        <button className="download_btn download-link"
                            onClick={() => handleDownload('pdf')} >
                            <FeatherIcon icon="download-cloud" style={{ marginRight: '5px' }} /><span>Download</span>
                        </button>
                        <button className="download_btn download-link"
                            onClick={() => handlePrint()} >
                            <FeatherIcon icon="printer" style={{ marginRight: '5px' }} /><span>Print</span>
                        </button>
                    </div>
                </div>
            </div >
        </>

    );
};

export default InvoiceViewOne;
