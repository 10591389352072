import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
// import InvoiceLogo1 from '../assets/invoice-logo1.png';

function Register() {
    const { login } = useAuth();
    const history = useHistory();
    const [step, setStep] = useState(1);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [emailOtp, setEmailOtp] = useState('');
    const [phoneOtp, setPhoneOtp] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyTypes, setCompanyTypes] = useState([]);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCompanyType, setSelectedCompanyType] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [validationError, setValidationError] = useState('');

    console.log(loading, error);
    const { authToken } = useAuth();

    const fetchDropdownData = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/get_dropdown_data`,
                {
                    models: [
                        { model_name: 'CompanyType' },
                        { model_name: 'Country' },
                    ],
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`
                    },
                }
            );

            const dropdownData = response.data.content;
            setCompanyTypes(dropdownData.CompanyType || []);
            setCountries(dropdownData.Country || []);
        } catch (err) {
            setError('Failed to load dropdown data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (step === 3) {
            fetchDropdownData();
        }
    }, [authToken, step]);

    const handleNextStep = (e) => {
        e.preventDefault();

        if (password.length < 8) {
            setValidationError('Password must be at least 8 characters long.');
            return;
        }

        if (password !== passwordConfirmation) {
            setValidationError('Password and Confirm Password do not match.');
            return;
        }

        setValidationError('');
        setStep(step + 1);
    };

    const handlePreviousStep = (e) => {
        e.preventDefault();
        setStep(step - 1);
    };

    const handleVerification = (e) => {
        e.preventDefault();
        setStep(step + 1);
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/register`,
                {
                    name,
                    email,
                    phone,
                    password,
                    email_otp: emailOtp,
                    phone_otp: phoneOtp,
                    company_name: companyName,
                    company_type_id: selectedCompanyType,
                    organization_name: companyName,
                    country_id: selectedCountry,
                    industry_type_id: 1,
                    password_confirmation: passwordConfirmation,
                    currency_id: 1,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.status === 200) {
                const token = `Bearer ${response.data.content.access_token}`;
                login(token);

                const branchId = response.data.content.branch.id;
                sessionStorage.setItem('branch_id', branchId);

                history.push('/index');  // Changed from navigate to history.push
            } else {
                console.error('Registration Failed:', response.data);
            }
        } catch (error) {
            console.error('Registration Failed:', error);
        }
    };

    return (
        <div className="main-wrapper login-body">
            <div className="login-wrapper">
                <div className="container">
                    {/* <img className="img-fluid logo-dark mb-2" src={InvoiceLogo1} alt="Logo" /> */}

                    <div className="loginbox">
                        <div className="login-right">
                            <div className="login-right-wrap">
                                <h1>Register</h1>
                                <p className="account-subtitle">Access to our dashboard</p>

                                {step === 1 && (
                                    <form onSubmit={handleNextStep}>
                                        <div className="input-block mb-3">
                                            <label className="form-control-label">Name</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="input-block mb-3">
                                            <label className="form-control-label">Email Address</label>
                                            <input
                                                className="form-control"
                                                type="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="input-block mb-3">
                                            <label className="form-control-label">Phone</label>
                                            <input
                                                className="form-control"
                                                type="tel"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                            />
                                        </div>
                                        <div className="input-block mb-3">
                                            <label className="form-control-label">Password</label>
                                            <input
                                                className="form-control"
                                                type="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="input-block mb-3">
                                            <label className="form-control-label">Confirm Password</label>
                                            <input
                                                className="form-control"
                                                type="password"
                                                value={passwordConfirmation}
                                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                                                required
                                            />
                                        </div>
                                        {validationError && (
                                            <div className="text-danger mb-3">
                                                {validationError}
                                            </div>
                                        )}
                                        <div className="input-block mb-0">
                                            <button className="btn btn-lg btn-block btn-primary w-100" type="submit">
                                                Next
                                            </button>
                                        </div>
                                    </form>
                                )}

                                {step === 2 && (
                                    <form onSubmit={handleVerification}>
                                        <div className="input-block mb-3">
                                            <label className="form-control-label">Email OTP</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={emailOtp}
                                                onChange={(e) => setEmailOtp(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="input-block mb-3">
                                            <label className="form-control-label">Phone OTP</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={phoneOtp}
                                                onChange={(e) => setPhoneOtp(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="input-block mb-0">
                                            <button className="btn btn-lg btn-block btn-primary w-100" type="submit">
                                                Verify
                                            </button>
                                        </div>
                                        <div className="mt-3">
                                            <button className="btn btn-lg btn-block btn-secondary w-100" onClick={handlePreviousStep}>
                                                Previous
                                            </button>
                                        </div>
                                    </form>
                                )}

                                {step === 3 && (
                                    <form onSubmit={handleRegister}>
                                        <div className="input-block mb-3">
                                            <label className="form-control-label">Company Name</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={companyName}
                                                onChange={(e) => setCompanyName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="input-block mb-3">
                                            <label className="form-control-label">Country</label>
                                            <select
                                                className="form-control"
                                                value={selectedCountry}
                                                onChange={(e) => setSelectedCountry(e.target.value)}
                                                required
                                            >
                                                <option value="">Select a Country</option>
                                                {countries.map((country) => (
                                                    <option key={country.id} value={country.id}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="input-block mb-3">
                                            <label className="form-control-label">Company Type</label>
                                            <select
                                                className="form-control"
                                                value={selectedCompanyType}
                                                onChange={(e) => setSelectedCompanyType(e.target.value)}
                                                required
                                            >
                                                <option value="">Select a Company Type</option>
                                                {companyTypes.map((type) => (
                                                    <option key={type.id} value={type.id}>
                                                        {type.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="input-block mb-0">
                                            <button className="btn btn-lg btn-block btn-primary w-100" type="submit">
                                                Register
                                            </button>
                                        </div>
                                        <div className="mt-3">
                                            <button className="btn btn-lg btn-block btn-secondary w-100" onClick={handlePreviousStep}>
                                                Previous
                                            </button>
                                        </div>
                                    </form>
                                )}

                                <div className="mt-4 text-center">
                                    <p>
                                        Already have an account? <Link to="/login">Login</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;