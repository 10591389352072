import React from 'react';
import { useHistory } from 'react-router-dom';
import { AiaLogoWithName } from "../_components/Imagepath";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { emailrgx } from "../assets/constant";
import { useAuth } from "../contexts/AuthContext";

const schema = yup.object({
    email: yup
        .string()
        .matches(emailrgx, "Invalid email address")
        .required("Email is required")
        .trim(),
    email_token: yup
        .string()
        .required("Email token is required")
        .trim(),
    phone_token: yup
        .string()
        .required("Phone token is required")
        .trim(),
});

const RegistrationVerificationForm = () => {
    const history = useHistory();
    const { login, setPermissions } = useAuth();

    const {
        handleSubmit,
        control,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
            email_token: "",
            phone_token: "",
        },
    });

    const onSubmit = async (data) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/verify_register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: data.email,
                    email_token: data.email_token,
                    phone_token: data.phone_token
                }),
            });

            const responseData = await response.json(); // Parse the response to JSON

            if (response.ok && responseData.status !== "error") {
                const token = `Bearer ${responseData.content.access_token}`;
                login(token);
                // Store session data
                sessionStorage.setItem('branch_id', responseData.content.branch_id);
                sessionStorage.setItem('user_id', responseData.content.user.id);
                sessionStorage.setItem('role_id', responseData.content.role_id);

                // Fetch permissions
                const permissionsResponse = await fetch(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/roles/${responseData.content.role_id}/permissions`,
                    {
                        method: 'GET',
                        headers: {
                            'Authorization': token,
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if (permissionsResponse.ok) {
                    const permissionsData = await permissionsResponse.json();
                    setPermissions(permissionsData);
                    history.push('/index');
                } else {
                    console.error('Failed to fetch permissions');
                }
            } else {
                // Display the API error message
                if (responseData.content?.message) {
                    setError("apiError", { message: responseData.content.message });
                } else {
                    setError("apiError", { message: "Verification failed" });
                }
            }
        } catch (error) {
            console.error('Verification failed:', error);
            setError("apiError", { message: 'Verification failed. Please try again later.' });
        }
    };

    return (
        <>
            <div className="main-wrapper login-body">
                <div className="login-wrapper">
                    <div className="container">
                        <img
                            className="img-fluid logo-dark mb-2"
                            src={AiaLogoWithName}
                            alt="Logo"
                        />
                        <div className="loginbox">
                            <div className="login-right">
                                <div className="login-right-wrap">
                                    <h1>Verify</h1>
                                    <p className="account-subtitle">Verify your account</p>
                                    <div>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="input-block mb-3 input_text">
                                                <label className="form-control-label">Email Address</label>
                                                <Controller
                                                    name="email"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <input
                                                            className={`form-control ${errors?.email ? "error-input" : ""}`}
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoComplete="false"
                                                        />
                                                    )}
                                                />
                                                <small>{errors?.email?.message}</small>
                                            </div>
                                            <div className="input-block mb-3 input_text">
                                                <label className="form-control-label">Email Token</label>
                                                <Controller
                                                    name="email_token"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <input
                                                            className={`form-control ${errors?.email_token ? "error-input" : ""}`}
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoComplete="false"
                                                        />
                                                    )}
                                                />
                                                <small>{errors?.email_token?.message}</small>
                                            </div>
                                            <div className="input-block mb-3 input_text">
                                                <label className="form-control-label">Phone Token</label>
                                                <Controller
                                                    name="phone_token"
                                                    control={control}
                                                    render={({ field: { value, onChange } }) => (
                                                        <input
                                                            className={`form-control ${errors?.phone_token ? "error-input" : ""}`}
                                                            type="text"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoComplete="false"
                                                        />
                                                    )}
                                                />
                                                <small>{errors?.phone_token?.message}</small>
                                            </div>

                                            {/* Display API Error */}
                                            {errors.apiError && (
                                                <div className="alert alert-danger">
                                                    {errors.apiError.message}
                                                </div>
                                            )}

                                            <button
                                                className="btn btn-lg btn-block w-100 btn-primary"
                                                type="submit"
                                            >
                                                Verify
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RegistrationVerificationForm;


// import React, { } from "react";
// import { useHistory } from "react-router-dom";
// import { InvoiceLogo1 } from "../_components/Imagepath";
// import { useForm, Controller } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
// import { emailrgx } from "../assets/constant";
// import { useAuth } from "../contexts/AuthContext";

// const schema = yup.object({
//     email: yup
//         .string()
//         .matches(emailrgx, "Invalid email address")
//         .required("Email is required")
//         .trim(),
//     email_token: yup
//         .string()
//         .required("Email token is required")
//         .trim(),
//     phone_token: yup
//         .string()
//         .required("Phone token is required")
//         .trim(),

// });

// const RegistrationVerificationForm = () => {
//     const history = useHistory();
//     // const navigate = useNavigate();
//     const { login, setPermissions } = useAuth();

//     const {
//         handleSubmit,
//         control,
//         setError,
//         // clearErrors,
//         formState: { errors },
//     } = useForm({
//         resolver: yupResolver(schema),
//         defaultValues: {
//             email: "",
//             email_token: "",
//             phone_token: "",
//         },
//     });

//     const onSubmit = async (data) => {
//         try {
//             const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/verify_register`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     email: data.email,
//                     email_token: data.email_token,
//                     phone_token: data.phone_token
//                 }),
//             });

//             const responseData = await response.json();

//             if (response.ok) {
//                 const token = `Bearer ${responseData.content.access_token}`;
//                 login(token);

//                 // Store session data
//                 sessionStorage.setItem('branch_id', responseData.content.branch_id);
//                 sessionStorage.setItem('user_id', responseData.content.user.id);
//                 sessionStorage.setItem('role_id', responseData.content.role_id);

//                 // Fetch permissions
//                 const permissionsResponse = await fetch(
//                     `${process.env.REACT_APP_API_V1_BASE_URL}/roles/${responseData.content.role_id}/permissions`,
//                     {
//                         method: 'GET',
//                         headers: {
//                             'Authorization': token,
//                             'Content-Type': 'application/json',
//                         },
//                     }
//                 );

//                 if (permissionsResponse.ok) {
//                     const permissionsData = await permissionsResponse.json();
//                     setPermissions(permissionsData);
//                     // navigate('/dashboard');
//                     history.push('/index');
//                 } else {
//                     console.error('Failed to fetch permissions');
//                 }
//             } else {
//                 setError("password", { message: responseData.message || "Login failed" });
//             }
//         } catch (error) {
//             console.error('Login failed:', error);
//             setError("password", { message: 'Login failed. Please try again later.' });
//         }
//     };

//     return (
//         <>
//             <div className="main-wrapper login-body">
//                 <div className="login-wrapper">
//                     <div className="container">
//                         <img
//                             className="img-fluid logo-dark mb-2"
//                             src={InvoiceLogo1}
//                             alt="Logo"
//                         />
//                         <div className="loginbox">
//                             <div className="login-right">
//                                 <div className="login-right-wrap">
//                                     <h1>Verify</h1>
//                                     <p className="account-subtitle">Verify your account</p>
//                                     <div>
//                                         <form onSubmit={handleSubmit(onSubmit)}>
//                                             <div className="input-block mb-3 input_text">
//                                                 <label className="form-control-label">Email Address</label>
//                                                 <Controller
//                                                     name="email"
//                                                     control={control}
//                                                     render={({ field: { value, onChange } }) => (
//                                                         <input
//                                                             className={`form-control ${errors?.email ? "error-input" : ""}`}
//                                                             type="text"
//                                                             value={value}
//                                                             onChange={onChange}
//                                                             autoComplete="false"
//                                                         />
//                                                     )}
//                                                 />
//                                                 <small>{errors?.email?.message}</small>
//                                             </div>
//                                             <div className="input-block mb-3 input_text">
//                                                 <label className="form-control-label">Email Token</label>
//                                                 <Controller
//                                                     name="email_token"
//                                                     control={control}
//                                                     render={({ field: { value, onChange } }) => (
//                                                         <input
//                                                             className={`form-control ${errors?.email_token ? "error-input" : ""}`}
//                                                             type="text"
//                                                             value={value}
//                                                             onChange={onChange}
//                                                             autoComplete="false"
//                                                         />
//                                                     )}
//                                                 />
//                                                 <small>{errors?.email_token?.message}</small>
//                                             </div>
//                                             <div className="input-block mb-3 input_text">
//                                                 <label className="form-control-label">Phone Token</label>
//                                                 <Controller
//                                                     name="phone_token"
//                                                     control={control}
//                                                     render={({ field: { value, onChange } }) => (
//                                                         <input
//                                                             className={`form-control ${errors?.phone_token ? "error-input" : ""}`}
//                                                             type="text"
//                                                             value={value}
//                                                             onChange={onChange}
//                                                             autoComplete="false"
//                                                         />
//                                                     )}
//                                                 />
//                                                 <small>{errors?.phone_token?.message}</small>
//                                             </div>
//                                             <button
//                                                 className="btn btn-lg btn-block w-100 btn-primary"
//                                                 type="submit"
//                                             >
//                                                 Verify
//                                             </button>
//                                         </form>


//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default RegistrationVerificationForm;