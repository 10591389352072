import React, { useState } from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import InvoiceViewOne from "./invoice-view-one";
const ShowInvoice = () => {
    // State for managing mobile menu toggle
    const [menu, setMenu] = useState(false);

    // Function to toggle the mobile menu
    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    return (
        <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
            <Header onMenuClick={toggleMobileMenu} />
            <Sidebar />
            {/* Page Wrapper */}
            <div className="page-wrapper">
                <InvoiceViewOne />
            </div>
        </div>
    );
};

export default ShowInvoice;
