import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Table, Tooltip } from "antd";
import axios from "axios";
// import FeatherIcon from "feather-icons-react";

import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { itemRender, onShowSizeChange } from "../_components/paginationfunction";
// import AddVendor from "../vendors/addVendor";
import { filterIcon } from "../_components/Imagepath";
import { useAuth } from "../contexts/AuthContext";
// import AddCustomerForm from "./AddCustomerForm";

const CustomersMain = () => {
  const { auth } = useAuth();
  const history = useHistory();
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [columns, setColumns] = useState([]);

  // Toggle Mobile Menu
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  // Handle Edit Customer
  const handleEdit = (id) => {
    history.push(`/edit-customers/${id}`);
  };

  // Handle Delete Customer
  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_V1_BASE_URL}/customers/${id}`, {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });

      if (response.status === 200) {
        await fetchCustomerDetails(); // Refresh data after delete
        console.log("Customer deleted successfully:", id);
      }
    } catch (error) {
      console.error("Error deleting customer:", error);
    }
  };

  // Fetch Customer Details
  const fetchCustomerDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_V1_BASE_URL}/customers`, {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });

      const records = response.data.records;
      const headers = response.data.headers;

      const dynamicColumns = headers.map((header) => ({
        title: header.display_name,
        dataIndex: header.field_name,
        sorter: header.sortable ? (a, b) => a[header.field_name]?.localeCompare(b[header.field_name]) : null,
        render: (text) => text,
      }));

      // Add actions column
      dynamicColumns.push({
        title: "Actions",
        dataIndex: "actions",
        render: (_, record) => (
          <div className="d-flex align-items-center">
            <button className="table_action_button" onClick={() => handleEdit(record.id)}>
              <i className="far fa-edit" />
            </button>
            <button className="table_action_button" onClick={() => handleDelete(record.id)}>
              <i className="far fa-trash-alt" />
            </button>
          </div>
        ),
      });

      setColumns(dynamicColumns);
      setCustomerData(records);
    } catch (error) {
      console.error("Error fetching customer details:", error);
    }
  };

  useEffect(() => {
    fetchCustomerDetails();
  }, [auth]);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={toggleMobileMenu} />
        <Sidebar />
        <div className="page-wrapper customers">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="content-page-header">
                <h5>Customers Details</h5>
                <div className="list-btn">
                  <ul className="filter-list">
                    <li>
                      <Link
                        to="#"
                        className="btn btn-filters w-auto popup-toggle me-2"
                        onClick={() => setShow(!show)}
                      >
                        <span className="me-2">
                          <Tooltip title="Filter" placement="bottom">
                            <img src={filterIcon} alt="filtericon" />
                          </Tooltip>
                        </span>
                        Filter
                      </Link>
                    </li>
                    <li>
                      <Link className="btn btn-primary" to="/add-customer">
                        <i className="fa fa-plus-circle me-2" aria-hidden="true" />
                        Add Customer
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card customers">
                  <div className="card-body">
                    <div className="table-responsive table-hover">
                      <Table
                        className="table"
                        pagination={{
                          total: customerData.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        columns={columns}
                        dataSource={customerData}
                        rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <AddVendor setShow={setShow} show={show} /> */}
        {/* <AddCustomerForm setShow ={setShow} show={show}/> */}


        <div className="modal custom-modal fade" id="delete_modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>Delete Customer</h3>
                  <p>Are you sure want to delete?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <button
                        type="reset"
                        data-bs-dismiss="modal"
                        className="w-100 btn btn-primary paid-continue-btn"
                      >
                        Delete
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        type="submit"
                        data-bs-dismiss="modal"
                        className="w-100 btn btn-primary paid-cancel-btn"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomersMain;
