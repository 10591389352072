import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { CustomInputField } from "../CustomComponents/ReusableComponents";
import PropTypes from 'prop-types';

const AddTransactionTypes = () => {
    const { auth } = useAuth();
    // const [transactionTypes, setTransactionTypes] = useState([]);

    const [formData, setFormData] = useState({
        name: '',
    });

    // useEffect(() => {
    //     const fetchDropdownData = async () => {
    //         try {
    //             const response = await axios.post(
    //                 `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
    //                 {
    //                     models: [
    //                         { model_name: "TransactionType" }
    //                     ]
    //                 },
    //                 {
    //                     headers: {
    //                         Authorization: `Bearer ${auth}`
    //                     }
    //                 }
    //             );
    //             setTransactionTypes(response.data.content.TransactionType || []);
    //         } catch (err) {
    //             // setError("Failed to load dropdown data");
    //         }
    //     };

    //     fetchDropdownData();
    // }, [auth]);

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_V1_BASE_URL}/transaction_types`,
                { ...formData, items: formData.items },
                {
                    headers: {
                        Authorization: `Bearer ${auth}`,
                        "Content-Type": "application/json"
                    }
                }
            );
            console.log("Transaction Type created successfully", response.data);
        } catch (err) {
            console.error("Failed to create Transaction Type", err);
        }
    }

    return(
        <>
        <div
            className="modal fade"
            id="add_transaction_types"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="addTransactionTypesLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="addTransactionTypesLabel">Add Transaction Type</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <CustomInputField
                        inputType="text"
                        labelText="Name"
                        inputWrapper="col-lg-12"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={handleSubmit}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>

        </>
    );
};

AddTransactionTypes.propTypes = {
    id: PropTypes.string.isRequired
};

  
export default AddTransactionTypes;