import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import MainContainer from "../CustomComponents/MainContainer";
import {
    CustomDropDownField,
    CustomInputField,
    CustomTextArea,
} from "../CustomComponents/ReusableComponents";

import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";

const TransactionForm = () => {
    const { auth } = useAuth();
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useHistory();
    const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;

    const [formData, setFormData] = useState({
        transaction_type_id: "",
        from_account_id: "",
        to_account_id: "",
        amount: "",
        transaction_date: "",
        description: "",
        status: "",
        is_reconciled: false,
        is_debit: false,
        customer_id: "",
        vendor_id: "",
        contact_id: "",
        bank_account: "",
    });

    const [transactionTypes, setTransactionTypes] = useState([]);
    // const [status,setStatus] = useState([statusOptions])
    const [accounts, setAccounts] = useState([]);
    const [ customers, setCustomers ] = useState([]);
    const [ vendors, setVendors ] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const statusOptions = [
        { value: "pending", label: "pending" },
        { value: "completed", label: "completed" },
        { value: "void", label: "void" }
    ];
    const handleToggle = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            is_debit: prevFormData.is_debit === "1" ? "0" : "1"
        }));
    };

    const handleToggleRecline  = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            is_reconciled: prevFormData.is_reconciled === "1" ? "0" : "1"
        }));
    };

    useEffect(() => {
        console.log(error);

        const fetchDropdownData = async () => {
            setLoading(true);
            setError(null);
            try {
                const dropdownRequestData = {
                    models: [
                        { model_name: "TransactionType" },
                        { model_name: "Account" },
                        { model_name: "Customer" },
                        { model_name: "Vendor" },
                    ]
                }; // Define your dropdown request data as needed
                const apiReqHeaders = {
                    headers: {
                        Authorization: `Bearer ${auth}`,
                        "Content-Type": "application/json"
                    }
                };

                const response = await axios.post(
                    `${API_BASE_URL}/get_dropdown_data`,
                    dropdownRequestData,
                    apiReqHeaders
                );

                const transactionTypeDropdownData =
                    response.data.content.TransactionType || [];
                setTransactionTypes(transactionTypeDropdownData);

                const accountDropdownData = response.data.content.Account || [];
                setAccounts(accountDropdownData);

                const customerDropdownData = response.data.content.Customer || [];
                setCustomers(customerDropdownData);

                const vendorDropdownData = response.data.content.Vendor || [];
                setVendors(vendorDropdownData);

                // setCountries(Currency || [1,2,3]);
            } catch (error) {
                setError("Error fetching dropdown data");
                // console.error("Error fetching dropdown data:", error);
            }
        };

        const fetchTransactionData = async () => {
            if (id) {
                try {
                    const apiReqHeaders = {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                            "Content-Type": "application/json"
                        }
                    };

                    const response = await axios.get(
                        `${API_BASE_URL}/transactions/${id}`,
                        apiReqHeaders
                    );
                    setFormData({
                        ...formData,
                        ...response.data
                    });
                } catch (error) {
                    setError("Error fetching customer data");
                    console.error("Error fetching customer data:", error);
                }
                console.log(loading);
            }
        };

        fetchDropdownData();
        fetchTransactionData();
        setLoading(false);
    }, [API_BASE_URL, auth, id]);

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // const handleSubmit = async e => {
    //     e.preventDefault();
    //     try {
    //         const apiReqHeaders = {
    //             headers: {
    //                 Authorization: `Bearer ${auth}`,
    //                 "Content-Type": "application/json"
    //             }
    //         };

    //         if (id) {
    //             await axios.put(
    //                 `${API_BASE_URL}/transactions/${id}`,
    //                 formData,
    //                 apiReqHeaders
    //             );
    //             alert(t("Transaction updated successfully!"));
    //         } else {
    //             await axios.post(
    //                 `${API_BASE_URL}/transactions`,
    //                 formData,
    //                 apiReqHeaders
    //             );
    //             alert(t("Transaction created successfully!"));
    //         }
    //         navigate("/dashboard/transactions");
    //     } catch (error) {
    //         console.error("Error submitting form:", error);
    //         alert(t("Failed to save transaction."));
    //     }
    //     navigate.goBack()
    // };

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const apiReqHeaders = {
                headers: {
                    Authorization: `Bearer ${auth}`,
                    "Content-Type": "application/json"
                }
            };
    
            if (id) {
                await axios.put(
                    `${API_BASE_URL}/transactions/${id}`,
                    formData,
                    apiReqHeaders
                );
                alert(t("Transaction updated successfully!"));
            } else {
                await axios.post(
                    `${API_BASE_URL}/transactions`,
                    formData,
                    apiReqHeaders
                );
                alert(t("Transaction created successfully!"));
            }
    
            // Only navigate after a successful request
            navigate.push("/transactions");
        } catch (error) {
            console.error("Error submitting form:", error);
            alert(t("Failed to save transaction."));
        }
    };

    const cancelAction =() =>{
        navigate.goBack();      
    }

    return (
        <>
            <Header />
            <Sidebar />
            <MainContainer>
            <div className="content-page-header">
                <h5>{id ? 'Edit Transaction' : 'Add Transaction'}</h5>
                {/* <h5>Add Invoice</h5> */}
            </div>

                <div className="row">
                    <CustomDropDownField
                        options={transactionTypes.map(c => ({
                            value: c.id,
                            label: c.name
                        }))}
                        labelText="Transaction Type"
                        onChange={handleChange}
                        name="transaction_type_id"
                        value={formData.transaction_type_id}
                    />

                    <CustomDropDownField
                        options={accounts.map(c => ({
                            value: c.id,
                            label: c.name
                        }))}
                        labelText=" From Account "
                        name="from_account_id"
                        onChange={handleChange}
                        dropDownSize="col-lg-6"
                        value={formData.from_account_id}
                    />
                    <CustomDropDownField
                        options={statusOptions.map(c => ({
                            value: c.value, // Correctly map value
                            label: c.label // Correctly map label
                        }))}
                        labelText="Status"
                        name="status"
                        onChange={handleChange}
                        dropDownSize="col-lg-6"
                        value={formData.status}
                    />

                    <CustomDropDownField
                        options={accounts.map(c => ({
                            value: c.id,
                            label: c.name
                        }))}
                        labelText=" To Account "
                        name="to_account_id"
                        onChange={handleChange}
                        dropDownSize="col-lg-6"
                        value={formData.to_account_id}
                    />

                    <CustomDropDownField
                        options={customers.map(c => ({
                            value: c.id,
                            label: c.name
                        }))}
                        labelText="Customer Account "
                        name="customer_id"
                        onChange={handleChange}
                        dropDownSize="col-lg-6"
                        value={formData.customer_id}
                    />
                    <CustomDropDownField
                        options={vendors.map(c => ({
                            value: c.id,
                            label: c.name
                        }))}
                        labelText="Vendor Account "
                        name="vendor_id"
                        onChange={handleChange}
                        dropDownSize="col-lg-6"
                        value={formData.vendor_id}
                    />
                
                    <CustomInputField
                        labelText="Bank Account"
                        value={formData.bank_account}
                        inputType="number"
                        name="bank_account"
                        onChange={handleChange}
                    />

                    <CustomInputField
                        labelText="Amount"
                        value={formData.amount}
                        inputType="number"
                        name="amount"
                        onChange={handleChange}
                    />
                    <CustomInputField
                        labelText="Transaction Date"
                        inputType="datetime-local"
                        name="transaction_date"
                        value={formData.transaction_date}
                        onChange={handleChange}
                    />
                    <div className="col-lg-8">
                        <CustomTextArea
                            labelText="Description"
                            placeholder="Enter Description Here ..."
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className="mb-3">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="isActiveSwitch"
                                checked={
                                    formData.is_debit === "1"
                                }
                                onChange={handleToggle}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="isActiveSwitch"
                            >
                                {formData.is_debit === "1"
                                    ? "Debit"
                                    : "Credit"}
                            </label>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="isActiveSwitch"
                                checked={
                                    formData.is_reconciled === "1"
                                }
                                onChange={handleToggleRecline}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="isActiveSwitch"
                            >
                                {formData.is_reconciled === "1"
                                    ? "Reconciled"
                                    : "Not Reconciled"}
                            </label>
                        </div>
                    </div>
                </div>
                <div className=" d-flex justify-content-end my-3">
                    <div className="preview-boxs py-3">
                        {/* <Link to="/signature-preview-invoice">Preview Invoice</Link> */}
                        <button
                            type="reset"
                            className="btn btn-primary cancel me-2"
                            onClick={cancelAction}
                        >
                            Cancel
                        </button>
                        <button
                            type="reset"
                            onClick={handleSubmit}
                            className="btn btn-primary  me-2"
                        >
                            Save
                        </button>
                        {/* <button type="submit" className="btn btn-primary">
                Save & Send
            </button> */}
                    </div>
                </div>
            </MainContainer>
        </>
    );
};

export default TransactionForm;
