import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import PropTypes from 'prop-types';

const ProtectedRoute = ({ component: Component }) => {
    const { auth } = useAuth();
    const history = useHistory();

    if (!auth) {
        history.push('/');
        return null;
    }

    return <Component />;
};

ProtectedRoute.propTypes = {
    component: PropTypes.elementType.isRequired,
};

export default ProtectedRoute;