

import React from 'react';
import PageComponent from '../../CustomComponents/PageComponent';
// import PageComponent from '../CustomComponents/PageComponent';
const Customers = () => {
  return(
    <PageComponent listAPI='customers' label='Customers' route="customers" addType='nextPage' addLabel='Customer'/>
  )
}

export default Customers;


// import React, { useState, useEffect } from "react";
// import { Link, 
//   // useHistory 
// } from "react-router-dom";
// import Header from "../../layouts/Header";
// import Sidebar from "../../layouts/Sidebar";
// import FeatherIcon from "feather-icons-react";
// import { Table, Tooltip } from "antd";
// import { onShowSizeChange, itemRender } from "../../_components/paginationfunction";
// // import AddVendor from "../vendors/addVendor";
// import { filterIcon } from "../../_components/Imagepath";
// import axios from "axios";
// import { useAuth } from "../../contexts/AuthContext";
// import { useHistory } from "react-router-dom";
// const Customers = () => {
//   const { auth } = useAuth();
//   // const history = useHistory();
//   const [menu, setMenu] = useState(false);
//   const [show, setShow] = useState(false);
//   const [customerData, setCustomerData] = useState([]);
//   const [columns, setColumns] = useState([]);

  
//   const history = useHistory();
//   const toggleMobileMenu = () => {
//     setMenu(!menu);
//   };

//   const handleEdit = (id) => {
//     history.push(`/edit-customers/${id}`);
//   };


//   const fetchCustomerDetails = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_V1_BASE_URL}/customers`, {
//         headers: {
//           Authorization: `Bearer ${auth}`,
//         },
//       });
//       console.log("response", response);
//       const records = response.data.records;
//       const headers = response.data.headers;

//       const dynamicColumns = headers.map(header => ({
//         title: header.display_name,
//         dataIndex: header.field_name,
//         sorter: header.sortable ? (a, b) => a[header.field_name]?.localeCompare(b[header.field_name]) : null,
//         render: (text) => text,
//       }));

//       dynamicColumns.push({
//         title: 'Actions',
//         dataIndex: 'actions',
//         render: (_, record) => (
//           <div className="d-flex align-items-center">
//             <button 
//               className="table_action_button d-block" 
//               onClick={() => handleEdit(record.id)}
//             >
//               <Link
//                         to="/edit-customer"
//                         // className={pathname === "/index" ? "active" : ""}
//                       >
//               <i className="far fa-edit" />
//               {/* Edit */}
//                       </Link>
            
//             </button>
//             <button 
//               className="table_action_button" 
//               onClick={() => handleDelete(record.id)}
//             >
//               <i className="far fa-trash-alt " />
//               {/* Delete */}
//             </button>
//           </div>
//         ),
//       });

//       setColumns(dynamicColumns);
//       setCustomerData(records);
//     } catch (error) {
//       console.error("Error fetching customer details:", error);
//     }
//   };

//   const handleDelete = async (id) => {
//     try {
//       const response = await axios.delete(`${process.env.REACT_APP_API_V1_BASE_URL}/customers/${id}`, {
//         headers: {
//           Authorization: `Bearer ${auth}`,
//         },
//       });

//       if (response.status === 200) {
//         await fetchCustomerDetails();
//         console.log("Customer deleted successfully:", id);
//       }
//     } catch (error) {
//       console.error("Error deleting customer:", error);
//     }
//   };

//   // const handleDelete = async (id) => {
//   //   try {
//   //     const response = await axios.delete(`${process.env.REACT_APP_API_V1_BASE_URL}/accounts/${id}`, {
//   //       headers: {
//   //         Authorization: `Bearer ${auth}`,
//   //       },
//   //     });
  
//   //     if (response.status === 200) {
//   //       console.log("Account type deleted successfully.");
//   //       fetchAccountDetails();
//   //     }
//   //   } catch (error) {
//   //     console.error("There was an error deleting the account type:", error.response ? error.response.data : error.message);
//   //   }
//   // }

//   useEffect(() => {
//     fetchCustomerDetails();
//   }, [auth]);

//   return (
//     <>
//       <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
//         <Header onMenuClick={toggleMobileMenu} />
//         <Sidebar />
//         <div className="page-wrapper customers">
//           <div className="content container-fluid">
//             <div className="page-header">
//               <div className="content-page-header">
//                 <h5>Customers </h5>
//                 <div className="list-btn">
//                   <ul className="filter-list">
//                     <li>
//                       <Link
//                         to="#"
//                         className="btn btn-filters w-auto popup-toggle me-2"
//                         onClick={() => setShow(!show)}
//                       >
//                         <span className="me-2">
//                           <Tooltip title="Filter" placement="bottom">
//                             <img src={filterIcon} alt='filtericon' />
//                           </Tooltip>
//                         </span>
//                         Filter
//                       </Link>
//                     </li>
//                     <li>
//                       <div className="dropdown dropdown-action">
//                         <Link
//                           to="#"
//                           className="btn-filters me-2"
//                           data-bs-toggle="dropdown"
//                           aria-expanded="false"
//                         >
//                           <span>
//                             <Tooltip title="Download" placement="bottom">
//                               <i className="fe fe-download" />
//                             </Tooltip>
//                           </span>
//                         </Link>
//                         <div className="dropdown-menu dropdown-menu-end">
//                           <ul className="d-block">
//                             <li>
//                               <Link
//                                 className="d-flex align-items-center download-item"
//                                 to="#"
//                                 download=""
//                               >
//                                 <i className="far fa-file-pdf me-2" />
//                                 PDF
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 className="d-flex align-items-center download-item"
//                                 to="#"
//                                 download=""
//                               >
//                                 <i className="far fa-file-text me-2" />
//                                 CVS
//                               </Link>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                     </li>
//                     <li>
//                       <Link className="btn-filters" to="#">
//                         <span>
//                           <Tooltip title="Print" placement="bottom">
//                             <i className="fe fe-printer" />
//                           </Tooltip>
//                         </span>
//                       </Link>
//                     </li>
//                     <li>
//                       <Link className="btn btn-import" to="#">
//                         <span>
//                           <FeatherIcon icon="check-square" className="me-2" />
//                           Import Customer
//                         </span>
//                       </Link>
//                     </li>
//                     <li>
//                       <Link className="btn btn-primary" 
//                       to="/add-customer"
//                       // to="/abc"
//                       >
//                         <i className="fa fa-plus-circle me-2" aria-hidden="true" />
//                         Add Customer form
//                       </Link>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-sm-12">
//                 <div className="card customers">
//                   <div className="card-body">
//                     <div className="table-responsive table-hover">
//                       <Table
//                         className="table"
//                         pagination={{
//                           total: customerData.length,
//                           showTotal: (total, range) =>
//                             `Showing ${range[0]} to ${range[1]} of ${total} entries`,
//                           onShowSizeChange: onShowSizeChange,
//                           itemRender: itemRender,
//                         }}
//                         columns={columns}
//                         dataSource={customerData}
//                         rowKey={(record) => record.id}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* <AddVendor setShow={setShow} show={show} /> */}

//         <div
//           className="modal custom-modal fade"
//           id="delete_modal"
//           role="dialog"
//         >
//           <div className="modal-dialog modal-dialog-centered modal-md">
//             <div className="modal-content">
//               <div className="modal-body">
//                 <div className="form-header">
//                   <h3>Delete Customer</h3>
//                   <p>Are you sure want to delete?</p>
//                 </div>
//                 <div className="modal-btn delete-action">
//                   <div className="row">
//                     <div className="col-6">
//                       <button
//                         type="reset"
//                         data-bs-dismiss="modal"
//                         className="w-100 btn btn-primary paid-continue-btn"
//                       >
//                         Delete
//                       </button>
//                     </div>
//                     <div className="col-6">
//                       <button
//                         type="submit"
//                         data-bs-dismiss="modal"
//                         className="w-100 btn btn-primary paid-cancel-btn"
//                       >
//                         Cancel
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Customers;