import React, { useState, useEffect } from 'react';
import Sidebar from '../layouts/Sidebar';
import Header from '../layouts/Header';
import axios from 'axios';
import {
    CustomDropDownField, CustomInputField
} from "../CustomComponents/ReusableComponents";
import { useAuth } from '../contexts/AuthContext';
import { useHistory, useParams } from "react-router-dom";

const AddUserComponent = () => {
    const { auth } = useAuth();
    const history = useHistory();
    const { id } = useParams();

    const [menu, setMenu] = useState(false);
    const [roles, setRoles] = useState([]);

    const [formData, setFormData] = useState({
        role_id: '',
        is_active: '1',
        name: '',
        email: ''
    })
    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const handleCancel = () => {
        history.push('/users');
    };

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                    {
                        models: [
                            { model_name: "Role" },
                        ]
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`
                        }
                    }
                );
                setRoles(response.data.content.Role	 || []);
            } catch (err) {
                // setError("Failed to load dropdown data");
            }
        };

        const fetchUserData = async () => {
            if (id) {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_V1_BASE_URL}/users/${id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${auth}`
                            }
                        }
                    );
                    setFormData(response.data);
                } catch (err) {
                    // Handle error
                }
            }
        };

        fetchDropdownData();
        fetchUserData();
    }, [auth, id]);
    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const response = id
                ? await axios.put(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/users/${id}`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                            "Content-Type": "application/json"
                        }
                    }
                )
                : await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/users`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                            "Content-Type": "application/json"
                        }
                    }
                );

            console.log("User saved successfully", response.data);
            history.push('/users');
        } catch (err) {
            console.error("Failed to save User", err);
            // Handle error
        }
    };

    return(
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <Header onMenuClick={toggleMobileMenu} />
                <Sidebar />
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="card mb-0">
                            <div className="card-body">
                                <div className="page-header">
                                    <div className="content-page-header">
                                    <h5>{id ? 'Edit User' : 'Add User'}</h5>
                                    </div>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <CustomDropDownField
                                                options={roles.map(c => ({
                                                    value: c.id,
                                                    label: c.name
                                                }))}
                                                labelText="Role Type"
                                                name="role_id"
                                                value={formData.role_id}
                                                onChange={handleChange}
                                            />
                                    </div>

                                    <div className="row">
                                        <CustomInputField
                                            inputType="text"
                                            labelText="User Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                        <CustomInputField
                                            inputType="text"
                                            labelText="Email "
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="text-end">
                                        <button
                                            type="reset"
                                            className="btn btn-primary cancel me-2"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Save
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddUserComponent;