import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

const ImportPopup = ({ id, listAPI }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null); // State to track the selected file

    const { auth } = useAuth();


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file); // Update the state with the selected file
        console.log(file); // You can replace this with your import logic
    };

    const handleDownloadSample = async () => {
        setLoading(true);
        setError(null);

        try {
            const sampleFileUrl = `${process.env.REACT_APP_API_BASE_URL}/download-sample/${listAPI}.csv`;
            console.log('Sample File URL:', sampleFileUrl);

            const response = await axios.get(sampleFileUrl, {
                responseType: 'blob',
            });

            const fileBlob = new Blob([response.data], { type: 'text/csv' });
            const url = window.URL.createObjectURL(fileBlob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${listAPI}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            window.URL.revokeObjectURL(url);

        } catch (err) {
            console.error('Failed to download sample file', err);
            setError('Failed to download sample file.');
        } finally {
            setLoading(false);
        }
    };

    // const handleUploadFile = () => {
    //     // Handle the file upload logic here
    //     console.log('Uploading file:', selectedFile); // Implement your upload logic
    // };
    const handleUploadFile = async () => {
        if (!selectedFile) {
            setError('No file selected.');
            return;
        }

        setLoading(true);
        setError(null);

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            await axios.post(
                `${process.env.REACT_APP_API_V1_BASE_URL}/${listAPI}/import`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${auth}`,
                    },
                }
            );

            setSelectedFile(null);
            alert('File uploaded successfully.');
        } catch (err) {
            console.error('Failed to upload file', err);
            setError('Failed to upload file.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby={`${id}Label`} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={`${id}Label`}>Import Popup</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="mb-3">
                                <label htmlFor="fileInput" className="form-label">Import File</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="fileInput"
                                    onChange={handleFileChange}
                                />
                            </div>
                            <button 
                                type="button" 
                                className="btn btn-link" 
                                onClick={handleDownloadSample}
                                disabled={loading}
                            >
                                {loading ? 'Downloading...' : 'Download Sample File'}
                            </button>
                            {error && <div className="text-danger mt-2">{error}</div>}
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn mx-2 btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button 
                            type="button" 
                            className="btn btn-primary" 
                            onClick={handleUploadFile} 
                            disabled={!selectedFile} // Disable button if no file is selected
                        >
                            Upload File
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

ImportPopup.propTypes = {
    id: PropTypes.string.isRequired,
    listAPI: PropTypes.string.isRequired,
};

export default ImportPopup;