import React, { useState } from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import ReportModal from "../../common/modal/reportModal";
import Breadcrumbs from "../../common/breadcrumb/breadCrumbs";
import { DatePicker } from "antd";
import Select from "react-select";
import axios from "axios";
import { useAuth } from '../../contexts/AuthContext';


const BalanceSheet = () => {
  const { auth: authToken } = useAuth();
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedperiodOption, setSelectedperiodOption] = useState(null);

  const periodoptions = [
    { value: 'current_fin_year', label: 'Current Financial Year' },
    { value: 'last_fin_year', label: 'Last Financial Year' },
    { value: 'last_3_months', label: 'Last 3 Months' },
    { value: 'last_6_months', label: 'Last 6 Months' },
    { value: 'custom', label: 'Custom' }, // Custom option for selecting a custom date range
  ];

  const [balanceSheetData, setBalanceSheetData] = useState({
    assets: {
      accounts: [],
      total: 0,
    },
    liabilities: {
      accounts: [],
      total: 0,
    },
    equity: {
      accounts: [],
      total: 0,
    },
    income: {
      accounts: [],
      total: 0,
    },
    expenses: {
      accounts: [],
      total: 0,
    },
  });

  const [totals, setTotals] = useState({
    total_assets: 0,
    total_liabilities_equity: 0,
    total_income: 0,
    total_expenses: 0,
    balance: 0,
  });

  const [isLoading, setIsLoading] = useState(false);

  // API Call for fetching balance sheet
  const getBalanceSheet = async () => {
    setIsLoading(true); // Start loading
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_V1_BASE_URL}/getbalancesheet`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        params: {
          start_date: selectedStartDate,
          end_date: selectedEndDate,
          period: selectedperiodOption.value,
        },
      });
      setBalanceSheetData(response.data.balance_sheet); // Set the balance sheet data
      setTotals({
        total_assets: response.data.balance_sheet.total_assets,
        total_liabilities_equity: response.data.balance_sheet.total_liabilities_equity,
        total_income: response.data.balance_sheet.total_income,
        total_expenses: response.data.balance_sheet.total_expenses,
        balance: response.data.balance_sheet.balance,
      });
    } catch (error) {
      console.error("Error fetching balance sheet data:", error);
      alert("Failed to fetch balance sheet data. Please try again.");
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const handleStartDateChange = (date, dateString) => {
    setSelectedStartDate(dateString);
  };

  const handleEndDateChange = (date, dateString) => {
    setSelectedEndDate(dateString);
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const handlePeriodChange = (selectedperiodOption) => {
    setSelectedperiodOption(selectedperiodOption);
    if (selectedperiodOption === 'custom') {
      // Show custom date picker or any custom logic
    }
  };

  const handleRunButtonClick = () => {
    getBalanceSheet(); // Fetch data when "Run" button is clicked
  };

  // Helper function to render accounts recursively
  const renderAccounts = (accounts, depth = 0) => {
    return accounts.map((account, index) => (
      <React.Fragment key={`${depth}-${index}`}>
        <tr>
          <td style={{ paddingLeft: `${20 + depth * 20}px`, fontWeight: depth === 0 ? "bold" : "normal" }}>
            {account.name}
          </td>
          <td>{account.code || ""}</td>
          <td>{account.total || 0}</td>
        </tr>
        {account.accounts && account.accounts.length > 0 && renderAccounts(account.accounts, depth + 1)}
      </React.Fragment>
    ));
  };

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <Breadcrumbs title="Balance Sheet" show={show} setShow={setShow} />
            <ReportModal setShow={setShow} show={show} />
            <div className="profit-menu">
              <div className="row">
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <label>Period</label>
                    <ul className="form-group">
                      <li>
                        <Select
                          value={selectedperiodOption}
                          options={periodoptions}
                          onChange={handlePeriodChange}
                          placeholder="Current Fin Year"
                        />
                      </li>
                    </ul>
                  </div>
                </div>

                {selectedperiodOption && selectedperiodOption.value === 'custom' && (
                  <>
                    <div className="col-lg-2 col-md-6 col-sm-12">
                      <div className="input-block mb-3">
                        <label>From</label>
                        <DatePicker onChange={handleStartDateChange} />
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12">
                      <div className="input-block mb-3">
                        <label>To</label>
                        <DatePicker onChange={handleEndDateChange} />
                      </div>
                    </div>
                  </>
                )}

                <div className="col-lg-2 col-md-6 col-sm-12">
                  <button
                    className="btn btn-primary loss"
                    onClick={handleRunButtonClick}
                    disabled={isLoading}
                  >
                    {isLoading ? "Running..." : "Run"}
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="card-table">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-center">
                        <thead className="thead-light loss">
                          <tr>
                            <th>Account</th>
                            <th>Code</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* <tr>
                            <td colSpan={3} style={{ backgroundColor: "#f5f5f5" }}>
                              <strong>Assets</strong>
                            </td>
                          </tr> */}
                          {balanceSheetData.assets.accounts.length > 0 ? (
                            renderAccounts(balanceSheetData.assets.accounts)
                          ) : (
                            <tr>
                              <td colSpan={3}>No asset data available</td>
                            </tr>
                          )}

                          {/* <tr>
                            <td colSpan={3} style={{ backgroundColor: "#f5f5f5" }}>
                              <strong>Liabilities</strong>
                            </td>
                          </tr> */}
                          {balanceSheetData.liabilities.accounts.length > 0 ? (
                            renderAccounts(balanceSheetData.liabilities.accounts)
                          ) : (
                            <tr>
                              <td colSpan={3}>No liability data available</td>
                            </tr>
                          )}

                          {/* <tr>
                            <td colSpan={3} style={{ backgroundColor: "#f5f5f5" }}>
                              <strong>Equity</strong>
                            </td>
                          </tr> */}
                          {balanceSheetData.equity.accounts.length > 0 ? (
                            renderAccounts(balanceSheetData.equity.accounts)
                          ) : (
                            <tr>
                              <td colSpan={3}>No equity data available</td>
                            </tr>
                          )}

                          {/* <tr>
                            <td colSpan={3} style={{ backgroundColor: "#f5f5f5" }}>
                              <strong>Income</strong>
                            </td>
                          </tr> */}
                          {balanceSheetData.income.accounts.length > 0 ? (
                            renderAccounts(balanceSheetData.income.accounts)
                          ) : (
                            <tr>
                              <td colSpan={3}>No income data available</td>
                            </tr>
                          )}

                          {/* <tr>
                            <td colSpan={3} style={{ backgroundColor: "#f5f5f5" }}>
                              <strong>Expenses</strong>
                            </td>
                          </tr> */}
                          {balanceSheetData.expenses.accounts.length > 0 ? (
                            renderAccounts(balanceSheetData.expenses.accounts)
                          ) : (
                            <tr>
                              <td colSpan={3}>No expense data available</td>
                            </tr>
                          )}
                           <tr className="profitloss-bg">
                              <td colSpan={2}><h6>Total Assets:</h6></td>
                              <td><h6>{totals.total_assets}</h6></td>
                            </tr>
                            <tr className="profitloss-bg">
                              <td colSpan={2}><h6>Total Liabilities & Equity:</h6></td>
                              <td><h6>{totals.total_liabilities_equity}</h6></td>
                            </tr>
                            <tr className="profitloss-bg">
                              <td colSpan={2}><h6>Total Income:</h6></td>
                              <td><h6>{totals.total_income}</h6></td>
                            </tr>
                            <tr className="profitloss-bg">
                              <td colSpan={2}><h6>Total Expenses:</h6></td>
                              <td><h6>{totals.total_expenses}</h6></td>
                            </tr>
                            <tr className="profitloss-bg">
                              <td colSpan={2}><h6>Balance:</h6></td>
                              <td><h6>{totals.balance}</h6></td>
                            </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BalanceSheet;
