import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Header from '../layouts/Header';
import Sidebar from '../layouts/Sidebar';
import { CustomTextArea, CustomDropDownField, CustomInputField } from '../CustomComponents/ReusableComponents';

const AddJournals = () => {
    const { id } = useParams();
    const history = useHistory();
    const { auth } = useAuth();
    const [menu, setMenu] = useState(false);
    const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;

    const getCurrentDateTime = () => {
        const currentDate = new Date();
        return currentDate.toISOString().slice(0, 16);
    };

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const [formData, setFormData] = useState({
        journal_type_id: '',
        transaction_id: '',
        date: getCurrentDateTime(),
        particular: '',
        reference: '',
        account_id: '',
        amount: '',
        is_debit: true,
    });

    const [journalTypes, setJournalTypes] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [accounts, setAccounts] = useState([]);
    // const [loading, setLoading] = useState(false);
    // const [error, setError] = useState(null);

    // Fetch data for dropdowns and journal entry if in edit mode
    useEffect(() => {
        const fetchData = async () => {
            // setLoading(true);
            try {
                // Fetch dropdown data
                const dropdownResponse = await axios.post(
                    `${API_BASE_URL}/get_dropdown_data`,
                    {
                        models: [
                            { model_name: 'JournalType' },
                            { model_name: 'Transaction' },
                            { model_name: 'Account' },
                        ],
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                        },
                    }
                );
                setJournalTypes(dropdownResponse.data.content.JournalType || []);
                setTransactions(dropdownResponse.data.content.Transaction || []);
                setAccounts(dropdownResponse.data.content.Account || []);

                // If editing, fetch the journal entry data
                if (id) {
                    const journalResponse = await axios.get(`${API_BASE_URL}/journals/${id}`, {
                        headers: {
                            Authorization: `Bearer ${auth}`,
                        },
                    });
                    setFormData(journalResponse.data);
                }
            } catch (err) {
                // setError('Failed to load data');
            } finally {
                // setLoading(false);
            }
        };

        fetchData();
    }, [id, auth, API_BASE_URL]);

    const handleToggle = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            is_debit: prevFormData.is_debit === '1' ? '0' : '1'
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // const handleSwitchChange = (e) => {
    //     setFormData({
    //         ...formData,
    //         is_debit: e.target.checked,
    //     });
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // setLoading(true);
        try {
            if (id) {
                // Edit mode: update the journal entry
                await axios.put(`${API_BASE_URL}/journals/${id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${auth}`,
                    },
                });
            } else {
                await axios.post(`${API_BASE_URL}/journals`, formData, {
                    headers: {
                        Authorization: `Bearer ${auth}`,
                    },
                });
            }
            history.push('/journals');
        } catch (err) {
            // setError('Failed to save journal entry');
        } finally {
            // setLoading(false);
        }
    };

    if (!formData) {
        return <CircularProgress />;
    }

    const handleCancel = () => {
        history.push('/journals');
    };

    return (
        <>
        <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
            <Header onMenuClick={toggleMobileMenu} />
            <Sidebar />
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="card mb-0">
                            <div className="card-body">
                                <div className="page-header">
                                    <div className="content-page-header">
                                    <h5>{id ? 'Edit Journals' : 'Add Journals'}</h5>
                                    </div>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <CustomDropDownField
                                            options={journalTypes.map(c => ({
                                                value: c.id,
                                                label: c.name
                                            }))}
                                            labelText="Journal Type"
                                            name="journal_type_id"
                                            value={formData.journal_type_id}
                                            onChange={handleChange}
                                        />

                                        <CustomDropDownField
                                            options={transactions.map(c => ({
                                                value: c.id,
                                                label: c.name
                                            }))}
                                            labelText="Transaction"
                                            name="transaction_id"
                                            value={formData.transaction_id}
                                            onChange={handleChange}
                                        />

                                    </div>
                                    {/* Date Picker */}
                                    {/* <div className="row">
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="input-block mb-3">
                                              <label>Purchases Date</label>
                                              <DatePicker
                                                className="datetimepicker form-control"
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                              ></DatePicker>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="input-block mb-3">
                                              <label>Purchases Date</label>
                                              <DatePicker
                                                className="datetimepicker form-control"
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                              ></DatePicker>
                                            </div>
                                        </div>
                                    </div> */}
                                                
                                    <div className="col-lg-6 my-3">
                                        <CustomTextArea
                                            rows={5}
                                            name="particular"
                                            value={formData.particular}
                                            onChange={handleChange}
                                            placeholder="Particular"
                                        />
                                    </div>

                                    <div className="col-lg-6 my-3">
                                        <CustomInputField
                                            inputType="text"
                                            labelText="Reference"
                                            name="reference"
                                            value={formData.reference}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="row">
                                        <CustomDropDownField
                                            options={accounts.map(c => ({
                                                value: c.id,
                                                label: c.name
                                            }))}
                                            labelText="Account"
                                            name="account_id"
                                            value={formData.account_id}
                                            onChange={handleChange}
                                        />

                                        <CustomInputField
                                            inputType="number"
                                            labelText="Amount"
                                            name="amount"
                                            value={formData.amount}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="isActiveSwitch"
                                                checked={formData.is_debit === '1'}
                                                onChange={handleToggle}
                                            />
                                            <label className="form-check-label" htmlFor="isActiveSwitch">
                                                {formData.is_debit === '1' ? 'Debit' : 'Credit'}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="text-end">
                                        <button
                                            type="reset"
                                            className="btn btn-primary cancel me-2"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Save
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        </>
    );
};

export default AddJournals;

// {
//     "journal_type_id": 2,
//     "transaction_id": 5,
//     "date": "2024-09-26T09:55",
//     "particular": "b",
//     "reference": "Ref",
//     "account_id": 4,
//     "amount": "1234",
//     "is_debit": true,
//     "branch_id": 1,
//     "updated_at": "2024-09-26T09:56:36.000000Z",
//     "created_at": "2024-09-26T09:56:36.000000Z",
//     "id": 55
// }