/* eslint-disable */ 
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom"; // Ensure you import useParams for route params
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import Select from 'react-select';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";

const EditCustomer = () => {
  const { id } = useParams(); // Get the customer ID from the URL parameters
  const auth = "your_auth_token"; // Replace this with your actual auth token logic
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [formData, setFormData] = useState({});
  const [accountTypes, setAccountTypes] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
          {
            models: [
              { model_name: "Customer" },
              { model_name: "AccountType" },
              { model_name: "Vendor" },
            ]
          },
          {
            headers: {
              Authorization: `Bearer ${auth}`
            }
          }
        );
        setAccountTypes(response.data.content.AccountType || []);
        setCustomers(response.data.content.Customer || []);
        setVendors(response.data.content.Vendor || []);
      } catch (err) {
        // Handle error
        console.error("Failed to load dropdown data", err);
      }
    };

    const fetchAccountData = async () => {
      if (id) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_V1_BASE_URL}/customer/${id}`,
            {
              headers: {
                Authorization: `Bearer ${auth}`
              }
            }
          );
          setFormData(response.data);
          setPhoneNumber(response.data.phone || ""); // Set phone number from fetched data
          setSelectedCurrency({ value: response.data.currency, label: response.data.currency }); // Set currency if applicable
        } catch (err) {
          // Handle error
          console.error("Failed to load customer data", err);
        }
      }
    };

    fetchDropdownData();
    fetchAccountData();
  }, [auth, id]);

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
  };

  const handleCurrencyChange = (selectedOption) => {
    setSelectedCurrency(selectedOption);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log({
      phoneNumber,
      selectedCurrency,
      formData, // Include other form data here as needed
    });
  };

  const currencyOptions = accountTypes.map(accountType => ({
    value: accountType.id,
    label: accountType.text,
  }));

  return (
    <>
      <div className={`main-wrapper`}>
        <Header />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="card mb-0">
              <div className="card-body">
                <div className="page-header">
                  <h5>Edit Customer details</h5>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <form onSubmit={handleSubmit}>
                      <div className="">
                        <div className="form-group-item">
                          <h5 className="form-title">Basic Details</h5>
                          <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <label>Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={formData.name || ""}
                                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                  placeholder="Enter Name"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <label>Email</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  value={formData.email || ""}
                                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                  placeholder="Enter Email Address"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <label>Phone</label>
                                <PhoneInput
                                  country={"in"}
                                  value={phoneNumber}
                                  onChange={handlePhoneChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <label>Currency</label>
                                <Select
                                  value={selectedCurrency}
                                  onChange={handleCurrencyChange}
                                  options={currencyOptions}
                                  placeholder="Select Currency"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <label>Website</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={formData.website || ""}
                                  onChange={(e) => setFormData({ ...formData, website: e.target.value })}
                                  placeholder="Enter Website Address"
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <label>Notes</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={formData.notes || ""}
                                  onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
                                  placeholder="Enter Your Notes"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Billing and Shipping Address */}
                        <div className="form-group-item">
                          {/* Add billing and shipping address fields as necessary */}
                        </div>
                        <div className="add-customer-btns text-end">
                          <Link to="/customers" className="btn customer-btn-cancel">
                            Cancel
                          </Link>
                          <button type="submit" className="btn customer-btn-save">
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCustomer;
