import React, { useState } from "react";
import SettingSidebar from "../layouts/SettingsSidebar";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import { useAuth } from "../contexts/AuthContext";
import axios from "axios";

const ChangePassword = () => {
    const { auth } = useAuth();
    const [menu, setMenu] = useState(false);
    const [formData, setFormData] = useState({
        current_password: "",
        new_password: "",
        new_password_confirmation: ""
    });

    const [errors, setErrors] = useState({
        passwordMismatch: false,
        apiError: ""
    });

    const [passwordValidation, setPasswordValidation] = useState({
        charLength: false,
        lowercase: false,
        uppercase: false,
        special: false
    });

    const [showPassword, setShowPassword] = useState({
        current: false,
        new: false,
        confirm: false,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));

        if (name === "new_password") {
            validatePassword(value);
        }
    };

    const validatePassword = (password) => {
        const validations = {
            charLength: password.length >= 8,
            lowercase: /[a-z]/.test(password),
            uppercase: /[A-Z]/.test(password),
            special: /[0-9_\W]/.test(password)
        };

        setPasswordValidation(validations);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({ passwordMismatch: false, apiError: "" });

        if (formData.new_password !== formData.new_password_confirmation) {
            setErrors({ ...errors, passwordMismatch: true });
            return;
        }

        try {
            const customerData = {
                current_password: formData.current_password,
                new_password: formData.new_password,
                new_password_confirmation: formData.new_password_confirmation
            };

            await axios.post(
                `${process.env.REACT_APP_API_V1_BASE_URL}/change-password`,
                customerData,
                {
                    headers: {
                        Authorization: `Bearer ${auth}`,
                        "Content-Type": "application/json"
                    }
                }
            );

            alert("Password changed successfully!");
        } catch (error) {
            setErrors({
                ...errors,
                apiError: "Failed to change password. Please try again."
            });
            console.error("Error changing password", error);
        }
    };

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const toggleShowPassword = (field) => {
        setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
    };

    return (
        <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
            <Header onMenuClick={toggleMobileMenu} />
            <Sidebar />
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="page-header">
                        <div className="content-page-header">
                            <h5>Settings</h5>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-3 col-md-4">
                            <SettingSidebar />
                        </div>

                        <div className="col-xl-9 col-md-8">
                            <div className="card">
                                <div className="card-header content-page-header mb-0">
                                    <h5 className="">Change Password</h5>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={handleSubmit} className="row align-items-center">
                                        <div className="col-lg-3 mb-2">
                                            <label>Current Password </label>
                                        </div>

                                        <div className="col-lg-9">
                                            <div className="d-flex password-field">

                                            <input
                                                type={showPassword.current ? "text" : "password"}
                                                placeholder="Enter Current Password"
                                                name="current_password"
                                                value={formData.current_password}
                                                onChange={handleChange}
                                                className="w-100 border-0 p-1  rounded"
                                            />
                                          <button type="button" className="btn btn-primary cancel px-2 visible-btn" onClick={() => toggleShowPassword('current')}>
    {showPassword.current ? <i className="fe fe-eye-off"></i> : <i className="fe fe-eye"></i>}
</button>

                                            </div>
                                        </div>

                                        <div className="col-lg-3 mb-2">
                                            <label>New Password </label>
                                        </div>

                                        <div className="col-lg-9">
                                        <div className="d-flex password-field">
                                            <input
                                                type={showPassword.new ? "text" : "password"}
                                                placeholder="Enter new Password"
                                                name="new_password"
                                                value={formData.new_password}
                                                onChange={handleChange}
                                                className="w-100 border-0 p-1  rounded"
                                            />

<button type="button" className="btn btn-primary cancel px-2 visible-btn" onClick={() => toggleShowPassword('new')}>
    {showPassword.current ? <i className="fe fe-eye-off"></i> : <i className="fe fe-eye"></i>}
</button>
                                        </div>
                                            {/* <button type="button" onClick={() => toggleShowPassword('new')}>
                                                {showPassword.new ? "Hide" : "Show"}
                                            </button> */}

                                          
                                        </div>

                                        <div className="col-lg-3 mb-2">
                                            <label>Confirm New Password </label>
                                        </div>

                                        <div className="col-lg-9">
                                        <div className="d-flex password-field">
                                            <input
                                                type={showPassword.confirm ? "text" : "password"}
                                                placeholder="Confirm new Password"
                                                name="new_password_confirmation"
                                                value={formData.new_password_confirmation}
                                                onChange={handleChange}
                                                className="w-100 border-0 p-1  rounded"
                                            />
                                                                               <button type="button"  className="btn btn-primary cancel px-2 visible-btn"  onClick={() => toggleShowPassword('confirm')}>
    {showPassword.current ? <i className="fe fe-eye-off"></i> : <i className="fe fe-eye"></i>}
</button>
                                        </div>
                                           

         
                                        </div>

                                        {errors.passwordMismatch && (
                                            <p style={{ color: "red" }}>
                                                New password and confirmation do not match.
                                            </p>
                                        )}

                                        {errors.apiError && (
                                            <p style={{ color: "red" }}>
                                                {errors.apiError}
                                            </p>
                                        )}
                                        <div className="col-lg-3"></div>
                                        <div className="col-lg-9 mt-1">
                                            <h5>Password requirements:</h5>
                                            <p className="my-1 mb-2">
                                                Ensure that these requirements are met:
                                            </p>
                                            <ul className="list-unstyled medium ms-3 pwd-instructions">
                                                <li className={`char-pwd ${passwordValidation.charLength ? 'valid' : ''}`}>
                                                    Minimum 8 characters long - the more, the better
                                                </li>
                                                <li className={`lowerCase-pwd ${passwordValidation.lowercase ? 'valid' : ''}`}>
                                                    At least one lowercase character
                                                </li>
                                                <li className={`upperCase-pwd ${passwordValidation.uppercase ? 'valid' : ''}`}>
                                                    At least one uppercase character
                                                </li>
                                                <li className={`symbol-pwd ${passwordValidation.special ? 'valid' : ''}`}>
                                                    At least one number, symbol
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                // disabled={!Object.values(passwordValidation).every(Boolean)}
                                            >
                                                Save Changes
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;
