import React, { useState } from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import ReportModal from "../../common/modal/reportModal";
import Breadcrumbs from "../../common/breadcrumb/breadCrumbs";
import { DatePicker } from "antd";
import Select from "react-select";
import axios from "axios";
import { useAuth } from '../../contexts/AuthContext';


const TrialBalance = () => {
  const { auth: authToken } = useAuth();
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedstartDate, setSelecteStartDate] = useState(null);
  const [selectedendDate, setSelectEdendDate] = useState(null);
  const [trialBalanceData, setTrialBalanceData] = useState([]);
  const [totalDebitData, setTotalDebitData] = useState([]);
  const [totalCreditsData, setTotalCreditsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedperiodOption, setSelectedperiodOption] = useState(null);

  const periodoptions = [
    { value: 'current_fin_year', label: 'Current Financial Year' },
    { value: 'last_fin_year', label: 'Last Financial Year' },
    { value: 'last_3_months', label: 'Last 3 Months' },
    { value: 'last_6_months', label: 'Last 6 Months' },
    { value: 'custom', label: 'Custom' }, // Custom option for selecting a custom date range
  ];


  // API Call for fetching trial balance
  const getTrialBalance = async () => {
    setIsLoading(true); // Start loading
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_V1_BASE_URL}/gettrialbalance`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        params: {
            start_date: selectedstartDate, // Use selectedDate here
            end_date: selectedendDate, // Use selectedDate here
          },
      });
      setTrialBalanceData(response.data.trial_balance); // Set the API response in state
      setTotalDebitData(response.data.totals.total_debits); // Set the API response in state
      setTotalCreditsData(response.data.totals.total_credits); // Set the API response in state
    } catch (error) {
      console.error("Error fetching trial balance data:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const handleStartDateChange = (date, dateString) => {
    setSelecteStartDate(dateString);
  };
  const handleEndDateChange = (date, dateString) => {
    setSelectEdendDate(dateString);
  };
  const handlePeriodChange = (selectedperiodOption) => {
    setSelectedperiodOption(selectedperiodOption);
    if (selectedperiodOption === 'custom') {
      // Show custom date picker or any custom logic
    }
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };


  const handleRunButtonClick = () => {
    getTrialBalance(); // Fetch data when "Run" button is clicked
  };

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <Breadcrumbs title="Trial Balance" show={show} setShow={setShow} />
            <ReportModal setShow={setShow} show={show} />
            <div className="profit-menu">
              <div className="row">
                {/* Date and filter options */}
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <label>Period</label>
                    <ul className="form-group">
                      <li>
                      <Select
                          value={selectedperiodOption}
                          options={periodoptions}
                          onChange={handlePeriodChange}
                          placeholder="Current Fin Year"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
                {selectedperiodOption && selectedperiodOption.value === 'custom' && (
                  <>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <label>From</label>
                    <div>
                      <DatePicker onChange={handleStartDateChange} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <label>To</label>
                    <div>
                      <DatePicker onChange={handleEndDateChange} />
                    </div>
                  </div>
                </div>
                </>
                )}

                <div className="col-lg-2 col-md-6 col-sm-12">
                  <button
                    className="btn btn-primary loss"
                    onClick={handleRunButtonClick}
                  >
                    {isLoading ? "Running..." : "Run"}
                  </button>
                </div>
              </div>
            </div>

            {/* Table to display trial balance data */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card-table">
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="table-profit-loss">
                        <table className="table table-center">
                          <thead className="thead-light loss">
                            <tr>
                              <th>Account</th>
                              <th>Debit</th>
                              <th>Credit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* Map through trialBalanceData */}
                            {trialBalanceData.map((section, index) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td colSpan={3}><strong>{section.name}</strong></td>
                                </tr>
                                
                                {/* Main Accounts */}
                                {section.accounts.length > 0 ? (
                                  section.accounts.map((account) => (
                                    <tr key={account.code}>
                                      <td>{account.name}</td>
                                      <td>{account.debit}</td>
                                      <td>{account.credit}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={3}>No accounts available</td>
                                  </tr>
                                )}

                                {/* Children (Sub-accounts) */}
                                {section.children.length > 0 &&
                                  section.children.map((child) => (
                                    <React.Fragment key={child.name}>
                                      <tr>
                                        <td colSpan={3}>
                                          <strong>{child.name}</strong>
                                        </td>
                                      </tr>
                                      {child.accounts.length > 0 ? (
                                        child.accounts.map((account) => (
                                          <tr key={account.code}>
                                            <td>{account.name}</td>
                                            <td>{account.debit}</td>
                                            <td>{account.credit}</td>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <td colSpan={3}>No accounts available for {child.name}</td>
                                        </tr>
                                      )}
                                    </React.Fragment>
                                  ))}
                              </React.Fragment>
                            ))}

                            {/* Total Debit and Credit */}
                            <tr className="profitloss-bg">
                              <td><h6>Total</h6></td>
                              <td><h6>{totalDebitData}</h6></td>
                              <td><h6>{totalCreditsData}</h6></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrialBalance;
