import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { Switch } from "react-router-dom";
import StickySidebar from "./layouts/StickySidebar";
import Dashboard from "./main/dashboard/Index";
import Customers from "./customers/CustomerMenu/Index";
// import AddCustomer from "./customers/add-customer";
import EditCustomer from "./customers/edit-customer";
import Expenses from "./finance-accounts/expenses/expenses";
import AddExpenses from "./finance-accounts/expenses/add-expenses";
import EditExpenses from "./finance-accounts/expenses/edit-expenses";
import RecurringInvoice from "./sales/invoices/recurring";
import AddPurchaseReturn from "./purchases-component/purchases/add-purchase-return";
import Calendar from "./main/application/calendar";
import DomainRequest from "./main/superadmin/domainrequest";
import Packages from "./main/superadmin/packages";
import Subscription from "./main/superadmin/subscription";
import FontAwesome from "./ui-interface/icons/font-awesome";
import Feather from "./ui-interface/icons/feather";
import IconicIcon from "./ui-interface/icons/iconic";
import MaterialIcons from "./ui-interface/icons/material";
import Pe7 from "./ui-interface/icons/pe7";
// import Invoices from "./sales/invoices/Index";
import Invoicedetails from "./sales/invoices/Invoice-details";
import ViewInvoice from "./sales/invoices/view-invoice";
import AddInvoice from "./sales/invoices/AddInvoice";
// import EditInvoice from "./sales/invoices/edit-invoice";
import InvoicePaid from "./sales/invoices/Invoice-paid";
import ParticallyPaid from "./sales/invoices/partically-paid";
import InvoiceUnpaid from "./sales/invoices/Invoice-unpaid";
import RefundedInvoice from "./sales/invoices/refunded-Invoice";
import InvoiceOverdue from "./sales/invoices/Invoice-overdue";
import Invoicesettings from "./sales/invoices/Invoice-settings";
import InvoiceDraft from "./sales/invoices/Invoice-draft";
import Invoicecancelled from "./sales/invoices/Invoice-cancelled";
import Chat from "./main/application/chat";
import Alert from "./ui-interface/base-ui/alerts";
import Accordions from "./ui-interface/base-ui/accordions";
import Avatar from "./ui-interface/base-ui/avatar";
import Badges from "./ui-interface/base-ui/badges";
import Buttons from "./ui-interface/base-ui/buttons";
import Buttongroup from "./ui-interface/base-ui/button-group";
import Breadcrumbs from "./ui-interface/base-ui/breadcrumbs";
import Cards from "./ui-interface/base-ui/cards";
import Carousel from "./ui-interface/base-ui/carousel";
import Dropdowns from "./ui-interface/base-ui/dropdowns";
import Grid from "./ui-interface/base-ui/grid";
import Images from "./ui-interface/base-ui/images";
import Lightbox from "./ui-interface/base-ui/lightbox";
import Media from "./ui-interface/base-ui/media";
import Modals from "./ui-interface/base-ui/modals";
import Offcanvas from "./ui-interface/base-ui/offcanvas";
import Pagination from "./ui-interface/base-ui/pagination";
import Popover from "./ui-interface/base-ui/popover";
import Progress from "./ui-interface/base-ui/progress";
import Placeholder from "./ui-interface/base-ui/placeholders";
import RangeSlides from "./ui-interface/base-ui/rangeslider";
import Spinners from "./ui-interface/base-ui/spinners";
import Sweetalerts from "./ui-interface/base-ui/sweeetalerts";
import Tap from "./ui-interface/base-ui/tab";
import Toasts from "./ui-interface/base-ui/toasts";
import Tooltip from "./ui-interface/base-ui/tooltip";
import Typography from "./ui-interface/base-ui/typography";
import Videos from "./ui-interface/base-ui/videos";
import Ribbon from "./ui-interface/elements/ribbon";
import DragDrop from "./ui-interface/elements/drag&drop";
import Rating from "./ui-interface/elements/rating";

import Texteditor from "./ui-interface/elements/texteditor";
import Counter from "./ui-interface/elements/counter";
import Scrollbar from "./ui-interface/elements/scrollbar";
import Stickynote from "./ui-interface/elements/stickynote";
import Timeline from "./ui-interface/elements/timeline";
import Horizontaltimeline from "./ui-interface/elements/horizontaltimeline";
import Formwizard from "./ui-interface/elements/formwizard";
import { Clipboard } from "./ui-interface/elements/clipboard";
import InvoiceOnea from "./sales/invoices/invoice-one-a";
import InvoiceFourA from "./sales/invoices/invoice-four-a";
import Companies from "./main/superadmin/companies";

import ContactMessage from "./support/contactMessage";
import customersLedger from "./customers/customersLedger";
import BankSetting from "./settings/BankSetting";
import PaymentMethod from "./settings/PaymentMethod";
import Error404 from "./pages/error404";
import DeleteAccounts from "./settings/deleteAccount";
import VectorMaps from "./pages/vectormaps/Index";
import TypiconIcons from "./ui-interface/icons/typicon";
import SimpleLine from "./ui-interface/icons/simpleLine";
import Themify from "./ui-interface/icons/themify";
import WeatherIcons from "./ui-interface/icons/weather";
import Flags from "./ui-interface/icons/flags";
import Apexchart from "./ui-interface/charts/apexcharts";
import ChartJs from "./ui-interface/charts/chartjs";
import Tickets from "./support/tickets";
import TicketRecurring from "./support/tickets/ticketRecurring";
import TicketCancelled from "./support/tickets/ticketCancelled";
import TicketList from "./support/tickets/ticketlist";
import TicketListPending from "./support/tickets/ticketlist/ticketListPending";
import TicketListOverdue from "./support/tickets/ticketlist/ticketListOverdue";
import TicketListDraft from "./support/tickets/ticketlist/ticketListdraft";
import TicketListRecurring from "./support/tickets/ticketlist/ticketListRecurring";
import TicketListCancelled from "./support/tickets/ticketlist/ticketListCancelled";
import TicketKanban from "./support/tickets/ticketKanban";
import TicketDetails from "./support/tickets/ticketOverview";
import AddMembership from "./membership/addMembership";
import EditTestimonials from "./content/testimonials/editTestimonials";
import AddTestimonials from "./content/testimonials/addTestimonials";
import Faq from "./content/faq";
import Cities from "./content/location/cities";
import States from "./content/location/states";
import Countries from "./content/location";
import BlogComments from "./content/blog/blog-comments";
import AddCategories from "./content/blog/add-categories";
import Categories from "./content/blog/categories";
import AddBlog from "./content/blog/add-blog";
import InactiveBlog from "./content/blog/inactive-blog";
import AllBlogs from "./content/blog/blog";
import Testimonials from "./content/testimonials";
import Pages from "./pages/page";
import AddPage from "./pages";
import Transaction from "./membership/transaction";
import Payments from "./finance-accounts/payments/payments";
import AccountSettings from "./settings/AccountSettings";
import CompanySettings from "./settings/CompanySettings";
import InvoiceSetting from "./settings/InvoiveSetting";
import InvoiceTemplateSettings from "./settings/InvoiceTemplateSettings";
import Inbox from "./main/application/inbox";
import RecurringHead from "./sales/invoices/recurring/recurringHead";
import CreditPending from "./sales/creditNotes/creditPending";
import CreditOverdue from "./sales/creditNotes/creditOverdue";
import CreditDraft from "./sales/creditNotes/creditDraft";
import CreditRecurring from "./sales/creditNotes/creditRecurring";
import CreditCancelled from "./sales/creditNotes/creditCancelled";
import AddCredit from "./sales/creditNotes/addCredit";
import CreditDetails from "./sales/creditNotes/creditDetails";
import Purchases from "./purchases-component/purchases/purchases";
import AddPurchases from "./purchases-component/purchases/add-purchases";
import EditPurchase from "./purchases-component/purchases/edit-purchases";
import PurchaseDetails from "./purchases-component/purchases/purchase-details";
import PurchaseOrders from "./purchases-component/purchases/purchase-orders";
import DebitNotes from "./purchases-component/purchases/debit-notes";
import Quotations from "./quotation-component/quotations";
import AddQuotations from "./quotation-component/quotations/addQuotations";
import EditQuotations from "./quotation-component/quotations/editQuotations";
import DeliveryChallans from "./quotation-component/deliveryChallans";
import EditChallans from "./quotation-component/deliveryChallans/editChallans";
import AddChallans from "./quotation-component/deliveryChallans/addChallans";
import PaymentSummary from "./reports-component/paymentSummary";
import AddUser from "./user-management/manageUser";
import RolesPermission from "./user-management/rolePermission";
import Permission from "./user-management/rolePermission/permission";
import DeleteAccount from "./user-management/deleteAccount";
import MembershipPlan from "./membership";
import MembershipAddons from "./membership/membershipAddons";
import Subscribers from "./membership/subscribers";
import BasicInputs from "./ui-interface/forms/basic-inputs";
import FormInputGroups from "./ui-interface/forms/input-groups";
import HorizontalForm from "./ui-interface/forms/horizontal-form";
import VerticalForm from "./ui-interface/forms/vertical-form";
import FormMask from "./ui-interface/forms/FormMask";
import FormValidation from "./ui-interface/forms/FormValidation";
import Fileupload from "./ui-interface/forms/File-upload";
import Formselect2 from "./ui-interface/forms/form-select2";
import BasicTables from "./ui-interface/tables/BasicTables";
import Datatables from "./ui-interface/tables/DataTables";
import Login from "./authentication/Login";
import Register from "./authentication/Register";
import ForgotPassword from "./authentication/forgot-password";
import LockScreen from "./authentication/lock-screen";
import AddProduct from "./inventory/products/addProduct";
import EditProduct from "./inventory/products/editProduct";
import ActiveCustomers from "./customers/activeCustomers";
import DeactiveCustomers from "./customers/deactivateCustomers";
import CustomerDetails from "./customers/CustomerMenu/customerDetails";
import Vendors from "./customers/vendors";
import ProductList from "./inventory/products/productList";
import Units from "./inventory/products/units";
import Inventory from "./inventory";
import InvoiceList from "./sales/invoices/invoice-list";
import InvoiceTemplate from "./sales/invoices/invoice-template";
import InvoiceDetailsAdmin from "./sales/invoices/Invoice-details-admin";
import Category from "./inventory/products/category";
import Components from "./components/Index";
import SalesReport from "./reports-component/reports/salesreport";
// import ExpenseReport from "./reports-component/reports/expense";
// import ProfitlossReport from "./reports-component/reports/profitloss";
// import Taxreport from "./reports-component/reports/taxreport";

import EmailSettings from "./settings/EmailSettings";
import ExpenseCategory from "./settings/ExpenseCategory";
import Notifications from "./settings/Notifications";
import ChangePassword from "./settings/ChangePassword";
import Profile from "./pages/profile/Index";
import Page404 from "./pages/errorpages/404";
import Page500 from "./pages/errorpages/500";
import BlankPage from "./pages/blank-page";
import CreditNotes from "./sales/creditNotes";
import Domain from "./main/superadmin/domain";
import PurchaseTransaction from "./main/superadmin/purchase-transaction";
import AddPurchaseOrder from "./purchases-component/purchases/add-purchase-order";
import EditPurchaseOrder from "./purchases-component/purchases/edit-purchase-order";
import EditPurchaseReturn from "./purchases-component/purchases/edit-purchse-return";
import SignatureList from "./signature/signature-list";
import SignatureInvoice from "./signature/signature-invoice";

import Ledger from "./customers/vendors/ledger";
import InvoiceThree from "./sales/invoices/Invoice-three";
import InvoiceTwo from "./sales/invoices/Invoice-two";
import InvoiceFive from "./sales/invoices/Invoice-five";
import PurchaseReport from "./reports-component/reports/purchaseReport";
import PurchaseReturnReport from "./reports-component/reports/purchaseReturnReport";
import SalesReturn from "./reports-component/reports/salesReturn";
import QuotationReport from "./reports-component/reports/quotationReport";
import PaymentReport from "./reports-component/reports/paymentReport";
import StockReport from "./reports-component/reports/stockReport";
import LowstockReport from "./reports-component/reports/lowstockReport";
import IncomeReport from "./reports-component/reports/incomeReport";
import TaxReport from "./reports-component/reports/taxreport";
import ExpenseReport from "./reports-component/reports/expenseReport";
import ProfitLossList from "./reports-component/reports/profitlossReport";
import CashRecepitOne from "./sales/invoices/cashrecepitone";
import CashRecepitFour from "./sales/invoices/CashRecepitFour";
import TwoFactor from "./settings/two-factor";
import CustomField from "./settings/custom-field";
import PlanBilling from "./settings/plan-billing";
import TaxRates from "./settings/tax-rates";
import SignaturePreviewInvoice from "./signature/signature-preview-invoice";
import TicketsOpen from "./support/tickets/ticketPending";
import TicketsResolved from "./support/tickets/ticketOverdue";
import TicketPending from "./support/tickets/ticketDraft";
import CashRecepitThree from "./sales/invoices/CashRecepitThree";
import CashRecepitTwo from "./sales/invoices/CashRecepitTwo";
import Preferences from "./settings/Preferences";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import EditCreditnote from "./sales/creditNotes/edit-creditnote";
import SassLogin from "./authentication/sass-login";
import SassRegister from "./authentication/sass-register";
import EmailTemplates from "./settings/email-templates";
import SeoSettings from "./settings/seosettings";
import SaasSettings from "./settings/saassettings";
import Superdashbord from "./main/superadmin/dashboard";
import PackagesList from "./main/superadmin/planlist";
import InvoiceSubscription from "./main/superadmin/invoicesubscription";
import TrialBalance from "./reports-component/reports/trialBalanceReport";
import BalanceSheetReport from "./reports-component/reports/balanceSheetReport";
import Journal from "./finance-accounts/journal";
import AccountComponent from "./accountsfinance/AccountComponent";
import AccountTypes from "./accountsfinance/AccountTypes";
import TransactionTypes from "./accountsfinance/TransactionTypes";
import TransactionComponent from "./accountsfinance/TransactionComponent";
import TransactionForm from "./settings/TransactionForm";
import AddAccountComponent from "./accountsfinance/AddAccountComponent";
import LedgerComponent from "./accountsfinance/ledger";
// import AddCustomerForm from "./customers/CustomerMenu/AddCustomerForm";
import CustomersMain from "./CustomComponents/Index";
import AddCustomerForm from "./CustomComponents/AddCustomerForm";
import PlaidLinkComponent from "./reports-component/reports/plaidLinkComponent";
import UserComponent from "./users/UserComponent";
import AddUserComponent from "./users/AddUserComponent";
import RoleComponent from "./roles/RoleComponent";
import AddRoleComponent from "./roles/AddRoleComponent";
import RegistrationVerificationForm from "./authentication/registration-verification-form";
import ItemFormFields from "./items/itemlist";
import AddItems from "./items/addItems";
import PurchaseInvoice from "./purchaseInvoice/purchaseInvoice";
import AddPurchaseInvoice from "./purchaseInvoice/addPurchaseInvoice";
import JournalList from "./journal/journalList";
import AddJournals from "./journal/addJournal";
import BranchList from "./branches/branchList";
import AddBranch from "./branches/addBranch";
import JournalTypesList from "./journal/journalTypesList";


import VendorMain from './customers/vendors/index'
// import Vendors from "./customers/vendors";
import addVendorForm from "./customers/vendors/addVendor";
import BankAccounts from "./accountsfinance/BankAccount";
import AddBankAccount from "./accountsfinance/AddBankAccount";
import UserProfile from "./settings/UserProfile";
import ProtectedRoute from "./authentication/ProtectedRoute";
import ShowInvoice from "./sales/invoices/show-invoice";
import PlaidDashboard from "./reports-component/reports/PlaidDashboard";



const AppContainer = () => {
  // const config = "/template/react";
  const [showStickySidebar, setShowStickySidebar] = useState(true);
  const location = useLocation();

  useEffect(() => {
    // Hide the sidebar on the "/invoices" page
    setShowStickySidebar(
      location.pathname !== "/invoice-one-a" &&
      location.pathname !== "/invoice-two" &&
      location.pathname !== "/invoice-three" &&
      location.pathname !== "/invoice-four-a" &&
      location.pathname !== "/invoice-five" &&
      location.pathname !== "/cashreceipt-1" &&
      location.pathname !== "/cashreceipt-2" &&
      location.pathname !== "/cashreceipt-3" &&
      location.pathname !== "/cashreceipt-4"
    );
  }, [location.pathname]);

  return (
    <Router basename="">
      {showStickySidebar && <StickySidebar />}
      <Switch>
        <Route path="/index" render={(props) => <ProtectedRoute {...props} component={Dashboard} />} />
        <Route path="/items" render={(props) => <ProtectedRoute {...props} component={ItemFormFields} />} />
        <Route path="/add-items" render={(props) => <ProtectedRoute {...props} component={AddItems} />} />
        <Route path="/edit-items/:id" render={(props) => <ProtectedRoute {...props} component={AddItems} />} />

        <Route path="/bills" render={(props) => <ProtectedRoute {...props} component={PurchaseInvoice} />} />
        <Route path="/add-bills" render={(props) => <ProtectedRoute {...props} component={AddPurchaseInvoice} />} />
        <Route path="/edit-bills/:id" render={(props) => <ProtectedRoute {...props} component={AddPurchaseInvoice} />} />

        <Route path="/journals" render={(props) => <ProtectedRoute {...props} component={JournalList} />} />
        <Route path="/add-journals" render={(props) => <ProtectedRoute {...props} component={AddJournals} />} />
        <Route path="/edit-journals/:id" render={(props) => <ProtectedRoute {...props} component={AddJournals} />} />

        <Route path="/journal_types" render={(props) => <ProtectedRoute {...props} component={JournalTypesList} />} />

        <Route path="/branches" render={(props) => <ProtectedRoute {...props} component={BranchList} />} />
        <Route path="/add-branches" render={(props) => <ProtectedRoute {...props} component={AddBranch} />} />
        <Route path="/edit-branches/:id" render={(props) => <ProtectedRoute {...props} component={AddBranch} />} />
        <Route path="/customers" render={(props) => <ProtectedRoute {...props} component={Customers} />} />
        <Route path="/add-customers" render={(props) => <ProtectedRoute {...props} component={AddCustomerForm} />} />
        {/* <Route path="/add-customer" render={(props) => <ProtectedRoute {...props} component={AddCustomerForm} /> */}
        <Route path="/edit-customers/:id?" render={(props) => <ProtectedRoute {...props} component={AddCustomerForm} />} />


        <Route path="/edit-customer" render={(props) => <ProtectedRoute {...props} component={EditCustomer} />} />
        <Route path="/expenses" render={(props) => <ProtectedRoute {...props} component={Expenses} />} />
        <Route path="/add-expenses" render={(props) => <ProtectedRoute {...props} component={AddExpenses} />} />
        <Route path="/edit-expenses" render={(props) => <ProtectedRoute {...props} component={EditExpenses} />} />
        {/* <Route path="/invoices" render={(props) => <ProtectedRoute {...props} component={Invoices} />} /> */}
        <Route path="/invoice-details" render={(props) => <ProtectedRoute {...props} component={Invoicedetails} />} />
        <Route path="/view-invoice" render={(props) => <ProtectedRoute {...props} component={ViewInvoice} />} />
        <Route path="/add-invoices" render={(props) => <ProtectedRoute {...props} component={AddInvoice} />} />
        <Route path="/edit-invoices/:id?" render={(props) => <ProtectedRoute {...props} component={AddInvoice} />} />
        <Route path="/view-invoices/:id?" render={(props) => <ProtectedRoute {...props} component={ShowInvoice} />} />

        <Route path="/invoice-paid" render={(props) => <ProtectedRoute {...props} component={InvoicePaid} />} />
        <Route path="/invoice-particallypaid" render={(props) => <ProtectedRoute {...props} component={ParticallyPaid} />} />
        <Route path="/invoice-unpaid" render={(props) => <ProtectedRoute {...props} component={InvoiceUnpaid} />} />
        <Route path="/invoice-refund" render={(props) => <ProtectedRoute {...props} component={RefundedInvoice} />} />
        <Route path="/invoice-overdue" render={(props) => <ProtectedRoute {...props} component={InvoiceOverdue} />} />
        <Route path="/invoices-settings" render={(props) => <ProtectedRoute {...props} component={Invoicesettings} />} />
        <Route path="/invoice-draft" render={(props) => <ProtectedRoute {...props} component={InvoiceDraft} />} />
        <Route path="/invoice-cancelled" render={(props) => <ProtectedRoute {...props} component={Invoicecancelled} />} />
        <Route path="/payments" render={(props) => <ProtectedRoute {...props} component={Payments} />} />
        <Route path="/settings" render={(props) => <ProtectedRoute {...props} component={AccountSettings} />} />
        <Route path="/user-profile" render={(props) => <ProtectedRoute {...props} component={UserProfile} />} />

        <Route path="/company-settings" render={(props) => <ProtectedRoute {...props} component={CompanySettings} />} />
        <Route path="/invoice-settings" render={(props) => <ProtectedRoute {...props} component={InvoiceSetting} />} />
        <Route
          path="/invoice-templatesettings"
          render={(props) => <ProtectedRoute {...props} component={InvoiceTemplateSettings}
          />} />
        <Route path="/preferences" render={(props) => <ProtectedRoute {...props} component={Preferences} />} />
        <Route path="/email-settings" render={(props) => <ProtectedRoute {...props} component={EmailSettings} />} />
        <Route path="/expense-category" render={(props) => <ProtectedRoute {...props} component={ExpenseCategory} />} />
        <Route path="/notifications" render={(props) => <ProtectedRoute {...props} component={Notifications} />} />
        <Route path="/change-password" render={(props) => <ProtectedRoute {...props} component={ChangePassword} />} />
        <Route path="/chat" render={(props) => <ProtectedRoute {...props} component={Chat} />} />
        <Route path="/inbox" render={(props) => <ProtectedRoute {...props} component={Inbox} />} />
        <Route path="/profile" render={(props) => <ProtectedRoute {...props} component={Profile} />} />
        <Route path="/error-404" render={(props) => <ProtectedRoute {...props} component={Page404} />} />
        <Route path="/error-500" render={(props) => <ProtectedRoute {...props} component={Page500} />} />
        <Route path="/blank-page" render={(props) => <ProtectedRoute {...props} component={BlankPage} />} />

        <Route path="/components" render={(props) => <ProtectedRoute {...props} component={Components} />} />

        <Route path="/basic-inputs" render={(props) => <ProtectedRoute {...props} component={BasicInputs} />} />
        <Route path="/input-groups" render={(props) => <ProtectedRoute {...props} component={FormInputGroups} />} />
        <Route path="/horizontal-form" render={(props) => <ProtectedRoute {...props} component={HorizontalForm} />} />
        <Route path="/vertical-form" render={(props) => <ProtectedRoute {...props} component={VerticalForm} />} />
        <Route path="/form-mask" render={(props) => <ProtectedRoute {...props} component={FormMask} />} />
        <Route path="/form-validation" render={(props) => <ProtectedRoute {...props} component={FormValidation} />} />
        <Route path="/File-upload" render={(props) => <ProtectedRoute {...props} component={Fileupload} />} />
        <Route path="/form-select2" render={(props) => <ProtectedRoute {...props} component={Formselect2} />} />

        <Route path="/basic-tables" render={(props) => <ProtectedRoute {...props} component={BasicTables} />} />
        <Route path="/data-tables" render={(props) => <ProtectedRoute {...props} component={Datatables} />} />
        <Route path="/login" exact component={Login} />
        <Route path="/register" render={(props) => <ProtectedRoute {...props} component={Register} />} />
        <Route path="/forgot-password" render={(props) => <ProtectedRoute {...props} component={ForgotPassword} />} />
        <Route path="/lock-screen" render={(props) => <ProtectedRoute {...props} component={LockScreen} />} />
        <Route path="/add-product" render={(props) => <ProtectedRoute {...props} component={AddProduct} />} />
        <Route path="/edit-product" render={(props) => <ProtectedRoute {...props} component={EditProduct} />} />
        <Route path="/active-customers" render={(props) => <ProtectedRoute {...props} component={ActiveCustomers} />} />
        <Route path="/deactive-customers" render={(props) => <ProtectedRoute {...props} component={DeactiveCustomers} />} />
        <Route path="/customer-details" render={(props) => <ProtectedRoute {...props} component={CustomerDetails} />} />
        <Route path="/vendors" render={(props) => <ProtectedRoute {...props} component={Vendors} />} />
        <Route path="/customers" render={(props) => <ProtectedRoute {...props} component={CustomersMain} />} />
        <Route path="/accounts" render={(props) => <ProtectedRoute {...props} component={AccountComponent} />} />
        <Route path="/add-accounts" render={(props) => <ProtectedRoute {...props} component={AddAccountComponent} />} />
        <Route path="/edit-accounts/:id?" render={(props) => <ProtectedRoute {...props} component={AddAccountComponent} />} />
        <Route path="/ledger/:id?" render={(props) => <ProtectedRoute {...props} component={LedgerComponent} />} />
        <Route path="/account_types" render={(props) => <ProtectedRoute {...props} component={AccountTypes} />} />

        <Route path="/bank_accounts" render={(props) => <ProtectedRoute {...props} component={BankAccounts} />} />
        <Route path="/add-bank_accounts" render={(props) => <ProtectedRoute {...props} component={AddBankAccount} />} />
        <Route path="/edit-bank_accounts/:id" render={(props) => <ProtectedRoute {...props} component={AddBankAccount} />} />

        <Route path="/transactions" render={(props) => <ProtectedRoute {...props} component={TransactionComponent} />} />
        <Route path="/transaction_types" render={(props) => <ProtectedRoute {...props} component={TransactionTypes} />} />
        <Route path="/product-list" render={(props) => <ProtectedRoute {...props} component={ProductList} />} />
        <Route path="/category" render={(props) => <ProtectedRoute {...props} component={Category} />} />
        <Route path="/units" render={(props) => <ProtectedRoute {...props} component={Units} />} />
        <Route path="/inventory" render={(props) => <ProtectedRoute {...props} component={Inventory} />} />
        <Route path="/invoice-template" render={(props) => <ProtectedRoute {...props} component={InvoiceTemplate} />} />
        <Route path="/invoice-details-admin" render={(props) => <ProtectedRoute {...props} component={InvoiceDetailsAdmin} />} />
        <Route path="/recurring-invoices" render={(props) => <ProtectedRoute {...props} component={RecurringInvoice} />} />
        <Route path="/recurring" render={(props) => <ProtectedRoute {...props} component={RecurringHead} />} />
        <Route path="/credit-notes" render={(props) => <ProtectedRoute {...props} component={CreditNotes} />} />
        <Route path="/credit-notes-pending" render={(props) => <ProtectedRoute {...props} component={CreditPending} />} />
        <Route path="/credit-notes-overdue" render={(props) => <ProtectedRoute {...props} component={CreditOverdue} />} />
        <Route path="/credit-notes-draft" render={(props) => <ProtectedRoute {...props} component={CreditDraft} />} />
        <Route path="/credit-notes-recurring" render={(props) => <ProtectedRoute {...props} component={CreditRecurring} />} />
        <Route path="/credit-notes-cancelled" render={(props) => <ProtectedRoute {...props} component={CreditCancelled} />} />
        <Route path="/add-credit-notes" render={(props) => <ProtectedRoute {...props} component={AddCredit} />} />
        <Route path="/credit-notes-details" render={(props) => <ProtectedRoute {...props} component={CreditDetails} />} />
        <Route path="/purchases" render={(props) => <ProtectedRoute {...props} component={Purchases} />} />
        <Route path="/add-purchases" render={(props) => <ProtectedRoute {...props} component={AddPurchases} />} />
        <Route path="/edit-purchases" render={(props) => <ProtectedRoute {...props} component={EditPurchase} />} />
        <Route path="/purchases-details" render={(props) => <ProtectedRoute {...props} component={PurchaseDetails} />} />
        <Route path="/purchase-orders" render={(props) => <ProtectedRoute {...props} component={PurchaseOrders} />} />
        <Route path="/debit-notes" render={(props) => <ProtectedRoute {...props} component={DebitNotes} />} />
        <Route path="/quotations" render={(props) => <ProtectedRoute {...props} component={Quotations} />} />
        <Route path="/add-quotations" render={(props) => <ProtectedRoute {...props} component={AddQuotations} />} />
        <Route path="/edit-quotations" render={(props) => <ProtectedRoute {...props} component={EditQuotations} />} />
        <Route path="/delivery-challans" render={(props) => <ProtectedRoute {...props} component={DeliveryChallans} />} />
        <Route path="/edit-delivery-challans" render={(props) => <ProtectedRoute {...props} component={EditChallans} />} />
        <Route path="/add-delivery-challans" render={(props) => <ProtectedRoute {...props} component={AddChallans} />} />
        <Route path="/payment-summary" render={(props) => <ProtectedRoute {...props} component={PaymentSummary} />} />
        <Route path="/add-user" render={(props) => <ProtectedRoute {...props} component={AddUser} />} />
        <Route path="/users" render={(props) => <ProtectedRoute {...props} component={UserComponent} />} />
        <Route path="/add-users" render={(props) => <ProtectedRoute {...props} component={AddUserComponent} />} />
        <Route path="/edit-users/:id?" render={(props) => <ProtectedRoute {...props} component={AddUserComponent} />} />
        {/* <Route path="/edit-users" render={(props) => <ProtectedRoute {...props} component={EditUser} /> */}
        <Route path="/roles-permission" render={(props) => <ProtectedRoute {...props} component={RoleComponent} />} />
        <Route path="/roles-demo-permission" render={(props) => <ProtectedRoute {...props} component={RolesPermission} />} />
        <Route path="/edit-roles/:id?" render={(props) => <ProtectedRoute {...props} component={AddRoleComponent} />} />
        <Route path="/add-roles" render={(props) => <ProtectedRoute {...props} component={AddRoleComponent} />} />
        <Route path="/permission/:id?" render={(props) => <ProtectedRoute {...props} component={Permission} />} />
        <Route path="/delete-account-request" render={(props) => <ProtectedRoute {...props} component={DeleteAccount} />} />
        <Route path="/membership-plans" render={(props) => <ProtectedRoute {...props} component={MembershipPlan} />} />
        <Route path="/membership-addons" render={(props) => <ProtectedRoute {...props} component={MembershipAddons} />} />
        <Route path="/subscribers" render={(props) => <ProtectedRoute {...props} component={Subscribers} />} />
        <Route path="/transactions" render={(props) => <ProtectedRoute {...props} component={Transaction} />} />
        <Route path="/add-page" render={(props) => <ProtectedRoute {...props} component={AddPage} />} />
        <Route path="/pages" render={(props) => <ProtectedRoute {...props} component={Pages} />} />
        <Route path="/all-blogs" render={(props) => <ProtectedRoute {...props} component={AllBlogs} />} />
        <Route path="/inactive-blog" render={(props) => <ProtectedRoute {...props} component={InactiveBlog} />} />
        <Route path="/add-blog" render={(props) => <ProtectedRoute {...props} component={AddBlog} />} />
        <Route path="/categories" render={(props) => <ProtectedRoute {...props} component={Categories} />} />
        <Route path="/add-categories" render={(props) => <ProtectedRoute {...props} component={AddCategories} />} />
        <Route path="/blog-comments" render={(props) => <ProtectedRoute {...props} component={BlogComments} />} />
        <Route path="/countries" render={(props) => <ProtectedRoute {...props} component={Countries} />} />
        <Route path="/states" render={(props) => <ProtectedRoute {...props} component={States} />} />
        <Route path="/cities" render={(props) => <ProtectedRoute {...props} component={Cities} />} />
        <Route path="/testimonials" render={(props) => <ProtectedRoute {...props} component={Testimonials} />} />
        <Route path="/add-testimonials" render={(props) => <ProtectedRoute {...props} component={AddTestimonials} />} />
        <Route path="/edit-testimonials" render={(props) => <ProtectedRoute {...props} component={EditTestimonials} />} />
        <Route path="/faq" render={(props) => <ProtectedRoute {...props} component={Faq} />} />
        <Route path="/tickets" render={(props) => <ProtectedRoute {...props} component={Tickets} />} />
        <Route path="/tickets-list-open" render={(props) => <ProtectedRoute {...props} component={TicketsOpen} />} />
        <Route path="/tickets-list-resolved" render={(props) => <ProtectedRoute {...props} component={TicketsResolved} />} />
        <Route path="/tickets-list-pending" render={(props) => <ProtectedRoute {...props} component={TicketPending} />} />
        <Route path="/tickets-list-closed" render={(props) => <ProtectedRoute {...props} component={TicketRecurring} />} />
        <Route path="/tickets-list-cancelled" render={(props) => <ProtectedRoute {...props} component={TicketCancelled} />} />
        <Route path="/tickets-list" render={(props) => <ProtectedRoute {...props} component={TicketList} />} />
        <Route path="/tickets-open" render={(props) => <ProtectedRoute {...props} component={TicketListPending} />} />
        <Route path="/tickets-resolved" render={(props) => <ProtectedRoute {...props} component={TicketListOverdue} />} />
        <Route path="/tickets-pending" render={(props) => <ProtectedRoute {...props} component={TicketListDraft} />} />
        <Route path="/tickets-closed" render={(props) => <ProtectedRoute {...props} component={TicketListRecurring} />} />
        <Route path="/tickets-cancelled" render={(props) => <ProtectedRoute {...props} component={TicketListCancelled} />} />
        <Route path="/tickets-kanban" render={(props) => <ProtectedRoute {...props} component={TicketKanban} />} />
        <Route path="/tickets-overview" render={(props) => <ProtectedRoute {...props} component={TicketDetails} />} />
        <Route path="/add-membership" render={(props) => <ProtectedRoute {...props} component={AddMembership} />} />
        <Route path="/fontawesome-icons" render={(props) => <ProtectedRoute {...props} component={FontAwesome} />} />
        <Route path="/feather-icons" render={(props) => <ProtectedRoute {...props} component={Feather} />} />
        <Route path="/ionic-icons" render={(props) => <ProtectedRoute {...props} component={IconicIcon} />} />
        <Route path="/material-icons" render={(props) => <ProtectedRoute {...props} component={MaterialIcons} />} />
        <Route path="/pe7-icons" render={(props) => <ProtectedRoute {...props} component={Pe7} />} />
        <Route path="/typicon-icons" render={(props) => <ProtectedRoute {...props} component={TypiconIcons} />} />
        <Route path="/simpleline-icons" render={(props) => <ProtectedRoute {...props} component={SimpleLine} />} />
        <Route path="/themify-icons" render={(props) => <ProtectedRoute {...props} component={Themify} />} />
        <Route path="/weather-icons" render={(props) => <ProtectedRoute {...props} component={WeatherIcons} />} />
        <Route path="/flag-icons" render={(props) => <ProtectedRoute {...props} component={Flags} />} />
        <Route path="/apex-charts" render={(props) => <ProtectedRoute {...props} component={Apexchart} />} />
        <Route path="/chart-js" render={(props) => <ProtectedRoute {...props} component={ChartJs} />} />

        <Route path="/alerts" render={(props) => <ProtectedRoute {...props} component={Alert} />} />
        <Route path="/accordions" render={(props) => <ProtectedRoute {...props} component={Accordions} />} />
        <Route path="/avatar" render={(props) => <ProtectedRoute {...props} component={Avatar} />} />
        <Route path="/badges" render={(props) => <ProtectedRoute {...props} component={Badges} />} />
        <Route path="/buttons" render={(props) => <ProtectedRoute {...props} component={Buttons} />} />
        <Route path="/button-group" render={(props) => <ProtectedRoute {...props} component={Buttongroup} />} />
        <Route path="/breadcrumbs" render={(props) => <ProtectedRoute {...props} component={Breadcrumbs} />} />
        <Route path="/cards" render={(props) => <ProtectedRoute {...props} component={Cards} />} />
        <Route path="/carousel" render={(props) => <ProtectedRoute {...props} component={Carousel} />} />
        <Route path="/dropdowns" render={(props) => <ProtectedRoute {...props} component={Dropdowns} />} />
        <Route path="/grid" render={(props) => <ProtectedRoute {...props} component={Grid} />} />
        <Route path="/images" render={(props) => <ProtectedRoute {...props} component={Images} />} />
        <Route path="/lightbox" render={(props) => <ProtectedRoute {...props} component={Lightbox} />} />
        <Route path="/media" render={(props) => <ProtectedRoute {...props} component={Media} />} />
        <Route path="/modals" render={(props) => <ProtectedRoute {...props} component={Modals} />} />
        <Route path="/offcanvas" render={(props) => <ProtectedRoute {...props} component={Offcanvas} />} />
        <Route path="/pagination" render={(props) => <ProtectedRoute {...props} component={Pagination} />} />
        <Route path="/popover" render={(props) => <ProtectedRoute {...props} component={Popover} />} />
        <Route path="/progress" render={(props) => <ProtectedRoute {...props} component={Progress} />} />
        <Route path="/placeholders" render={(props) => <ProtectedRoute {...props} component={Placeholder} />} />
        <Route path="/rangeslides" render={(props) => <ProtectedRoute {...props} component={RangeSlides} />} />
        <Route path="/spinners" render={(props) => <ProtectedRoute {...props} component={Spinners} />} />
        <Route path="/sweetalerts" render={(props) => <ProtectedRoute {...props} component={Sweetalerts} />} />
        <Route path="/tab" render={(props) => <ProtectedRoute {...props} component={Tap} />} />
        <Route path="/toasts" render={(props) => <ProtectedRoute {...props} component={Toasts} />} />
        <Route path="/tooltip" render={(props) => <ProtectedRoute {...props} component={Tooltip} />} />
        <Route path="/typography" render={(props) => <ProtectedRoute {...props} component={Typography} />} />
        <Route path="/video" render={(props) => <ProtectedRoute {...props} component={Videos} />} />
        <Route path="/vector-map" render={(props) => <ProtectedRoute {...props} component={VectorMaps} />} />
        <Route path="/error-404" render={(props) => <ProtectedRoute {...props} component={Error404} />} />
        <Route path="/delete-accounts" render={(props) => <ProtectedRoute {...props} component={DeleteAccounts} />} />
        <Route path="/paymentmethod" render={(props) => <ProtectedRoute {...props} component={PaymentMethod} />} />
        <Route path="/banksetting" render={(props) => <ProtectedRoute {...props} component={BankSetting} />} />

        <Route path="/ribbon" render={(props) => <ProtectedRoute {...props} component={Ribbon} />} />
        <Route path="/clipboard" render={(props) => <ProtectedRoute {...props} component={Clipboard} />} />
        <Route path="/drag-drop" render={(props) => <ProtectedRoute {...props} component={DragDrop} />} />
        <Route path="/rating" render={(props) => <ProtectedRoute {...props} component={Rating} />} />
        <Route path="/text-editor" render={(props) => <ProtectedRoute {...props} component={Texteditor} />} />
        <Route path="/counter" render={(props) => <ProtectedRoute {...props} component={Counter} />} />
        <Route path="/scrollbar" render={(props) => <ProtectedRoute {...props} component={Scrollbar} />} />
        <Route path="/notification" render={(props) => <ProtectedRoute {...props} component={Notification} />} />
        <Route path="/sticky-note" render={(props) => <ProtectedRoute {...props} component={Stickynote} />} />
        <Route path="/timeline" render={(props) => <ProtectedRoute {...props} component={Timeline} />} />
        <Route path="/horizontal-timeline" render={(props) => <ProtectedRoute {...props} component={Horizontaltimeline} />} />
        <Route path="/form-wizard" render={(props) => <ProtectedRoute {...props} component={Formwizard} />} />
        <Route path="/contact-messages" render={(props) => <ProtectedRoute {...props} component={ContactMessage} />} />
        <Route path="/customers-ledger" render={(props) => <ProtectedRoute {...props} component={customersLedger} />} />
        <Route path="/ledger" render={(props) => <ProtectedRoute {...props} component={Ledger} />} />
        <Route path="/invoice-one-a" render={(props) => <ProtectedRoute {...props} component={InvoiceOnea} />} />
        <Route path="/invoice-four-a" render={(props) => <ProtectedRoute {...props} component={InvoiceFourA} />} />
        <Route path="/invoice-five" render={(props) => <ProtectedRoute {...props} component={InvoiceFive} />} />
        <Route path="/invoice-three" render={(props) => <ProtectedRoute {...props} component={InvoiceThree} />} />
        <Route path="/invoice-two" render={(props) => <ProtectedRoute {...props} component={InvoiceTwo} />} />
        <Route path="/companies" render={(props) => <ProtectedRoute {...props} component={Companies} />} />
        <Route path="/subscription" render={(props) => <ProtectedRoute {...props} component={Subscription} />} />
        <Route path="/packages" render={(props) => <ProtectedRoute {...props} component={Packages} />} />
        <Route path="/domain-request" render={(props) => <ProtectedRoute {...props} component={DomainRequest} />} />
        <Route path="/domain" render={(props) => <ProtectedRoute {...props} component={Domain} />} />
        <Route path="/add-transactions" render={(props) => <ProtectedRoute {...props} component={TransactionForm} />} />
        <Route path="/edit-transactions/:id?" render={(props) => <ProtectedRoute {...props} component={TransactionForm} />} />
        <Route path="/purchase-transaction" render={(props) => <ProtectedRoute {...props} component={PurchaseTransaction} />} />
        <Route path="/add-purchase-return" render={(props) => <ProtectedRoute {...props} component={AddPurchaseReturn} />} />
        <Route path="/calendar" render={(props) => <ProtectedRoute {...props} component={Calendar} />} />
        <Route path="/add-purchase-order" render={(props) => <ProtectedRoute {...props} component={AddPurchaseOrder} />} />
        <Route path="/edit-purchase-order" render={(props) => <ProtectedRoute {...props} component={EditPurchaseOrder} />} />
        <Route path="/edit-purchase-return" render={(props) => <ProtectedRoute {...props} component={EditPurchaseReturn} />} />
        <Route path="/signature-list" render={(props) => <ProtectedRoute {...props} component={SignatureList} />} />
        <Route path="/signature-invoice" render={(props) => <ProtectedRoute {...props} component={SignatureInvoice} />} />
        <Route path="/expense-report" render={(props) => <ProtectedRoute {...props} component={ExpenseReport} />} />
        <Route path="/purchase-report" render={(props) => <ProtectedRoute {...props} component={PurchaseReport} />} />
        <Route path="/purchase-return" render={(props) => <ProtectedRoute {...props} component={PurchaseReturnReport} />} />
        <Route path="/sales-report" render={(props) => <ProtectedRoute {...props} component={SalesReport} />} />
        <Route path="/sales-return-report" render={(props) => <ProtectedRoute {...props} component={SalesReturn} />} />
        <Route path="/quotation-report" render={(props) => <ProtectedRoute {...props} component={QuotationReport} />} />
        <Route path="/payment-report" render={(props) => <ProtectedRoute {...props} component={PaymentReport} />} />
        <Route path="/stock-report" render={(props) => <ProtectedRoute {...props} component={StockReport} />} />
        <Route path="/low-stock-report" render={(props) => <ProtectedRoute {...props} component={LowstockReport} />} />
        <Route path="/income-report" render={(props) => <ProtectedRoute {...props} component={IncomeReport} />} />
        <Route path="/tax-report" render={(props) => <ProtectedRoute {...props} component={TaxReport} />} />
        <Route path="/profit-loss-list" render={(props) => <ProtectedRoute {...props} component={ProfitLossList} />} />
        <Route path="/trial-balance-list" render={(props) => <ProtectedRoute {...props} component={TrialBalance} />} />
        <Route path="ledger/:id" render={(props) => <ProtectedRoute {...props} component={Ledger} />} />
        <Route path="/journal/:id" render={(props) => <ProtectedRoute {...props} component={Journal} />} />
        <Route path="/connect-account" component={PlaidLinkComponent} />
        <Route path="/plaid-dashboard" component={PlaidDashboard} />
        {/* render={(props) =>  <ProtectedRoute {...props} component={PlaidLinkComponent} />}  */}

        <Route path="/balance-sheet" render={(props) => <ProtectedRoute {...props} component={BalanceSheetReport} />} />
        <Route path="/cashreceipt-1" render={(props) => <ProtectedRoute {...props} component={CashRecepitOne} />} />
        <Route path="/cashreceipt-2" render={(props) => <ProtectedRoute {...props} component={CashRecepitTwo} />} />
        <Route path="/cashreceipt-3" render={(props) => <ProtectedRoute {...props} component={CashRecepitThree} />} />
        <Route path="/cashreceipt-4" render={(props) => <ProtectedRoute {...props} component={CashRecepitFour} />} />
        <Route path="/two-factor" render={(props) => <ProtectedRoute {...props} component={TwoFactor} />} />
        <Route path="/custom-field" render={(props) => <ProtectedRoute {...props} component={CustomField} />} />
        <Route path="/plan-pilling" render={(props) => <ProtectedRoute {...props} component={PlanBilling} />} />
        <Route path="/tax-rates" render={(props) => <ProtectedRoute {...props} component={TaxRates} />} />
        <Route
          path="/signature-preview-invoice"
          render={(props) => <ProtectedRoute {...props} component={SignaturePreviewInvoice}
          />} />
        <Route path="/edit-credit-notes" render={(props) => <ProtectedRoute {...props} component={EditCreditnote} />} />
        <Route path="/sass-login" render={(props) => <ProtectedRoute {...props} component={SassLogin} />} />
        <Route path="/sass-register" render={(props) => <ProtectedRoute {...props} component={SassRegister} />} />
        <Route path="/emailtemplates" render={(props) => <ProtectedRoute {...props} component={EmailTemplates} />} />
        <Route path="/seosettings" render={(props) => <ProtectedRoute {...props} component={SeoSettings} />} />
        <Route path="/sasssettings" render={(props) => <ProtectedRoute {...props} component={SaasSettings} />} />
        <Route path="/dashbord" render={(props) => <ProtectedRoute {...props} component={Superdashbord} />} />
        <Route path="/plans-list" render={(props) => <ProtectedRoute {...props} component={PackagesList} />} />
        <Route path="/invoice-subscription" render={(props) => <ProtectedRoute {...props} component={InvoiceSubscription} />} />



        <Route path="/vendors" render={(props) => <ProtectedRoute {...props} component={VendorMain} />} />
        <Route path="/add-vendors" render={(props) => <ProtectedRoute {...props} component={addVendorForm} />} />
        <Route path="/edit-vendors/:id" render={(props) => <ProtectedRoute {...props} component={addVendorForm} />} />
        <Route path="/invoices" render={(props) => <ProtectedRoute {...props} component={InvoiceList} />} />
        <Route path="/add/sales_invoices" render={(props) => <ProtectedRoute {...props} component={AddInvoice} />} />
        <Route path="/edit/sales_invoices/:id?" render={(props) => <ProtectedRoute {...props} component={AddInvoice} />} />

        <Route path='/verify_registration' component={RegistrationVerificationForm} />
        <Route path="/" exact component={Login} />

      </Switch>
    </Router>
  );
};

export default AppContainer;
