import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import Sidebar from "../layouts/Sidebar";
import Header from "../layouts/Header";
import { useHistory, useParams } from "react-router-dom";
import {
    CustomDropDownField,
    CustomInputField,
} from "../CustomComponents/ReusableComponents";

const AddBankAccount = () => {
    const { auth } = useAuth();
    const history = useHistory();
    const { id } = useParams();

    const [menu, setMenu] = useState(false);

    const accountTypes =  [
        { name: "Checking" },
        { name: "Savings" },
        { name: "Money Market" },
        { name: "Joint" },
        { name: "Custodial" },
        { name: "Trust" },
    ];
    const [formData, setFormData] = useState({
        account_holder_name: "",
        bank_name: "",
        account_number: "",
        account_type: process.env.REACT_APP_ACCOUNT_ID_BANK_ACCOUNT,
        is_active: true
    });

    // const [accountTypes, setAccountTypes] = useState([]);
    // const [error, setError] = useState(null);

    useEffect(() => {
        // const fetchDropdownData = async () => {
        //     try {
        //         const response = await axios.post(
        //             `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
        //             {
        //                 models: [{ model_name: "AccountType" }]
        //             },
        //             {
        //                 headers: {
        //                     Authorization: `Bearer ${auth}`
        //                 }
        //             }
        //         );
        //         setAccountTypes(response.data.content.AccountType || []);
        //     } catch (err) {
        //         setError("Failed to load dropdown data");
        //     }
        // };

        const fetchBankAccounData = async () => {
            if (id) {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_V1_BASE_URL}/bank_accounts/${id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${auth}`
                            }
                        }
                    );
                    setFormData(response.data);
                } catch (err) {
                    // Handle error
                }
            }
        };

        // fetchDropdownData();
        fetchBankAccounData();
    }, [auth, id]);

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const handleToggle = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            is_active: prevFormData.is_active === "1" ? "0" : "1"
        }));
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const response = id
                ? await axios.put(
                      `${process.env.REACT_APP_API_V1_BASE_URL}/bank_accounts/${id}`,
                      formData,
                      {
                          headers: {
                              Authorization: `Bearer ${auth}`,
                              "Content-Type": "application/json"
                          }
                      }
                  )
                : await axios.post(
                      `${process.env.REACT_APP_API_V1_BASE_URL}/bank_accounts`,
                      formData,
                      {
                          headers: {
                              Authorization: `Bearer ${auth}`,
                              "Content-Type": "application/json"
                          }
                      }
                  );

            console.log("Bank Account saved successfully", response.data);
            history.push("/bank_accounts");
        } catch (err) {
            console.error("Failed to save Bank Account", err);
            // Handle error
        }
    };

    const handleCancel = () => {
        history.push("/bank_aacounts");
    };

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <Header onMenuClick={toggleMobileMenu} />
                <Sidebar />
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="card mb-0">
                            <div className="card-body">
                                <div className="page-header">
                                    <div className="content-page-header">
                                        <h5>
                                            {id
                                                ? "Edit Bank Account"
                                                : "Add Bank Account"}
                                        </h5>
                                    </div>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <CustomInputField
                                            inputType="text"
                                            labelText="Account Holder Name"
                                            name="account_holder_name"
                                            value={formData.account_holder_name}
                                            onChange={handleChange}
                                        />
                                        <CustomInputField
                                            inputType="number"
                                            labelText="Account Number"
                                            name="account_number"
                                            value={formData.account_number}
                                            onChange={handleChange}
                                        />
                                        <CustomDropDownField
                                            options={accountTypes.map(c => ({
                                                value: c.name,
                                                label: c.name
                                            }))}
                                            labelText="Account Type"
                                            name="account_type"
                                            value={formData.account_type}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="col-lg-6 my-3">
                                        <CustomInputField
                                            inputType="text"
                                            labelText="Bank Name"
                                            name="bank_name"
                                            value={formData.bank_name}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">
                                            Active
                                        </label>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="isActiveSwitch"
                                                checked={
                                                    formData.is_active === "1"
                                                }
                                                onChange={handleToggle}
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="isActiveSwitch"
                                            >
                                                {formData.is_active === "1"
                                                    ? "Active"
                                                    : "Inactive"}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="text-end">
                                        <button
                                            type="reset"
                                            className="btn btn-primary cancel me-2"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddBankAccount;
