import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FilterChart } from "../_components/Imagepath";
import PropTypes from "prop-types";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";

const NewFilter = ({ show, setShow, filterableFields, handleFilterChange }) => {
    console.log("filterableFields in new filters", filterableFields);
    const { auth } = useAuth();

    const [filterContent, setFilterContent] = useState([]);
    const [filterValues, setFilterValues] = useState({});


    const handleInputChange = (fieldName, value) => {
      setFilterValues(prev => ({ ...prev, [fieldName]: value }));
    };

    const handleGenerateReport = () => {
      Object.keys(filterValues).forEach(fieldName => {
        handleFilterChange(fieldName, filterValues[fieldName]);
      });
      setShow(false);
    };

    const handleClearFilters = () => {
      setFilterValues({});
      filterableFields.forEach(field => handleFilterChange(field.field_name, ""));
    };

    useEffect(() => {
        setFilterContent(filterableFields || []);
        const fetchOptions = async () => {
            const requestData = (filterableFields || [])
                .filter(header => header.type === 'dropdown')
                .map(header => ({ model_name: header.display_name })); // Adjust based on your data structure

            if (requestData.length) {
                try {
                    const response = await axios.post(
                        `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                        { models: requestData },
                        {
                            headers: {
                                Authorization: `Bearer ${auth}`,
                                'Content-Type': 'application/json',
                            },
                        }
                    );

                    const dropDownData = response.data.content || {}; // Assuming the API returns dropdown options in `content`
                    const updatedHeaders = (filterableFields || []).map(header => {
                        if (header.type === 'dropdown') {
                            return {
                                ...header,
                                option_list: dropDownData[header.display_name] || [], // Update with options
                            };
                        }
                        return header; // Return the header unmodified if not a dropdown
                    });

                    setFilterContent(updatedHeaders); // Update state with the new filter content
                } catch (error) {
                    console.error('Failed to fetch options', error);
                }
            }
        };

        fetchOptions();
    }, [auth, filterableFields]); 

    return (
        <>
            <div className={`toggle-sidebar ${show ? "open-filter" : ""}`}>
                <div className="sidebar-layout-filter">
                    <div className="sidebar-header">
                        <h5>Filter</h5>
                        <Link
                            to="#"
                            className="sidebar-closes"
                            onClick={() => setShow(!show)}
                        >
                            <i className="fa-regular fa-circle-xmark" />
                        </Link>
                    </div>
                    <div className="sidebar-body">
                        <form action="#" autoComplete="off">
                            <div className="accordion" id="accordionMain1">
                                <div className="card-header-new" id="headingOne">
                                    <h6 className="filter-title">
                                        <Link
                                            to="#"
                                            className="w-100"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >
                                            Filter Fields
                                            <span className="float-end">
                                                <i className="fa-solid fa-chevron-down" />
                                            </span>
                                        </Link>
                                    </h6>
                                </div>
                                <div id="collapseOne" className="collapse show">
                                    <div className="card-body">
                                        {filterContent && Array.isArray(filterContent) && filterContent.map((field, index) => {
                                            if (field.type === "text") {
                                                return (
                                                    <div key={index} className={`mb-3 ${field.columnClass}`}>
                                                        <label htmlFor={field.field_name} className="form-label">
                                                            {field.display_name}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id={field.field_name}
                                                            name={field.field_name}
                                                            className="form-control"
                                                            value={filterValues[field.field_name] || ""}
                                                            onChange={(e) => handleInputChange(field.field_name, e.target.value)}
                                                        />
                                                    </div>
                                                );
                                            }

                                            if (field.type === "dropdown") {
                                                return (
                                                    <div key={index} className={`mb-3 ${field.columnClass}`}>
                                                        <label htmlFor={field.field_name} className="form-label">
                                                            {field.display_name}
                                                        </label>
                                                        <select
                                                            id={field.field_name}
                                                            name={field.field_name}
                                                            className="form-select"
                                                            value={filterValues[field.field_name] || ""}
                                                            onChange={(e) => handleInputChange(field.field_name, e.target.value)}
                                                        >
                                                            <option value="">Select {field.display_name}</option>
                                                            {field.option_list && field.option_list.map((option) => (
                                                                <option key={option.id} value={option.name}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                );
                                            }

                                            if (field.type === "date") {
                                                return (
                                                    <div key={index} className={`mb-3 ${field.columnClass}`}>
                                                        <label htmlFor={field.field_name} className="form-label">
                                                            {field.display_name}
                                                        </label>
                                                        <input
                                                            type="date"
                                                            id={field.field_name}
                                                            name={field.field_name}
                                                            className="form-control"
                                                            value={filterValues[field.field_name] || ""}
                                                            onChange={(e) => handleInputChange(field.field_name, e.target.value)}
                                                        />
                                                    </div>
                                                );
                                            }

                                            return null; 
                                        })}
                                    </div>
                                </div>
                            </div>
                            <button
                                type="button"
                                className="d-inline-flex align-items-center justify-content-center btn w-100 btn-secondary mb-3"
                                onClick={handleClearFilters}
                            >
                                Clear Filters
                            </button>
                            
                            <button
                                type="button"
                                className="d-inline-flex align-items-center justify-content-center btn w-100 btn-primary"
                                onClick={handleGenerateReport}
                            >
                                <span>
                                    <img
                                        src={FilterChart}
                                        className="me-2"
                                        alt="Generate report"
                                    />
                                </span>
                                Apply Filter
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

NewFilter.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    filterableFields: PropTypes.arrayOf(
        PropTypes.shape({
            display_name: PropTypes.string.isRequired,
            field_name: PropTypes.string.isRequired,
            i18n_key: PropTypes.string.isRequired,
            type: PropTypes.oneOf(['text', 'dropdown']).isRequired,
            columnClass: PropTypes.string.isRequired,
            sortable: PropTypes.number.isRequired,
            filterable: PropTypes.number.isRequired,
        })
    ).isRequired,
    handleFilterChange: PropTypes.func.isRequired,
};


export default NewFilter;